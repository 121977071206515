import { AssetApi } from "entities/api/assetApi";
import { Asset, AssetModel } from "entities/model/assetModel";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ScrollArea } from "shared/ui/shadcn/components/ui/scroll-area";
import { Skeleton } from "shared/ui/shadcn/components/ui/skeleton";
import ItemManufactureAsset from "./ItemManufactureAsset";
import ItemOfficeAsset from "./ItemOfficeAsset";

interface Props {
  keyword: string;
}

export default function SearchListAsset({ keyword }: Props) {
  const limit = 5;
  const [dataList, setDataList] = useState<Asset[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();

  const fetchDataList = async () => {
    try {
      setLoading(true);
      const response = await AssetApi.getList({
        offset: 0,
        limit,
        keyword,
        approved: 1,
        areaId: "",
      });
      let responseDataList: Asset[] = [];
      if (response.success) {
        for (let index = 0; index < response.dataList.length; index++) {
          const item = response.dataList[index];
          responseDataList.push(AssetModel.fromJson(item));
        }
      }
      setDataList(responseDataList);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDataList();
  }, [keyword]);

  const handleItemWidget = (item: Asset) => {
    switch (item.area_type) {
      case "MANUFACTURE":
        return <ItemManufactureAsset data={item} key={item.id} />;
      case "OFFICE":
        return <ItemOfficeAsset data={item} key={item.id} />;
      default:
        return <></>;
    }
  };

  return loading ? (
    <ScrollArea className="flex flex-col max-h-96 gap-4 p-2">
      {[...Array(limit)].map((item: any, index: number) => (
        <Skeleton className="w-full h-24 mb-4" key={index} />
      ))}
    </ScrollArea>
  ) : dataList.length > 1 ? (
    <ScrollArea className="flex flex-col max-h-96 gap-4">
      {dataList.map((item: Asset, index: number) => handleItemWidget(item))}
    </ScrollArea>
  ) : (
    <div className="flex items-center justify-center p-4">Not Found</div>
  );
}
