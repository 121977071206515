import { StringUtil } from "shared/utils/stringUtil";

export interface PIC {
  id?: string;
  name?: string;
  nik?: string;
  avatar?: string;
}

const fromJson = (json: any): PIC => {
  let result: PIC = {
    id: json["id"],
    name: json["name"],
    nik: json["nik"],
    avatar: json["avatar"] ?? StringUtil.getAvatarDefault(json["name"] ?? ""),
  };

  return result;
};

const toJson = (value: PIC): string => {
  return JSON.stringify(value);
};

export const PICModel = { fromJson, toJson };
