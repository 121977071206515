/* eslint-disable react-hooks/exhaustive-deps */
import { ChevronDownIcon, ChevronUpIcon } from "lucide-react";
import { useEffect, useState } from "react";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "shared/ui/shadcn/components/ui/collapsible";
import SidebarNavDashboard from "./SidebarNavDashboard";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { NavigationUtil, SidebarNav } from "shared/utils/navigationUtil";

interface Props {
  label: string;
  childs: SidebarNav[];
  icon: JSX.Element;
}

export default function SidebarMultiNavDashboard({
  label,
  childs,
  icon,
}: Props) {
  const [open, setOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { user_id, role_id } = useParams();

  const checkActive = () => {
    const result = childs.filter((item: SidebarNav) =>
      NavigationUtil.checkActiveNav(item.name, location.pathname, {
        user_id,
        role_id,
      })
    );
    if (result.length > 0) setOpen(true);
  };

  useEffect(() => {
    checkActive();
  }, []);

  return (
    <Collapsible open={open} onOpenChange={setOpen}>
      <CollapsibleTrigger asChild>
        <div
          className={`
            cursor-pointer rounded-lg
            mb-2 p-2
            hover:bg-primary hover:text-white
            ${open ? "bg-primary text-white" : "bg-blue-50"}
            flex gap-2 items-center group
            `}
        >
          <div className="w-auto h-10 aspect-square flex justify-center items-center">
            {icon}
          </div>
          <div className="flex justify-between items-center flex-grow">
            <div
              className={`${
                open && "text-white"
              } group-hover:text-white font-semibold text-sm`}
            >
              {label}
            </div>
            {open ? (
              <ChevronUpIcon
                className={`w-4 h-4 ${
                  open && "stroke-white"
                } group-hover:stroke-white`}
              />
            ) : (
              <ChevronDownIcon
                className={`w-4 h-4 ${
                  open && "stroke-white"
                } group-hover:stroke-white`}
              />
            )}
          </div>
        </div>
      </CollapsibleTrigger>
      <CollapsibleContent className="pl-2">
        {childs.map((item: SidebarNav, index: number) => (
          <div
            onClick={() => {
              navigate(item.path!);
            }}
            key={index}
          >
            <SidebarNavDashboard
              icon={item.icon}
              label={item.label}
              name={item.name}
            />
          </div>
        ))}
      </CollapsibleContent>
    </Collapsible>
  );
}
