import { useLocation, useParams } from "react-router-dom";
import { NavigationUtil, SidebarNav } from "shared/utils/navigationUtil";

export default function SidebarNavDashboard({ name, icon, label }: SidebarNav) {
  const location = useLocation();
  const { user_id, role_id } = useParams();

  const checkActiveNav = (name: string): boolean => {
    return NavigationUtil.checkActiveNav(name, location.pathname, {
      user_id,
      role_id,
    });
  };

  return (
    <div
      className={`
        cursor-pointer rounded-lg
        mb-2 p-2
        hover:bg-primary hover:text-white
        flex gap-2 items-center group
        ${checkActiveNav(name) ? "bg-primary text-white" : "bg-blue-50"}
    `}
    >
      <div className="w-auto h-10 aspect-square flex justify-center items-center">
        <img src={icon} alt={label} className="w-full h-auto" />
      </div>
      <div
        className={`${
          checkActiveNav(name) && "text-white"
        } group-hover:text-white font-semibold text-sm`}
      >
        {label}
      </div>
    </div>
  );
}
