import DefaultLoading from "./DefaultLoading";

export default function PageLoading() {
  return (
    <div className="w-screen h-screen flex flex-col gap-2 items-center justify-center bg-white">
      <DefaultLoading />
      <div className="font-bold text-gray-400">Please Wait...</div>
    </div>
  );
}
