const list = {
  container: {
    hidden: { opacity: 1, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2,
      },
    },
  },
  item: {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  },
};

const card = {
  initial: { scale: 0, y: "-100%", opacity: 0 },
  animate: { scale: 1, y: 0, opacity: 1 },
  transition: {
    type: "spring",
    duration: 1.7,
  },
};

const item = {
  initial: { x: "-100%", opacity: 0 },
  animate: { x: 0, opacity: 1 },
  transition: {
    type: "spring",
    duration: 1.7,
  },
};

export const AnimationValue = { list, card, item };
