/* eslint-disable react-hooks/exhaustive-deps */
import { Area, AreaModel } from "entities/model/areaModel";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import DefaultLoading from "shared/ui/loading/DefaultLoading";
import { AnimationValue } from "shared/values/animationValue";
import OEEItem from "./OEEItem";
import { useSearchParams } from "react-router-dom";
import { PerformanceApi } from "entities/api/performanceApi";

export default function OEEList() {
  const [loading, setLoading] = useState<boolean>(true);
  const [dataList, setDataList] = useState<Area[]>([]);
  const [searchParams] = useSearchParams();

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await PerformanceApi.getOEEList();
      if (response.success) {
        const oeeList: Area[] = [];
        for (const key in response.dataList) {
          const item = response.dataList[key];
          oeeList.push(AreaModel.fromJson(item));
        }
        setDataList(oeeList);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [searchParams]);

  return loading ? (
    <DefaultLoading />
  ) : (
    <>
      {dataList.map((data: Area, index: number) => (
        <motion.div
          key={index}
          initial={AnimationValue.item.initial}
          animate={AnimationValue.item.animate}
          transition={{
            type: AnimationValue.item.transition.type,
            duration: AnimationValue.item.transition.duration + index,
          }}
        >
          <OEEItem data={data} />
        </motion.div>
      ))}
    </>
  );
}
