/* eslint-disable react-hooks/exhaustive-deps */
import { UserApi } from "entities/api/userApi";
import { Area, AreaType } from "entities/model/areaModel";
import { User, UserModel } from "entities/model/userModel";
import FilterTableAreaUser from "features/user/FilterTableAreaUser";
import TableAreaUser from "features/user/TableAreaUser";
import { useEffect, useState } from "react";
import PageLoading from "shared/ui/loading/PageLoading";

interface Props {
  user_id: string;
  areaType: AreaType;
}

export default function TableFormAreaUser({ user_id, areaType }: Props) {
  const [loading, setLoading] = useState<boolean>(true);
  const [user, setUser] = useState<User>({});
  const [area, setArea] = useState<Area>({ id: "" });

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await UserApi.get(user_id);
      if (response.success) {
        setUser(UserModel.fromJson(response.data));
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [user_id]);

  return loading ? (
    <PageLoading />
  ) : (
    <>
      <div className="mb-2">
        <FilterTableAreaUser
          user={user}
          areaType={areaType}
          setArea={setArea}
        />
      </div>
      {area.id !== "" && (
        <TableAreaUser user={user} area={area} areaType={areaType} />
      )}
    </>
  );
}
