import { ApiValue } from "shared/values/apiValue";
import { BaseApi } from "./baseApi";

const getAvg = async () => {
  return await BaseApi.get({
    url: ApiValue.energyGetAvg,
  });
};

export const EnergyApi = {
  getAvg,
};
