import { Asset, AssetModel } from "./assetModel";
import { User, UserModel } from "./userModel";

export interface AssetOverhaul {
  id?: string;
  overhaul_amount?: number;
  overhaul_date?: Date | null;
  last_book_value?: number;
  asset_id?: string;
  created_by_id?: string;
  asset?: Asset;
  created_by?: User;
}

const fromJson = (json: any): AssetOverhaul => {
  let result: AssetOverhaul = {
    id: json["id"],
    overhaul_amount: json["overhaul_amount"],
    overhaul_date: json["overhaul_date"]
      ? new Date(json["overhaul_date"])
      : null,
    last_book_value: json["last_book_value"],
    asset_id: json["asset_id"],
    created_by_id: json["created_by_id"],
  };

  if (json["asset"]) result.asset = AssetModel.fromJson(json["asset"]);
  if (json["created_by"])
    result.created_by = UserModel.fromJson(json["created_by"]);

  return result;
};

const toJson = (value: AssetOverhaul): string => {
  return JSON.stringify(value);
};

export const AssetOverhaulModel = { fromJson, toJson };
