/* eslint-disable react-hooks/exhaustive-deps */
import { AssetApi } from "entities/api/assetApi";
import { Area, AreaType } from "entities/model/areaModel";
import { Asset, AssetModel } from "entities/model/assetModel";
import { PaginationModel, Paginations } from "entities/model/paginationModel";
import {
  CheckIcon,
  EditIcon,
  EyeIcon,
  FileBarChartIcon,
  FilePlus2Icon,
  ListIcon,
  PlusCircleIcon,
  SearchIcon,
  Trash2Icon,
  XIcon,
} from "lucide-react";
import { useEffect, useState } from "react";
import DefaultButton from "shared/ui/button/DefaultButton";
import SelectInput, { OptionSelect } from "shared/ui/input/SelectInput";
import TextInput from "shared/ui/input/TextInput";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "shared/ui/shadcn/components/ui/table";
import EmptyRowTable from "shared/ui/table/EmptyRowTable";
import LoadingRowTable from "shared/ui/table/LoadingRowTable";
import PaginationTable from "shared/ui/table/PaginationTable";
import { SettingValue } from "shared/values/settingValue";
import FormAsset from "./FormAsset";
import { format } from "date-fns";
import DialogDetailAsset from "widgets/asset/DialogDetailAsset";
import { Button } from "shared/ui/shadcn/components/ui/button";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "shared/ui/shadcn/components/ui/tooltip";
import ApproveAsset from "./ApproveAsset";
import DeleteAsset from "./DeleteAsset";
import { useNavigate, useSearchParams } from "react-router-dom";
import { RouteValue } from "shared/values/routeValue";
import { ApiValue } from "shared/values/apiValue";
import FormImportAsset from "./FormImportAsset";
import ExportExcelAsset from "./ExportExcelAsset";
import DefaultLoading from "shared/ui/loading/DefaultLoading";
import { useProfile } from "app/hooks/profileHook";

interface Props {
  area: Area;
  areaType: AreaType;
  asset_id?: string;
}
export default function TableAsset({ area, areaType, asset_id = "" }: Props) {
  const { profile } = useProfile();
  const limit = SettingValue.limitAsset;
  const [keyword, setKeyword] = useState<string>("");
  const [approved, setApproved] = useState<number>(1);
  const [pagination, setPagination] = useState<Paginations>(
    PaginationModel.handleInitial(limit)
  );
  const [dataList, setDataList] = useState<Asset[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const optionApprovedList: OptionSelect[] = [
    {
      value: "1",
      label: "Approved",
    },
    {
      value: "0",
      label: "Not Yet Approved",
    },
  ];
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [loadingPage, setLoadingPage] = useState<boolean>(false);

  const fetchDataList = async () => {
    try {
      setLoading(true);
      let offset = 0;
      if (keyword) {
        offset = 0;
      } else {
        offset = pagination.page === 1 ? 0 : pagination.page! * limit - limit;
      }
      const response = await AssetApi.getList({
        offset,
        limit,
        keyword,
        approved,
        areaId: area.id,
        id: asset_id,
      });
      let responseDataList: Asset[] = [];
      if (response.success) {
        for (let index = 0; index < response.dataList.length; index++) {
          const item = response.dataList[index];
          responseDataList.push(AssetModel.fromJson(item));
        }
        setPagination(response.pagination);
      }
      setDataList(responseDataList);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleParam = async () => {
    try {
      setLoadingPage(true);
      const responseAsset = await AssetApi.get(searchParams.get("asset_id")!);
      const asset = AssetModel.fromJson(responseAsset.data);
      setApproved(responseAsset.data["approved"]);
      setKeyword(asset.asset_no!);
    } catch (error) {
    } finally {
      setLoadingPage(false);
    }
  };

  useEffect(() => {
    fetchDataList();
  }, [keyword, pagination.page, area.id, approved]);

  useEffect(() => {
    if (searchParams.get("asset_id")) {
      handleParam();
    }
  }, [searchParams]);

  return loadingPage ? (
    <DefaultLoading />
  ) : (
    <>
      <div className="flex flex-col-reverse md:flex-row justify-between mb-2 p-2 bg-blue-100 rounded-lg gap-2">
        <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-2">
          <TextInput
            placeholder="Search by name or no asset"
            prefixIcon={<SearchIcon className="w-4 h-4 stroke-gray-500" />}
            value={keyword}
            setValue={setKeyword}
          />
          <SelectInput
            value={approved.toString()}
            setValue={(value: string) => {
              setApproved(parseInt(value));
            }}
            dataList={optionApprovedList}
          />
        </div>
        {!loading && (
          <div className="flex flex-col md:flex-row gap-2">
            <ExportExcelAsset
              area={area}
              asset_id={asset_id}
              approved={approved}
            />
            <FormAsset
              areaType={areaType}
              area_level_id={area.id}
              asset_id={asset_id}
              isParent={asset_id === ""}
              onFormSubmit={() => {
                fetchDataList();
              }}
            >
              <DefaultButton
                label="Add Data"
                icon={<PlusCircleIcon className="stroke-white w-6 h-6" />}
              />
            </FormAsset>
            <a href={ApiValue.publicTemplateExcelAsset}>
              <DefaultButton
                label="Export Template"
                icon={<FileBarChartIcon className="stroke-white w-6 h-6" />}
                className="bg-emerald-500"
              />
            </a>
            <FormImportAsset
              area={area}
              areaType={areaType}
              asset_id={asset_id}
              onFormSubmit={() => {
                fetchDataList();
              }}
            >
              <DefaultButton
                label="Import Data"
                icon={<FilePlus2Icon className="w-6 h-6" />}
                className="bg-amber-500 text-gray-900"
              />
            </FormImportAsset>
          </div>
        )}
      </div>
      <div className="rounded-md border">
        <Table>
          <TableHeader className="bg-primary">
            <TableRow>
              <TableHead className="w-[5%] text-white text-center">
                No
              </TableHead>
              <TableHead className="text-white">Name</TableHead>
              <TableHead className="text-white">No Asset</TableHead>
              <TableHead className="text-white">PIC</TableHead>
              <TableHead className="text-white">Purchase Date</TableHead>
              <TableHead className="text-white">Price</TableHead>
              <TableHead className="text-white">Created By</TableHead>
              <TableHead className="w-[10%] text-white text-center">
                Action
              </TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {loading ? (
              <LoadingRowTable colSpan={8} />
            ) : dataList.length < 1 ? (
              <EmptyRowTable colSpan={8} />
            ) : (
              <>
                {dataList.map((item: Asset, index: number) => (
                  <TableRow key={index}>
                    <TableCell className="text-center">
                      {(pagination.page === 1
                        ? 1
                        : pagination.page! * limit - limit + 1) + index}
                    </TableCell>
                    <TableCell>{item.name}</TableCell>
                    <TableCell>{item.asset_no}</TableCell>
                    <TableCell>{item.pic?.name}</TableCell>
                    <TableCell>
                      {item.purchase_date &&
                        format(item.purchase_date!, "PPPP")}
                    </TableCell>
                    <TableCell>{`Rp ${
                      item.price ? item?.price?.toLocaleString() : 0
                    }`}</TableCell>
                    <TableCell>{item.createdBy?.display_name}</TableCell>
                    <TableCell className="text-center">
                      <div className="flex gap-2 justify-center items-center">
                        <TooltipProvider>
                          <Tooltip>
                            <DialogDetailAsset asset_id={item.id!}>
                              <TooltipTrigger asChild>
                                <Button className="bg-cyan-500">
                                  <EyeIcon className="w-4 h-4 stroke-white" />
                                </Button>
                              </TooltipTrigger>
                            </DialogDetailAsset>

                            <TooltipContent>Show Detail</TooltipContent>
                          </Tooltip>
                        </TooltipProvider>
                        {approved === 0 && profile?.role?.can_approve && (
                          <>
                            <ApproveAsset
                              data={item}
                              approved={true}
                              onDelete={() => {
                                fetchDataList();
                              }}
                            >
                              <Button className="bg-emerald-500">
                                <CheckIcon className="w-4 h-4 stroke-white" />
                              </Button>
                            </ApproveAsset>
                            <ApproveAsset
                              data={item}
                              approved={false}
                              onDelete={() => {
                                fetchDataList();
                              }}
                            >
                              <Button className="bg-rose-500">
                                <XIcon className="w-4 h-4 stroke-white" />
                              </Button>
                            </ApproveAsset>
                          </>
                        )}
                        {approved === 1 && (
                          <>
                            {asset_id === "" && (
                              <TooltipProvider>
                                <Tooltip>
                                  <TooltipTrigger>
                                    <Button
                                      className="bg-emerald-500"
                                      onClick={() => {
                                        navigate(
                                          RouteValue.assetChildList.replace(
                                            ":asset_id",
                                            item.id!
                                          )
                                        );
                                      }}
                                    >
                                      <ListIcon className="w-4 h-4 stroke-white" />
                                    </Button>
                                  </TooltipTrigger>
                                  <TooltipContent>
                                    See Child Asset
                                  </TooltipContent>
                                </Tooltip>
                              </TooltipProvider>
                            )}
                            <FormAsset
                              areaType={areaType}
                              area_level_id={area.id}
                              asset_id={asset_id}
                              isParent={asset_id === ""}
                              data={item}
                              onFormSubmit={() => {
                                fetchDataList();
                              }}
                            >
                              <Button className="bg-blue-500">
                                <EditIcon className="w-4 h-4 stroke-white" />
                              </Button>
                            </FormAsset>
                            <DeleteAsset
                              data={item}
                              onDelete={() => {
                                fetchDataList();
                              }}
                            >
                              <Button className="bg-rose-500">
                                <Trash2Icon className="w-4 h-4 stroke-white" />
                              </Button>
                            </DeleteAsset>
                          </>
                        )}
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </>
            )}
          </TableBody>
        </Table>
      </div>
      <PaginationTable pagination={pagination} setPagination={setPagination} />
    </>
  );
}
