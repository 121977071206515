import { UserApi } from "entities/api/userApi";
import { Area } from "entities/model/areaModel";
import { Trash2Icon } from "lucide-react";
import { useState } from "react";
import DefaultButton from "shared/ui/button/DefaultButton";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "shared/ui/shadcn/components/ui/dialog";
import { useToast } from "shared/ui/shadcn/components/ui/use-toast";

interface Props {
  children: JSX.Element;
  onDelete?: any;
  data: Area;
  user_id: string;
}

export default function DeleteAreaUser({
  children,
  onDelete,
  data,
  user_id,
}: Props) {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState<boolean>(false);
  const { toast } = useToast();

  const deleteData = async () => {
    try {
      setLoading(true);
      const response = await UserApi.removeArea(user_id, data.id!);

      if (!response.success) return;

      toast({
        title: `Success delete user`,
        description: "Success deleted!",
        className: "bg-emerald-500 !text-white",
      });
      if (onDelete) {
        onDelete();
      }
      setOpen(false);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger
        asChild
        onClick={() => {
          setOpen(true);
        }}
      >
        {children}
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Are you sure delete this user?</DialogTitle>
        </DialogHeader>
        <div>Are you sure delete data user {data.name} ?</div>
        <div className="flex gap-2 justify-end">
          <div>
            <DefaultButton
              label="Cancel"
              type="button"
              variant={"outline"}
              onClick={() => {
                setOpen(false);
              }}
            />
          </div>
          <div>
            <DefaultButton
              label="Delete"
              icon={<Trash2Icon className="stroke-white w-6 h-6" />}
              loading={loading}
              type="button"
              variant={"destructive"}
              onClick={async () => {
                await deleteData();
              }}
            />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
