import { Menu } from "lucide-react";
import { useLocation, useParams } from "react-router-dom";
import { Separator } from "shared/ui/shadcn/components/ui/separator";
import {
  Sheet,
  SheetContent,
  SheetTrigger,
} from "shared/ui/shadcn/components/ui/sheet";
import { AssetValue } from "shared/values/assetValue";
import SidebarDashboard from "./SidebarDashboard";
import SearchAsset from "features/asset/SearchAsset";
import QRCodeScanAsset from "features/asset/QRCodeScanAsset";
import UserNotification from "features/notification/UserNotification";
import MenuProfileUser from "widgets/user/MenuProfileUser";

export default function HeaderDashboard() {
  const location = useLocation();
  const { user_id } = useParams();

  const handleTitlePage = (): string => {
    switch (location.pathname) {
      case "/manufacture":
        return "Manufacturing Dashboard";
      case "/office":
        return "Office Dashboard";
      case "/asset":
        return "Asset List";
      case "/pic/master":
        return "PIC List";
      case "/pic/area":
        return "PIC of Area List";
      case "/master/manufacture":
        return "Data Master - Manufacture List";
      case "/master/supplier":
        return "Data Master - Supplier List";
      case "/user":
        return "User List";
      case `/user/${user_id}/area`:
        return "User Area List";
      default:
        return "";
    }
  };

  return (
    <div
      className="
        h-[3.125rem] w-full
        flex gap-2 md:gap-4 justify-between items-center 
        bg-white p-2
        border border-gray-100 
        shadow-lg md:rounded-lg
        fixed top-0 left-0
        md:static
        z-50
        "
    >
      <Sheet>
        <SheetTrigger asChild>
          <Menu className="w-6 h-6 stroke-gray-500 cursor-pointer" />
        </SheetTrigger>
        <SheetContent className="w-max p-2 pt-12" side="left">
          <SidebarDashboard />
        </SheetContent>
      </Sheet>
      <img
        src={AssetValue.logotypeImage}
        alt="Akebono Logo"
        className="h-6 w-auto"
      />
      <Separator orientation="vertical" />
      <div className="flex-grow font-semibold uppercase text-xs md:text-base hidden md:block">
        {handleTitlePage()}
      </div>
      <div className="hidden md:block w-[30%]">
        <SearchAsset />
      </div>
      <div className="flex gap-4 items-center">
        <QRCodeScanAsset />
        <UserNotification />
        <MenuProfileUser />
      </div>
    </div>
  );
}
