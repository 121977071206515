import { format } from "date-fns";
import { Asset } from "entities/model/assetModel";
import { motion } from "framer-motion";
import { useSearchParams } from "react-router-dom";
import { AnimationValue } from "shared/values/animationValue";
import { AssetValue } from "shared/values/assetValue";

interface Props {
  data: Asset;
}

export default function ItemOfficeAsset({ data }: Props) {
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <motion.li
      className={`flex justify-between items-start gap-2 mx-2 rounded-lg p-2 my-2 cursor-pointer ${
        searchParams.get("asset_id") === data.id ? "bg-primary" : "bg-blue-50"
      }`}
      variants={AnimationValue.list.item}
      onClick={() => {
        setSearchParams({ asset_id: data.id! });
      }}
    >
      {data.image!.length > 0 && (
        <div
          className="!bg-cover !bg-no-repeat w-14 h-14 rounded-lg"
          style={{ background: `url("${data.image![0].image}")` }}
        ></div>
      )}
      <div className="flex-grow">
        <div
          className={`font-semibold text-sm ${
            searchParams.get("asset_id") === data.id
              ? "text-white"
              : "text-gray-400"
          } `}
        >
          No: {data.asset_no}
        </div>
        <div
          className={`font-semibold ${
            searchParams.get("asset_id") === data.id && "text-white"
          }`}
        >
          {data.name}
        </div>
        <div
          className={`font-semibold text-sm ${
            searchParams.get("asset_id") === data.id
              ? "text-white"
              : "text-gray-400"
          }`}
        >
          PIC: {data.pic!.name}
        </div>
      </div>
      <div className="flex flex-col gap-1 items-end">
        <div
          className="!bg-contain !bg-center !bg-no-repeat w-14 h-14"
          style={{ background: `url("${AssetValue.calendarImage}")` }}
        ></div>
        <div
          className={`font-semibold ${
            searchParams.get("asset_id") === data.id && "text-white"
          }`}
        >
          {format(data.purchase_date!, "dd MMM Y")}
        </div>
      </div>
    </motion.li>
  );
}
