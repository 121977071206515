import { z } from "zod";

const add = z.object({
  name: z.string().min(1, { message: "Name is required!" }),
  nik: z.string(),
  avatar: z.string(),
});
const addArea = z.object({
  pic_id: z.string().min(1, { message: "PIC is required!" }),
  area_level_id: z.string().min(1, { message: "Area id is required!" }),
});

export const PICValidationValue = {
  add,
  addArea,
};
