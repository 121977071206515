/* eslint-disable react-hooks/exhaustive-deps */
import { Asset, AssetModel } from "entities/model/assetModel";
import { ImageIcon } from "lucide-react";
import QRCode from "react-qr-code";
import { Button } from "shared/ui/shadcn/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "shared/ui/shadcn/components/ui/dialog";
import * as htmlToImage from "html-to-image";
import { useEffect, useState } from "react";
import { AssetApi } from "entities/api/assetApi";
import DefaultLoading from "shared/ui/loading/DefaultLoading";

interface Props {
  asset_id: string;
}

export default function DownloadQRCodeAsset({ asset_id }: Props) {
  const [loading, setLoading] = useState<boolean>(true);
  const [asset, setAsset] = useState<Asset>({});

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await AssetApi.get(asset_id);
      setAsset(AssetModel.fromJson(response.data));
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const downloadAsImage = async () => {
    const node: any = document.getElementById(`qr-code-${asset.id}`);
    await htmlToImage
      .toPng(node, { cacheBust: false })
      .then(function (dataUrl) {
        const link = document.createElement("a");
        link.download = `qr-code-${asset.name}.png`;
        link.href = dataUrl;
        link.click();
      })
      .catch(function (error) {
        console.error("oops, something went wrong!", error);
      });
  };

  useEffect(() => {
    fetchData();
  }, [asset_id]);

  return (
    <Dialog>
      <DialogTrigger asChild>
        <div className="text-sm bg-primary text-white px-2 rounded-lg cursor-pointer">
          Download QR Code
        </div>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[720px] md:max-w-[360px]">
        <DialogHeader>
          <DialogTitle>QR Code Asset</DialogTitle>
        </DialogHeader>
        {loading ? (
          <DefaultLoading />
        ) : (
          <>
            <div
              className="flex flex-col justify-center items-center py-2 gap-2 bg-white"
              id={`qr-code-${asset.id}`}
            >
              <QRCode value={JSON.stringify(asset.id)} />
              <div className="font-semibold text-xl text-center">
                {asset.asset_no} - {asset.name}
              </div>
            </div>
            <Button className="flex gap-2" onClick={downloadAsImage}>
              <ImageIcon className="stroke-white w-6 h-6" />
              Download QR Code as Image
            </Button>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
}
