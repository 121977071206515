/* eslint-disable react-hooks/exhaustive-deps */
import { AreaApi } from "entities/api/areaApi";
import { Area, AreaModel } from "entities/model/areaModel";
import FilterAsset from "features/asset/FilterAsset";
import ListAsset from "features/asset/ListAsset";
import OEEItem from "features/oee/OEEItem";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AccordionCard from "shared/ui/card/AccordionCard";
import CardTitleGeneral from "shared/ui/card/CardTitleGeneral";
import DefaultLoading from "shared/ui/loading/DefaultLoading";
import { ScrollArea } from "shared/ui/shadcn/components/ui/scroll-area";
import { AnimationValue } from "shared/values/animationValue";
import { AssetValue } from "shared/values/assetValue";

export default function AssetListManufacture() {
  const [loading, setLoading] = useState<boolean>();
  const [oee, setOee] = useState<Area>({});
  const { area_id } = useParams();

  const fetchOEE = async () => {
    try {
      setLoading(true);
      // const response = await OeeApi.getOEE({ areaType: "MANUFACTURE", areaId });
      // if (response.success) {
      //   setOee(AreaModel.toArea(response.data));
      // }
      const response = await AreaApi.get(area_id!);
      const area = AreaModel.fromJson(response.data);
      setOee({ ...area, oee: 90, oee_yesterday: 85, asset_off: 0 });
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOEE();
  }, []);

  return (
    <motion.div
      variants={AnimationValue.list.item}
      className="
        w-full !bg-white rounded-lg border border-gray-100 shadow-lg 
        flex flex-col gap-2
        h-[calc(100vh-3.125rem-(4*theme('gap.2'))-5rem)]"
    >
      <CardTitleGeneral icon={AssetValue.assetImage} title="Asset List" />
      <FilterAsset areaType="MANUFACTURE" />
      <AccordionCard title="OEE">
        {loading ? <DefaultLoading /> : <OEEItem data={oee} />}
      </AccordionCard>
      <ScrollArea>
        <ListAsset areaType="MANUFACTURE" />
      </ScrollArea>
      <div className="h-2"></div>
    </motion.div>
  );
}
