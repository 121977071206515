import { Button } from "../shadcn/components/ui/button";

interface Props {
  label: string;
  icon?: JSX.Element;
  suffixIcon?: JSX.Element;
  loading?: boolean;
  type?: "button" | "submit" | "reset";
  disabled?: boolean;
  className?: string;
  variant?:
    | "default"
    | "destructive"
    | "outline"
    | "secondary"
    | "ghost"
    | "link"
    | null
    | undefined;
  onClick?: any | null;
}

export default function DefaultButton({
  label,
  icon,
  suffixIcon,
  loading = false,
  type = "button",
  disabled = false,
  className = "",
  variant,
  onClick = () => {},
}: Props) {
  return (
    <Button
      variant={variant}
      className={`flex gap-4 w-full ${className}`}
      type={type}
      disabled={loading || disabled}
      onClick={onClick}
    >
      {loading ? (
        <>
          <l-dot-pulse size="24" speed="1.3" color="white"></l-dot-pulse>
          Mohon Tunggu...
        </>
      ) : (
        <>
          {icon}
          {label}
          {suffixIcon}
        </>
      )}
    </Button>
  );
}
