import { zodResolver } from "@hookform/resolvers/zod";
import { RoleApi } from "entities/api/roleApi";
import { Role } from "entities/model/roleModel";
import { SaveIcon } from "lucide-react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import DefaultButton from "shared/ui/button/DefaultButton";
import SelectInput from "shared/ui/input/SelectInput";
import TextInput from "shared/ui/input/TextInput";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "shared/ui/shadcn/components/ui/dialog";
import { Form } from "shared/ui/shadcn/components/ui/form";
import { Label } from "shared/ui/shadcn/components/ui/label";
import { Switch } from "shared/ui/shadcn/components/ui/switch";
import { useToast } from "shared/ui/shadcn/components/ui/use-toast";
import ErrorText from "shared/ui/text/ErrorText";
import { RoleValidationValue } from "shared/values/validations/roleValidationValue";
import { z } from "zod";

interface Props {
  children: JSX.Element;
  onFormSubmit?: any;
  data?: Role | null;
}

export default function FormRole({
  children,
  onFormSubmit,
  data = null,
}: Props) {
  const [open, setOpen] = useState<boolean>(false);
  const form = useForm<z.infer<typeof RoleValidationValue.add>>({
    resolver: zodResolver(RoleValidationValue.add),
    defaultValues: {
      name: data ? data.name : "",
      access_all_area: data ? (data.access_all_area ? 1 : 0) : 0,
      access_area: data ? data.access_area : 1,
    },
  });
  const [loading, setLoading] = useState<boolean>(false);
  const { toast } = useToast();

  const onSubmit = async (values: z.infer<typeof RoleValidationValue.add>) => {
    try {
      setLoading(true);
      const dataInput = {
        name: values.name,
        access_all_area: values.access_all_area,
        access_area: values.access_area,
      };
      const response = data
        ? await RoleApi.edit(data.id!, dataInput)
        : await RoleApi.save(dataInput);
      if (!response.success) {
        const dataError = response.error;
        form.setError(dataError.path, { message: dataError.msg });
        return;
      }
      if (onFormSubmit) {
        onFormSubmit({ ...data, name: values.name });
      }
      form.clearErrors();
      if (data === null) {
        form.reset({ name: "" });
      }
      toast({
        title: `Success ${data === null ? "add" : "edit"} role`,
        description: data === null ? "Success created!" : "Success edited!",
        className: "bg-emerald-500 !text-white",
      });
      setOpen(false);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger
        asChild
        onClick={() => {
          setOpen(true);
        }}
      >
        {children}
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>{data ? "Edit Role" : "Add Role"}</DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <div className="mb-2">
              <TextInput
                label="Name"
                placeholder="Enter name role"
                value={form.getValues("name")}
                setValue={(value: string) => {
                  form.setValue("name", value, { shouldValidate: true });
                }}
              />
              <ErrorText text={form.formState.errors.name?.message!} />
            </div>
            <div className="flex items-center space-x-2 mb-2">
              <Switch
                id="access_all_area"
                checked={form.getValues("access_all_area") === 1}
                onCheckedChange={(checked: boolean) => {
                  form.setValue("access_area", checked ? 0 : 1, {
                    shouldValidate: true,
                  });
                  form.setValue("access_all_area", checked ? 1 : 0, {
                    shouldValidate: true,
                  });
                }}
              />
              <Label htmlFor="access_all_area">Access All Area</Label>
            </div>
            {form.getValues("access_all_area") === 0 && (
              <SelectInput
                label="Level Area"
                value={form.getValues("access_area").toString()}
                setValue={(value: string) => {
                  form.setValue("access_area", parseInt(`${value}`), {
                    shouldValidate: true,
                  });
                }}
                dataList={[
                  { value: "1", label: "Level 1" },
                  { value: "2", label: "Level 2" },
                  { value: "3", label: "Level 3" },
                ]}
              />
            )}
            <DefaultButton
              label="Save changes"
              icon={<SaveIcon className="stroke-white w-6 h-6" />}
              loading={loading}
              type="submit"
              className="mt-10"
            />
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
