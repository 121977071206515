/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Circle, MapContainer, TileLayer, useMap } from "react-leaflet";

import "leaflet/dist/leaflet.css";
import "leaflet/dist/images/marker-shadow.png";
import { Asset, AssetModel } from "entities/model/assetModel";
import { AssetApi } from "entities/api/assetApi";
import DefaultLoading from "shared/ui/loading/DefaultLoading";

interface Position {
  latitude: number;
  longitude: number;
}

const MarkerAsset = ({
  lat,
  lng,
  zoom,
}: {
  lat: number;
  lng: number;
  zoom: number;
}) => {
  const map = useMap();

  const handleFly = () => {
    map.flyTo({ lat, lng }, zoom);
  };

  useEffect(() => {
    handleFly();
  }, [lat, lng]);

  return (
    <Circle
      center={[lat, lng]}
      pathOptions={{ fillColor: "red", color: "red" }}
      radius={10}
    />
  );
};

interface Props {
  asset_id: string;
}

export default function PositionAsset({ asset_id }: Props) {
  const [data, setData] = useState<Position>({ latitude: 0, longitude: 0 });
  const [loading, setLoading] = useState<boolean>(true);
  const [asset, setAsset] = useState<Asset>({});

  const fetchData = async () => {
    try {
      const response = await fetch(
        "https://flespi.io/gw/devices/5392882/telemetry/position",
        {
          headers: {
            Authorization:
              "FlespiToken yX7YCOhsdZb5yYsQPDzM4iJ2nmh69I8yv5kWHrcLfdGD1WkFnHhiNO8Y0fTXMRj7",
          },
        }
      );
      if (response.ok) {
        const data = await response.json();
        const latitude = data.result[0].telemetry.position.value.latitude;
        const longitude = data.result[0].telemetry.position.value.longitude;
        setData({ latitude, longitude });
      }
    } catch (error) {}
  };

  const fetchAssetData = async () => {
    try {
      setLoading(true);
      const response = await AssetApi.get(asset_id);
      setAsset(AssetModel.fromJson(response.data));
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    fetchAssetData();
  }, []);

  return (
    <div className="flex gap-2 h-full">
      {loading ? (
        <DefaultLoading />
      ) : (
        <>
          <MapContainer
            center={[data.latitude, data.longitude]}
            zoom={17}
            className="w-full h-full rounded-lg flex-grow z-0"
            zoomAnimation={true}
          >
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <MarkerAsset lat={data.latitude} lng={data.longitude} zoom={17} />
          </MapContainer>
          <div className="flex flex-col h-full justify-center pl-4 w-1/3">
            <div className="p-2 bg-blue-200 rounded-lg">
              <div className="font-semibold">
                {asset.area_type === "MANUFACTURE" ? "Area" : "Building"}
              </div>
              <div className="text-gray-500 mb-2">{asset.areaLevel1?.name}</div>
              {asset.areaLevel2 && (
                <>
                  <div className="font-semibold">
                    {asset.area_type === "MANUFACTURE" ? "Sub Area" : "Floor"}
                  </div>
                  <div className="text-gray-500 mb-2">
                    {asset.areaLevel2?.name}
                  </div>
                </>
              )}
              {asset.areaLevel3 && (
                <>
                  <div className="font-semibold">
                    {asset.area_type === "MANUFACTURE" ? "Line" : "Room"}
                  </div>
                  <div className="text-gray-500">{asset.areaLevel3?.name}</div>
                </>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
}
