import { useState } from "react";
import { Input } from "../shadcn/components/ui/input";
import { EyeIcon, EyeOffIcon, LockIcon } from "lucide-react";

interface Props {
  label?: string;
  placeholder?: string;
  prefixIcon?: boolean;
  suffixIcon?: boolean;
  value?: string;
  setValue?: any;
}

export default function PasswordInput({
  label = "",
  placeholder = "",
  prefixIcon = false,
  suffixIcon = true,
  value = "",
  setValue,
}: Props) {
  const [showPassword, setShowPassword] = useState(false);

  const widgetInput = (padding?: string) => (
    <Input
      className={`${padding ?? ""} w-full`}
      placeholder={placeholder}
      type={showPassword ? "text" : "password"}
      value={value}
      onChange={(v) => {
        setValue(v.target.value);
      }}
    />
  );

  const widgetFormControl = () => {
    if (prefixIcon || suffixIcon)
      return (
        <div className="relative w-full">
          {prefixIcon && (
            <div className="absolute left-2 h-full flex items-center">
              <LockIcon className="w-4 h-4 stroke-gray-500" />
            </div>
          )}
          {suffixIcon && (
            <div
              className="absolute right-2 h-full flex items-center cursor-pointer"
              onClick={() => {
                setShowPassword(!showPassword);
              }}
            >
              {showPassword ? (
                <EyeOffIcon className="w-4 h-4 stroke-gray-500" />
              ) : (
                <EyeIcon className="w-4 h-4 stroke-gray-500" />
              )}
            </div>
          )}
          {widgetInput(`${prefixIcon && "pl-8"} ${suffixIcon && "pr-8"}`)}
        </div>
      );

    return <div className="w-full">{widgetInput()}</div>;
  };

  return (
    <div>
      {label && <div className="mb-1">{label}</div>}
      {widgetFormControl()}
    </div>
  );
}
