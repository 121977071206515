import { motion } from "framer-motion";
import DetailButton from "shared/ui/button/DetailButton";
import CardTitleActionSmallGeneral from "shared/ui/card/CardTitleActionSmallGeneral";
import DefaultDialog from "shared/ui/dialog/DefaultDialog";
import { AnimationValue } from "shared/values/animationValue";
import { AssetValue } from "shared/values/assetValue";
import ChartHistoryMttr from "features/mttr/ChartHistoryMttr";
import { useNavigate } from "react-router-dom";
import { RouteValue } from "shared/values/routeValue";

export default function MttrManufacture() {
  const navigate = useNavigate();

  return (
    <motion.div
      variants={AnimationValue.list.item}
      className="
      w-full !bg-white rounded-lg border border-gray-100 shadow-lg 
      flex flex-col
      h-[calc((100vh-3.125rem-(6*theme('gap.2'))-5rem)*37.5/100)]"
    >
      <CardTitleActionSmallGeneral
        icon={AssetValue.performanceImage}
        title="MTTR"
        action={
          <div
            onClick={() => {
              navigate(
                RouteValue.manufacturePerformanceDetailDashboard.replace(
                  ":type",
                  "MTTR"
                )
              );
            }}
          >
            <DetailButton />
          </div>
        }
      />
      <div className="flex-grow rounded-lg bg-blue-50 mx-2 mb-2 p-1">
        <ChartHistoryMttr />
      </div>
    </motion.div>
  );
}
