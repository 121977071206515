import { RoleMenu, RoleMenuModel } from "./roleMenuModel";

export interface Role {
  access_all_area?: boolean;
  access_area?: number;
  can_add?: boolean;
  can_approve?: boolean;
  can_delete?: boolean;
  can_edit?: boolean;
  id?: string;
  name?: string;
  role_menu?: RoleMenu[];
}

const fromJson = (json: any): Role => {
  let result: Role = {
    id: json["id"],
    access_all_area: json["access_all_area"] === 1,
    access_area: json["access_area"],
    can_add: json["can_add"] === 1,
    can_approve: json["can_approve"] === 1,
    can_delete: json["can_delete"] === 1,
    can_edit: json["can_edit"] === 1,
    name: json["name"],
  };

  result.role_menu = [];
  if (json["role_menu"])
    for (let index = 0; index < json["role_menu"].length; index++) {
      const item = json["role_menu"][index];
      result.role_menu.push(RoleMenuModel.fromJson(item));
    }

  return result;
};

const toJson = (value: Role): string => {
  return JSON.stringify(value);
};

export const RoleModel = { fromJson, toJson };
