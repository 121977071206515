/* eslint-disable react-hooks/exhaustive-deps */
import { zodResolver } from "@hookform/resolvers/zod";
import { AssetApi } from "entities/api/assetApi";
import { FileApi } from "entities/api/fileApi";
import { FileBarChart2Icon, SaveIcon, UploadCloudIcon } from "lucide-react";
import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import DefaultButton from "shared/ui/button/DefaultButton";
import { Form } from "shared/ui/shadcn/components/ui/form";
import { useToast } from "shared/ui/shadcn/components/ui/use-toast";
import ErrorText from "shared/ui/text/ErrorText";
import { AssetValidationValue } from "shared/values/validations/assetValidationValue";
import { z } from "zod";

interface Props {
  asset_id?: string;
  onFormSubmit: any;
}

export default function FormUploadImageAsset({
  asset_id = "",
  onFormSubmit,
}: Props) {
  const form = useForm<z.infer<typeof AssetValidationValue.uploadImage>>({
    resolver: zodResolver(AssetValidationValue.uploadImage),
    defaultValues: {
      image: "",
    },
  });
  const [loading, setLoading] = useState(false);
  const inputFileRef: any = useRef();
  const [file, setFile] = useState<File | null>(null);
  const { toast } = useToast();

  const onSubmit = async (
    values: z.infer<typeof AssetValidationValue.uploadImage>
  ) => {
    try {
      setLoading(true);
      const reponseUpload = await FileApi.uploadAssetImage(asset_id, file);
      if (!reponseUpload.success) {
        const dataError = reponseUpload.error;
        form.setError(dataError.path, { message: dataError.msg });
        return;
      }
      const image = reponseUpload.data.image;
      const response = await AssetApi.saveImage(asset_id, { image });
      if (!response.success) {
        const dataError = response.error;
        form.setError(dataError.path, { message: dataError.msg });
        return;
      }
      form.clearErrors();
      setFile(null);
      toast({
        title: `Success upload image asset`,
        description: "Success upload image asset",
        className: "bg-emerald-500 !text-white",
      });
      onFormSubmit();
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const onFileChangeCapture = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files != null && e.target.files!.length > 0) {
      setFile(e.target.files[0]);
      form.setValue("image", URL.createObjectURL(e.target.files[0]), {
        shouldValidate: true,
      });
    }
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <input
          type="file"
          ref={inputFileRef}
          onChangeCapture={onFileChangeCapture}
          className="hidden"
          accept="image/jpg,image/jpeg,image/png,image/webp"
        />
        <div
          className="bg-blue-100 rounded-lg w-full h-auto aspect-video p-2 flex items-center justify-center cursor-pointer"
          onClick={() => {
            inputFileRef.current.click();
          }}
        >
          <div className="flex flex-col gap-2 items-center justify-center">
            {file ? (
              <>
                <FileBarChart2Icon className="w-8 h-8" />
                <div className="font-semibold">{file.name}</div>
              </>
            ) : (
              <>
                <UploadCloudIcon className="w-8 h-8" />
                <div className="font-semibold">Choose file</div>
              </>
            )}
          </div>
        </div>
        <ErrorText text={form.formState.errors.image?.message!} />
        {file !== null && (
          <DefaultButton
            label="Import Excel"
            icon={<SaveIcon className="stroke-white w-6 h-6" />}
            loading={loading}
            type="submit"
            className="mt-8"
          />
        )}
      </form>
    </Form>
  );
}
