import { ApiValue } from "shared/values/apiValue";
import { BaseApi, ParamList } from "./baseApi";
import { AreaType } from "entities/model/areaModel";

const save = async (data: any) => {
  return await BaseApi.post({ url: ApiValue.assetSave, data });
};

const edit = async (id: string, data: any) => {
  return await BaseApi.put({
    url: ApiValue.assetEdit.replace("{asset_id}", id),
    data,
  });
};

const remove = async (id: string) => {
  return await BaseApi.deleteData({
    url: ApiValue.assetDelete.replace("{asset_id}", id),
  });
};

const getList = async ({
  limit,
  offset,
  keyword = "",
  approved = 1,
  areaId,
  id = "",
  purchase_date = "",
  pic_id = "",
  warranty = "",
  manufacture_id = "",
  supplier_id = "",
  area_type = "",
}: ParamList) => {
  let url = `${ApiValue.assetGetList}?limit=${limit}&offset=${offset}&approved=${approved}`;
  if (id)
    url = `${ApiValue.assetGetChildList.replace(
      "{asset_id}",
      id
    )}?limit=${limit}&offset=${offset}&approved=${approved}`;
  if (areaId) url = `${url}&area_level_id=${areaId}`;
  if (keyword) url = `${url}&keyword=${keyword}`;
  if (purchase_date) url = `${url}&purchase_date=${purchase_date}`;
  if (pic_id) url = `${url}&pic_id=${pic_id}`;
  if (warranty) url = `${url}&warranty=${warranty}`;
  if (manufacture_id) url = `${url}&manufacture_id=${manufacture_id}`;
  if (supplier_id) url = `${url}&supplier_id=${supplier_id}`;
  if (area_type) url = `${url}&area_type=${area_type}`;
  return await BaseApi.get({ url });
};

const get = async (id: string) => {
  return await BaseApi.get({
    url: ApiValue.assetGet.replace("{asset_id}", id),
  });
};

const getExcelList = async ({ approved = 1, areaId, id = "" }: ParamList) => {
  let url = `${ApiValue.assetGetExcelList}?approved=${approved}`;
  if (id)
    url = `${ApiValue.assetGetExcelChildList.replace(
      "{asset_id}",
      id
    )}?approved=${approved}`;
  if (areaId) url = `${url}&area_level_id=${areaId}`;
  return await BaseApi.get({ url });
};

const getTotal = async ({ areaType, areaId }: ParamList) => {
  let url = `${ApiValue.assetGetTotal}?area_type=${areaType}`;
  if (areaId) {
    url = `${url}&area_id=${areaId}`;
  }
  return await BaseApi.get({ url });
};

const getTotalArea = async (areaType: AreaType) => {
  let url = ApiValue.assetGetTotalArea;
  return await BaseApi.get({ url });
};

const getWarrantyList = async () => {
  let url = ApiValue.assetGetWarrantyList;
  return await BaseApi.get({ url });
};

const saveImage = async (asset_id: string, data: any) => {
  return await BaseApi.post({
    url: ApiValue.assetImageSave.replace("{asset_id}", asset_id),
    data,
  });
};

const removeImage = async (asset_id: string, id: string) => {
  return await BaseApi.deleteData({
    url: ApiValue.assetImageDelete
      .replace("{asset_id}", asset_id)
      .replace("{asset_image_id}", id),
  });
};

const getListDashboard = async ({
  limit,
  offset,
  keyword = "",
  areaId,
  id = "",
  purchase_date = "",
  pic_id = "",
  warranty = "",
  manufacture_id = "",
  supplier_id = "",
  area_type = "",
}: ParamList) => {
  let url = `${ApiValue.assetGetDashboardList}?limit=${limit}&offset=${offset}&approved=1`;
  if (id)
    url = `${ApiValue.assetGetChildList.replace(
      "{asset_id}",
      id
    )}?limit=${limit}&offset=${offset}&approved=1`;
  if (areaId) url = `${url}&area_level_id=${areaId}`;
  if (keyword) url = `${url}&keyword=${keyword}`;
  if (purchase_date) url = `${url}&purchase_date=${purchase_date}`;
  if (pic_id) url = `${url}&pic_id=${pic_id}`;
  if (warranty) url = `${url}&warranty=${warranty}`;
  if (manufacture_id) url = `${url}&manufacture_id=${manufacture_id}`;
  if (supplier_id) url = `${url}&supplier_id=${supplier_id}`;
  if (area_type) url = `${url}&area_type=${area_type}`;
  return await BaseApi.get({ url });
};

export const AssetApi = {
  save,
  edit,
  getList,
  get,
  remove,
  getExcelList,
  getTotal,
  getTotalArea,
  getWarrantyList,
  saveImage,
  removeImage,
  getListDashboard,
};
