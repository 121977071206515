import { zodResolver } from "@hookform/resolvers/zod";
import { format } from "date-fns";
import { AssetOverhaulApi } from "entities/api/assetOverhaulApi";
import { AssetOverhaul } from "entities/model/assetOverhaulModel";
import { SaveIcon } from "lucide-react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import DefaultButton from "shared/ui/button/DefaultButton";
import { DateInput } from "shared/ui/input/DateInput";
import NumberInput from "shared/ui/input/NumberInput";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "shared/ui/shadcn/components/ui/dialog";
import { Form } from "shared/ui/shadcn/components/ui/form";
import { useToast } from "shared/ui/shadcn/components/ui/use-toast";
import ErrorText from "shared/ui/text/ErrorText";
import { AssetValidationValue } from "shared/values/validations/assetValidationValue";
import { z } from "zod";

interface Props {
  children: JSX.Element;
  onFormSubmit?: any;
  data?: AssetOverhaul | null;
  asset_id: string;
}

export default function FormAssetOverhaul({
  children,
  onFormSubmit,
  data = null,
  asset_id,
}: Props) {
  const [open, setOpen] = useState<boolean>(false);
  const form = useForm<z.infer<typeof AssetValidationValue.addOverhaul>>({
    resolver: zodResolver(AssetValidationValue.addOverhaul),
    defaultValues: {
      overhaul_amount: data ? data.overhaul_amount : 0,
      overhaul_date: data
        ? format(data.overhaul_date!, "Y-MM-dd")
        : format(new Date(), "Y-MM-dd"),
    },
  });
  const [loading, setLoading] = useState<boolean>(false);
  const { toast } = useToast();

  const onSubmit = async (
    values: z.infer<typeof AssetValidationValue.addOverhaul>
  ) => {
    try {
      setLoading(true);
      const response = data
        ? await AssetOverhaulApi.edit(asset_id, data.id!, { ...values })
        : await AssetOverhaulApi.save(asset_id, { ...values });
      if (!response.success) {
        const dataError = response.error;
        form.setError(dataError.path, { message: dataError.msg });
        return;
      }
      if (onFormSubmit) {
        onFormSubmit();
      }
      form.clearErrors();
      toast({
        title: `Success ${data === null ? "add" : "edit"} overhaul`,
        description: data === null ? "Success created!" : "Success edited!",
        className: "bg-emerald-500 !text-white",
      });
      setOpen(false);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger
        asChild
        onClick={() => {
          setOpen(true);
        }}
      >
        {children}
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>
            {data ? "Edit AssetOverhaul" : "Add AssetOverhaul"}
          </DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <div className="mb-2">
              <NumberInput
                label="Name"
                placeholder="Enter name manufacture"
                value={form.getValues("overhaul_amount")}
                setValue={(value: number) => {
                  form.setValue("overhaul_amount", parseInt(`${value}`), {
                    shouldValidate: true,
                  });
                }}
              />
              <ErrorText
                text={form.formState.errors.overhaul_amount?.message!}
              />
            </div>
            <div className="mb-10">
              <DateInput
                label="Purchase Date"
                date={new Date(form.getValues("overhaul_date"))}
                setDate={(value: Date) => {
                  form.setValue("overhaul_date", format(value, "Y-MM-dd"), {
                    shouldValidate: true,
                  });
                }}
              />
              <ErrorText text={form.formState.errors.overhaul_date?.message!} />
            </div>
            <DefaultButton
              label="Save changes"
              icon={<SaveIcon className="stroke-white w-6 h-6" />}
              loading={loading}
              type="submit"
            />
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
