import { UploadIcon } from "lucide-react";

export default function UploadButton() {
  return (
    <div className="bg-primary text-white px-2 py-2 rounded-lg cursor-pointer flex items-center gap-2">
      <UploadIcon className="h-4 w-4 stroke-white" />
      Update
    </div>
  );
}
