import { ApiValue } from "shared/values/apiValue";
import { BaseApi, ParamList } from "./baseApi";
import { PIC } from "entities/model/picModel";
import { PICArea } from "entities/model/picAreaModel";

const save = async ({ id, name, avatar, nik }: PIC) => {
  let data = {};
  if (name) data = { ...data, name };
  if (avatar) data = { ...data, avatar };
  if (nik) data = { ...data, nik };
  return await BaseApi.post({ url: ApiValue.picSave, data });
};

const edit = async ({ id, name, avatar, nik }: PIC) => {
  let data = {};
  if (name) data = { ...data, name };
  if (avatar) data = { ...data, avatar };
  if (nik) data = { ...data, nik };
  return await BaseApi.put({
    url: ApiValue.picEdit.replace("{pic_id}", id!),
    data,
  });
};

const remove = async (id: string) => {
  return await BaseApi.deleteData({
    url: ApiValue.picDelete.replace("{pic_id}", id),
  });
};

const getList = async ({ limit, offset, keyword = "" }: ParamList) => {
  let url = `${ApiValue.picGetList}?limit=${limit}&offset=${offset}`;
  if (keyword) url = `${url}&keyword=${keyword}`;
  return await BaseApi.get({ url });
};

const saveArea = async ({ area_level_id, pic_id }: PICArea) => {
  let data = {};
  if (area_level_id) data = { ...data, area_level_id };
  if (pic_id) data = { ...data, pic_id };
  return await BaseApi.post({ url: ApiValue.picAreaSave, data });
};

const removeArea = async (id: string) => {
  return await BaseApi.deleteData({
    url: ApiValue.picAreaDelete.replace("{pic_area_id}", id),
  });
};

const getByAreaList = async ({
  areaId,
  limit,
  offset,
  keyword = "",
}: ParamList) => {
  let url = `${ApiValue.picAreaGetList}?limit=${limit}&offset=${offset}&areaLevelId=${areaId}`;
  if (keyword) url = `${url}&keyword=${keyword}`;
  return await BaseApi.get({ url });
};

export const PICApi = {
  save,
  edit,
  remove,
  getList,
  saveArea,
  removeArea,
  getByAreaList,
};
