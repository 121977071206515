import { SettingValue } from "shared/values/settingValue";
import { create } from "zustand";
import { decodeToken } from "react-jwt";

const signed =
  localStorage.getItem(SettingValue.keyLocalStorageToken) != null &&
  localStorage.getItem(SettingValue.keyLocalStorageToken) !== "";

type State = {
  signed: boolean;
  profileId: string;
};

type Actions = {
  setSigned: (value: boolean) => void;
  setProfileId: (value: string) => void;
};

const handleInitialProfile = () => {
  if (!signed) return "";
  const decode: any = decodeToken(
    localStorage.getItem(SettingValue.keyLocalStorageToken)!
  );
  return decode;
};

export const useAuth = create<State & Actions>((set) => ({
  signed: signed,
  profileId: handleInitialProfile(),
  setSigned: (value: boolean) => set((state) => ({ signed: value })),
  setProfileId: (value: string) => set((state) => ({ profileId: value })),
}));
