import CounterAreaAsset from "features/asset/CounterAreaAsset";
import { motion } from "framer-motion";
import CardTitleActionSmallGeneral from "shared/ui/card/CardTitleActionSmallGeneral";
import { AnimationValue } from "shared/values/animationValue";
import { AssetValue } from "shared/values/assetValue";
import { useNavigate } from "react-router-dom";
import { RouteValue } from "shared/values/routeValue";
import DetailButton from "shared/ui/button/DetailButton";

export default function CounterAssetManufacture() {
  const navigate = useNavigate();
  return (
    <motion.div
      variants={AnimationValue.list.item}
      className="
        w-full !bg-white rounded-lg border border-gray-100 shadow-lg 
        flex flex-col
        h-[calc((100vh-3.125rem-(6*theme('gap.2'))-5rem)*37.5/100)]"
    >
      <CardTitleActionSmallGeneral
        icon={AssetValue.assetImage}
        title="Total Asset Area"
        action={
          <div
            onClick={() => {
              navigate(
                `${RouteValue.manufacturePerformanceDetailDashboard.replace(
                  ":type",
                  "asset-total"
                )}?area_type=MANUFACTURE`
              );
            }}
          >
            <DetailButton />
          </div>
        }
      />
      <div className="flex-grow rounded-lg bg-blue-50 mx-2 mb-2 p-1">
        <CounterAreaAsset
          areaType="MANUFACTURE"
          className="h-[calc(((100vh-3.125rem-(6*theme('gap.2'))-5rem)*37.5/100)-theme('height.6')-theme('margin.2')*4-theme('padding.1')*2)]"
        />
      </div>
    </motion.div>
  );
}
