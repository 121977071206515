import { format } from "date-fns";
import DetailAsset from "features/asset/DetailAsset";
import DownloadQRCodeAsset from "features/asset/DownloadQRCodeAsset";
import ItemDetailAsset from "features/asset/ItemDetailAsset";
import { motion } from "framer-motion";
import { useSearchParams } from "react-router-dom";
import { BookValueHelper } from "shared/helpers/bookValueHelper";
import CardTitleActionGeneral from "shared/ui/card/CardTitleActionGeneral";
import DefaultLoading from "shared/ui/loading/DefaultLoading";
import { ScrollArea } from "shared/ui/shadcn/components/ui/scroll-area";
import { AnimationValue } from "shared/values/animationValue";
import { AssetValue } from "shared/values/assetValue";

export default function AssetDetailManufacture() {
  const [searchParams] = useSearchParams();

  return searchParams.get("asset_id") ? (
    <motion.div
      variants={AnimationValue.list.item}
      className="
        w-full !bg-white rounded-lg border border-gray-100 shadow-lg 
        flex flex-col
        h-[calc((100vh-3.125rem-(5*theme('gap.2'))-5rem)*50/100)]
        col-span-2"
    >
      <CardTitleActionGeneral
        icon={AssetValue.assetImage}
        title="Asset Detail"
        action={
          searchParams.get("asset_id") ? (
            <DownloadQRCodeAsset asset_id={searchParams.get("asset_id")!} />
          ) : (
            <></>
          )
        }
      />
      <ScrollArea
        className="
        bg-blue-50 rounded-lg mx-2
        h-[calc(((100vh-3.125rem-(5*theme('gap.2'))-5rem)*50/100)-theme('height.8')-theme('margin.4'))]
        "
      >
        <DetailAsset
          asset_id={searchParams.get("asset_id")!}
          className="grid grid-cols-2 p-2 gap-2 gap-x-2"
        />
      </ScrollArea>
      <div className="h-2"></div>
    </motion.div>
  ) : (
    <DefaultLoading />
  );
}
