import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { dotPulse, reuleaux } from "ldrs";
import { Toaster } from "shared/ui/shadcn/components/ui/toaster";
import { RouteValue } from "shared/values/routeValue";
import AppRoute from "app/AppRoute";

dotPulse.register();
reuleaux.register();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  // <React.StrictMode>
  <>
    <BrowserRouter basename={RouteValue.userSignIn}>
      <AppRoute />
    </BrowserRouter>
    <Toaster />
  </>

  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
