/* eslint-disable react-hooks/exhaustive-deps */
import { AssetImage } from "entities/model/assetImageModel";
import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuTrigger,
} from "shared/ui/shadcn/components/ui/context-menu";
import { Trash2Icon } from "lucide-react";
import { useEffect, useState } from "react";
import { Asset, AssetModel } from "entities/model/assetModel";
import { AssetApi } from "entities/api/assetApi";
import DeleteAssetImage from "./DeleteAssetImage";
import DefaultLoading from "shared/ui/loading/DefaultLoading";

interface Props {
  className?: string;
  asset_id: string;
  refresh?: boolean;
}

export default function ImageAsset({
  className = "",
  asset_id,
  refresh = false,
}: Props) {
  const [loading, setLoading] = useState<boolean>(true);
  const [asset, setAsset] = useState<Asset>({});

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await AssetApi.get(asset_id);
      setAsset(AssetModel.fromJson(response.data));
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [asset_id, refresh]);

  return (
    <div className={`${className}`}>
      {loading ? (
        <DefaultLoading />
      ) : (
        <>
          {asset?.image?.map((image: AssetImage, index: number) => (
            <ContextMenu key={index}>
              <ContextMenuTrigger asChild>
                <div
                  className={`h-full w-auto aspect-square !bg-center !bg-cover !bg-no-repeat rounded-lg cursor-pointer`}
                  style={{ background: `url("${image.image}")` }}
                  onClick={() => {
                    window.open(image.image, "_blank");
                  }}
                ></div>
              </ContextMenuTrigger>
              <ContextMenuContent className="w-24">
                <DeleteAssetImage
                  asset={asset}
                  callback={async () => {
                    let newDataList = asset.image!.filter(
                      (value: AssetImage, i: number) => i !== index
                    );
                    setAsset({ ...asset, image: newDataList });
                  }}
                  id={image.id!}
                >
                  <div className="flex gap-2 items-center p-2 cursor-pointer hover:bg-blue-50">
                    <Trash2Icon className="w-4 h-4 stroke-red-500" />
                    <div className="text-red-500">Delete</div>
                  </div>
                </DeleteAssetImage>
              </ContextMenuContent>
            </ContextMenu>
          ))}
        </>
      )}
    </div>
  );
}
