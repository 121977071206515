import { motion } from "framer-motion";
import DetailButton from "shared/ui/button/DetailButton";
import CardTitleActionSmallGeneral from "shared/ui/card/CardTitleActionSmallGeneral";
import { AnimationValue } from "shared/values/animationValue";
import { AssetValue } from "shared/values/assetValue";
import { useNavigate } from "react-router-dom";
import { RouteValue } from "shared/values/routeValue";
import ChartHistoryAr from "features/ar/ChartHistoryAr";

export default function ArManufacture() {
  const navigate = useNavigate();

  return (
    <motion.div
      variants={AnimationValue.list.item}
      className="
        w-full !bg-white rounded-lg border border-gray-100 shadow-lg 
        flex flex-col
        h-[calc((100vh-3.125rem-(6*theme('gap.2'))-5rem)*37.5/100)]
        xl:col-span-2"
    >
      <CardTitleActionSmallGeneral
        icon={AssetValue.performanceImage}
        title="Availability Ratio (AR)"
        action={
          <div
            onClick={() => {
              navigate(
                RouteValue.manufacturePerformanceDetailDashboard.replace(
                  ":type",
                  "AR"
                )
              );
            }}
          >
            <DetailButton />
          </div>
        }
      />
      <div className="flex-grow rounded-lg bg-blue-50 mx-2 mb-2 p-1">
        <ChartHistoryAr />
      </div>
    </motion.div>
  );
}
