import { StringUtil } from "shared/utils/stringUtil";
import { Role, RoleModel } from "./roleModel";
import { Area } from "./areaModel";

export interface User {
  id?: string;
  username?: string;
  display_name?: string;
  avatar?: string;
  role?: Role;
  new_password?: string;
  old_password?: string;
  role_id?: string;
  area?: Area;
}

const fromJson = (json: any): User => {
  let user: User = {
    id: json["id"],
    username: json["username"],
    display_name: json["display_name"],
    role_id: json["role_id"],
    avatar:
      json["avatar"] ?? StringUtil.getAvatarDefault(json["display_name"] ?? ""),
  };
  if (json["role"]) user.role = RoleModel.fromJson(json["role"]);
  if (json["user_area"]) user.area = RoleModel.fromJson(json["user_area"]);
  return user;
};

const toJson = (value: User): string => {
  return JSON.stringify(value);
};

export const UserModel = { fromJson, toJson };
