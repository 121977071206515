import { ApiValue } from "shared/values/apiValue";
import { BaseApi, ParamList } from "./baseApi";

const getList = async ({
  limit,
  offset,
  keyword = "",
  areaId = "",
  areaType = "MANUFACTURE",
  level = 1,
  parentId = "",
}: ParamList) => {
  let url = `${ApiValue.areaGetList}?limit=${limit}&offset=${offset}&areaType=${areaType}&level=${level}`;
  if (keyword) url = `${url}&keyword=${keyword}`;
  if (areaId) url = `${url}&areaId=${areaId}`;
  if (parentId) url = `${url}&parentId=${parentId}`;
  return await BaseApi.get({ url });
};

const getHirarki = async (area_id: string) => {
  let url = `${ApiValue.areaGetHirarki.replace("{area_id}", area_id)}`;
  return await BaseApi.get({ url });
};

const getAssetTotal = async ({
  areaType = "MANUFACTURE",
  level = 1,
  parentId = "",
}: ParamList) => {
  let url = `${ApiValue.areaGetAssetTotal}?areaType=${areaType}&level=${level}`;
  if (parentId) url = `${url}&parentId=${parentId}`;
  return await BaseApi.get({ url });
};

const get = async (area_id: string) => {
  let url = `${ApiValue.areaGet.replace("{area_id}", area_id)}`;
  return await BaseApi.get({ url });
};

export const AreaApi = { getList, getHirarki, getAssetTotal, get };
