/* eslint-disable react-hooks/exhaustive-deps */
import SelectArea from "features/area/SelectArea";
import SelectPIC from "features/pic/SelectPIC";
import AccordionCard from "shared/ui/card/AccordionCard";
import { DateInput } from "shared/ui/input/DateInput";
import TextInput from "shared/ui/input/TextInput";
import SelectManufacture from "features/manufacture/SelectManufacture";
import SelectSupplier from "features/supplier/SelectSupplier";
import { ScrollArea } from "shared/ui/shadcn/components/ui/scroll-area";
import { Area, AreaType } from "entities/model/areaModel";
import { useEffect, useState } from "react";
import { useAssetFilter } from "app/hooks/assetFilterHook";
import { format } from "date-fns";
import { PIC } from "entities/model/picModel";
import { Manufacture } from "entities/model/manufactureModel";
import { Supplier } from "entities/model/supplierModel";
import SelectWarrantyAsset from "./SelectWarrantyAsset";

interface Props {
  areaType: AreaType;
}

export default function FilterAsset({ areaType }: Props) {
  const { assetFilter, setAssetFilter } = useAssetFilter();
  const [purchaseDate, setPurchaseDate] = useState<Date>();
  const [areaLevel1, setAreaLevel1] = useState<Area>({ id: "" });
  const [areaLevel2, setAreaLevel2] = useState<Area>({ id: "" });
  const [areaLevel3, setAreaLevel3] = useState<Area>({ id: "" });
  const [pic, setPic] = useState<PIC>({ id: "" });
  const [manufacture, setManufacture] = useState<Manufacture>({ id: "" });
  const [supplier, setSupplier] = useState<Supplier>({ id: "" });

  const init = () => {
    setAreaLevel1({ id: assetFilter.area_level1 });
    setAreaLevel2({ id: assetFilter.area_level2 });
    setAreaLevel3({ id: assetFilter.area_level3 });
  };

  useEffect(() => {
    init();
  }, [assetFilter.area_level_id, assetFilter.area_type]);

  return (
    <AccordionCard title="Filter">
      <ScrollArea className="h-72">
        <div className="py-2">
          <TextInput
            placeholder="Type code or asset name..."
            label="Search"
            value={assetFilter.keyword}
            setValue={(value: string) => {
              setAssetFilter({ ...assetFilter, keyword: value });
            }}
          />
          <div className="h-2"></div>
          <DateInput
            date={purchaseDate}
            setDate={(value: Date) => {
              setAssetFilter({
                ...assetFilter,
                purchase_date: format(value, "Y-MM-dd"),
              });
              setPurchaseDate(value);
            }}
            label="Purchase Date"
          />
          <div className="h-2"></div>
          {areaType === "OFFICE" && (
            <>
              <SelectArea
                areaType={areaType}
                label={areaType === "OFFICE" ? "Building" : "Area"}
                level={1}
                value={areaLevel1}
                setValue={(value: Area) => {
                  setAreaLevel1(value);
                  setAreaLevel2({ id: "" });
                  setAreaLevel3({ id: "" });
                  setAssetFilter({
                    ...assetFilter,
                    area_level_id: value.id,
                  });
                }}
              />
              <div className="h-2"></div>
            </>
          )}
          <>
            <SelectArea
              areaType={areaType}
              label={areaType === "MANUFACTURE" ? "Sub Area" : "Floor"}
              level={2}
              value={areaLevel2}
              setValue={(value: Area) => {
                setAreaLevel2(value);
                setAreaLevel3({ id: "" });
                setAssetFilter({
                  ...assetFilter,
                  area_level2: value.id,
                  area_level_id: value.id,
                });
              }}
              areaId={areaLevel1.area_id}
              disabled={areaLevel1.id === ""}
              parentId={areaLevel1.id}
            />
            <div className="h-2"></div>
          </>
          <>
            <SelectArea
              areaType={areaType}
              label={areaType === "MANUFACTURE" ? "Line" : "Room"}
              level={3}
              value={areaLevel3}
              setValue={(value: Area) => {
                setAreaLevel3(value);
                setAssetFilter({
                  ...assetFilter,
                  area_level3: value.id,
                  area_level_id: value.id,
                });
              }}
              areaId={areaLevel2.area_id}
              disabled={areaLevel2.id === ""}
              parentId={areaLevel2.id}
            />
            <div className="h-2"></div>
          </>
          <SelectPIC
            label="PIC"
            value={pic}
            setValue={(value: PIC) => {
              setAssetFilter({ ...assetFilter, pic_id: value.id });
              setPic(value);
            }}
          />
          <div className="h-2"></div>
          <SelectWarrantyAsset
            label="Warranty"
            value={assetFilter.warranty!}
            setValue={(value: string) => {
              setAssetFilter({ ...assetFilter, warranty: value });
            }}
          />
          <div className="h-2"></div>
          <SelectManufacture
            label="Manufacture"
            value={manufacture}
            setValue={(value: Manufacture) => {
              setAssetFilter({ ...assetFilter, manufacture_id: value.id });
              setManufacture(value);
            }}
          />
          <div className="h-2"></div>
          <SelectSupplier
            label="Supplier"
            value={supplier}
            setValue={(value: Supplier) => {
              setAssetFilter({ ...assetFilter, supplier_id: value.id });
              setSupplier(value);
            }}
          />
        </div>
      </ScrollArea>
    </AccordionCard>
  );
}
