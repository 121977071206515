import { motion } from "framer-motion";
import ListPIC from "features/pic/ListPIC";
import CardTitleSmallGeneral from "shared/ui/card/CardTitleSmallGeneral";
import { AnimationValue } from "shared/values/animationValue";
import { AssetValue } from "shared/values/assetValue";

export default function PICManufacture() {
  return (
    <motion.div
      variants={AnimationValue.list.item}
      className="
        w-full !bg-white rounded-lg border border-gray-100 shadow-lg 
        flex flex-col
        h-[calc((100vh-3.125rem-(6*theme('gap.2'))-5rem)*25/100)]"
    >
      <CardTitleSmallGeneral
        icon={AssetValue.employeeImage}
        title="PIC of Area"
      />
      <div className="flex-grow rounded-lg mx-2 mb-2">
        <ListPIC
          classNameContainer="h-[calc(((100vh-3.125rem-(6*theme('gap.2'))-5rem)*25/100)-theme('height.6')-theme('margin.2')*3)]
            w-full
            overflow-hidden
            overflow-x-auto
            scroll-smooth
            scrollbar-custom
            grid grid-rows-1 min-[1440px]:grid-rows-2 grid-flow-col gap-2"
          classNameItem="h-[calc(((100vh-3.125rem-(6*theme('gap.2'))-5rem)*25/100)-theme('height.6')-theme('margin.2')*3)]
            min-[1440px]:h-[calc(((((100vh-3.125rem-(6*theme('gap.2'))-5rem)*25/100)-theme('height.6')-theme('margin.2')*3)/2)-theme('gap.2'))]
            w-60"
        />
      </div>
    </motion.div>
  );
}
