import { ApiValue } from "shared/values/apiValue";
import { BaseApi } from "./baseApi";

const getAvg = async (type: string, area_id?: string) => {
  return await BaseApi.get({
    url: ApiValue.performanceGetAvg.replace("{type}", type),
  });
};

const getOEEList = async () => {
  return await BaseApi.get({
    url: ApiValue.performanceGetOEE,
  });
};

const getHistory = async (type: string, area_id?: string) => {
  return await BaseApi.get({
    url: ApiValue.performanceGetHistory.replace("{type}", type),
  });
};

export const PerformanceApi = {
  getAvg,
  getOEEList,
  getHistory,
};
