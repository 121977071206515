/* eslint-disable react-hooks/exhaustive-deps */

import { PIC, PICModel } from "entities/model/picModel";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import DefaultLoading from "shared/ui/loading/DefaultLoading";
import { AnimationValue } from "shared/values/animationValue";
import ItemPIC from "./ItemPIC";
import { PICApi } from "entities/api/picApi";

interface Props {
  classNameContainer?: string;
  classNameItem?: string;
}

export default function ListPIC({ classNameContainer, classNameItem }: Props) {
  const [dataList, setDataList] = useState<PIC[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await PICApi.getList({ offset: 0, limit: 0 });
      if (response.success) {
        const picList: PIC[] = [];
        for (const key in response.dataList) {
          const item = response.dataList[key];
          picList.push(PICModel.fromJson(item));
        }
        setDataList(picList);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return loading ? (
    <DefaultLoading />
  ) : (
    <motion.ul
      variants={AnimationValue.list.container}
      initial="hidden"
      animate="visible"
      className={classNameContainer}
    >
      {dataList.map((data: PIC, index: number) => (
        <motion.li
          variants={AnimationValue.list.item}
          key={index}
          className={classNameItem}
        >
          <ItemPIC data={data} />
        </motion.li>
      ))}
    </motion.ul>
  );
}
