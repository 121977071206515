/* eslint-disable react-hooks/exhaustive-deps */
import { Outlet } from "react-router-dom";
import CounterOffice from "widgets/office/CounterOffice";

export default function DashboardOfficeLayout() {
  return (
    <div
      className=" 
      flex flex-col gap-2 
      xl:h-[calc(100vh-3.125rem-(2*theme('gap.2')))]
      pt-[calc(3.125rem+theme('padding.4'))] xl:pt-0
      px-2 xl:px-0
      "
    >
      <CounterOffice />
      <Outlet />
    </div>
  );
}
