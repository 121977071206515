import { SearchIcon } from "lucide-react";
import { useEffect, useState } from "react";
import TextInput from "shared/ui/input/TextInput";
import {
  CommandDialog,
  CommandInput,
} from "shared/ui/shadcn/components/ui/command";
import { SettingValue } from "shared/values/settingValue";
import SearchListAsset from "./SearchListAsset";

export default function SearchAsset() {
  const [open, setOpen] = useState<boolean>(false);
  const [keyword, setKeyword] = useState<string>("");

  useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (e.key === "k" && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        setOpen((open) => !open);
      }
    };
    document.addEventListener("keydown", down);
    return () => document.removeEventListener("keydown", down);
  }, []);

  useEffect(() => {
    setKeyword("");
  }, [open]);

  return (
    <>
      <div
        onClick={() => {
          setOpen(true);
        }}
      >
        <TextInput
          placeholder="Search..."
          prefixIcon={<SearchIcon className="w-4 h-4 stroke-gray-500" />}
          suffixIcon={
            <kbd className="pointer-events-none inline-flex h-5 select-none items-center gap-1 rounded border bg-muted px-1.5 font-mono text-[10px] font-medium text-muted-foreground opacity-100">
              <span className="text-xs">
                {SettingValue.platform === "windows" ? "Ctrl" : "⌘"}
              </span>
              K
            </kbd>
          }
        />
      </div>
      <CommandDialog open={open} onOpenChange={setOpen}>
        <CommandInput
          placeholder="Search by name or code asset"
          value={keyword}
          onValueChange={setKeyword}
        />
        {keyword && <SearchListAsset keyword={keyword} />}
      </CommandDialog>
    </>
  );
}
