/* eslint-disable react-hooks/exhaustive-deps */
import { ManufactureApi } from "entities/api/manufactureApi";
import { useEffect, useState } from "react";
import { AssetValue } from "shared/values/assetValue";
import CounterDashboard from "widgets/dashboard/CounterDashboard";

export default function CounterManufacture() {
  const [counter, setCounter] = useState<number>(0);

  const getCounter = async () => {
    try {
      let response: any = null;
      response = await ManufactureApi.getTotal();
      if (response.success) {
        setCounter(response.data.counter);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getCounter();
  }, []);

  return (
    <CounterDashboard
      counter={counter}
      icon={AssetValue.factoryImage}
      label="Manufacture Total"
    />
  );
}
