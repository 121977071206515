import { Asset, AssetModel } from "./assetModel";
import { User, UserModel } from "./userModel";

export type NotifType = "ASSET_NOT_APPROVED" | "ASSET_APPROVED" | "ASSET_ADD";

export interface NotifData {
  asset?: Asset;
  sender?: User;
}

const notiDataFromJson = (json: any): NotifData => {
  let result: NotifData = {};

  if (json["asset"]) result.asset = AssetModel.fromJson(json["asset"]);
  if (json["sender"]) result.sender = UserModel.fromJson(json["sender"]);

  return result;
};

export interface Notification {
  id?: string;
  title?: string;
  body?: string;
  notif_type?: NotifType;
  opened?: boolean;
  notif_data?: NotifData;
  created_at?: Date | null;
  sender_id?: string;
  reciever_id?: string;
  sender?: User;
  reciever?: User;
}

const fromJson = (json: any): Notification => {
  let result: Notification = {
    id: json["id"],
    title: json["title"],
    body: json["body"],
    notif_type: json["notif_type"],
    opened: json["opened"] === 1,
    created_at: json["created_at"] ? new Date(json["created_at"]) : null,
    sender_id: json["sender_id"],
    reciever_id: json["reciever_id"],
  };
  if (json["notif_data"])
    result.notif_data = notiDataFromJson(JSON.parse(json["notif_data"]));
  if (json["sender"]) result.sender = UserModel.fromJson(json["sender"]);
  if (json["reciever"]) result.reciever = UserModel.fromJson(json["reciever"]);

  return result;
};

const toJson = (value: Notification): string => {
  return JSON.stringify(value);
};

export const NotificationModel = { fromJson, toJson };
