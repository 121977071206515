/* eslint-disable react-hooks/exhaustive-deps */
import { useAssetFilter } from "app/hooks/assetFilterHook";
import { AreaApi } from "entities/api/areaApi";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { Outlet, useParams } from "react-router-dom";
import PageLoading from "shared/ui/loading/PageLoading";
import { AnimationValue } from "shared/values/animationValue";
import AssetListManufacture from "widgets/manufacture/AssetListManufacture";

export default function DashboardAreaManufactureLayout() {
  const { area_id } = useParams();
  const { assetFilter, setAssetFilter } = useAssetFilter();
  const [loading, setLoading] = useState<boolean>(true);

  const handleParam = async () => {
    if (area_id) {
      setLoading(true);
      const response = await AreaApi.getHirarki(area_id);
      setAssetFilter({
        ...assetFilter,
        area_level_id: area_id,
        area_type: "MANUFACTURE",
        area_level1: response.data.areaLevel1.id,
        area_level2: response.data.areaLevel2 ?? "",
        area_level3: response.data.areaLevel3 ?? "",
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    handleParam();
  }, [area_id]);

  return loading ? (
    <PageLoading />
  ) : (
    <motion.div
      variants={AnimationValue.list.container}
      initial="hidden"
      animate="visible"
      className="
        grid grid-cols-1 xl:grid-cols-4 xl:gap-2
        xl:h-[calc(100vh-3.125rem-(4*theme('gap.2'))-5rem)]
        "
    >
      <AssetListManufacture />
      <div className="h-4 xl:hidden"></div>
      <Outlet />
    </motion.div>
  );
}
