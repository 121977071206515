/* eslint-disable react-hooks/exhaustive-deps */
import { AssetApi } from "entities/api/assetApi";
import { Area, AreaType } from "entities/model/areaModel";
import { AssetModel } from "entities/model/assetModel";
import SelectArea from "features/area/SelectArea";
import TableAsset from "features/asset/TableAsset";
import { FilterIcon } from "lucide-react";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import DefaultButton from "shared/ui/button/DefaultButton";
import DefaultLoading from "shared/ui/loading/DefaultLoading";

interface Props {
  areaType: AreaType;
}

export default function FilterPerformanceManufacture({ areaType }: Props) {
  const [areaLevel1, setAreaLevel1] = useState<Area>({ id: "" });
  const [areaLevel2, setAreaLevel2] = useState<Area>({ id: "" });
  const [areaLevel3, setAreaLevel3] = useState<Area>({ id: "" });
  const [area, setArea] = useState<Area>({ id: "" });
  const [loading, setLoading] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const [loadingPage, setLoadingPage] = useState<boolean>(false);

  const handleParam = async () => {
    try {
      setLoadingPage(true);
      const responseAsset = await AssetApi.get(searchParams.get("asset_id")!);
      const asset = AssetModel.fromJson(responseAsset.data);
      switch (asset.area?.level) {
        case 1:
          setAreaLevel1(asset.areaLevel1!);
          break;
        case 2:
          setAreaLevel1(asset.areaLevel1!);
          setAreaLevel2(asset.areaLevel2!);
          break;
        case 3:
          setAreaLevel1(asset.areaLevel1!);
          setAreaLevel2(asset.areaLevel2!);
          setAreaLevel3(asset.areaLevel3!);
          break;
        default:
          break;
      }
      setArea(asset.area!);
    } catch (error) {
    } finally {
      setLoadingPage(false);
    }
  };

  const resetAllArealLevel = () => {
    setArea({ id: "" });
    setAreaLevel1({ id: "" });
    setAreaLevel2({ id: "" });
    setAreaLevel3({ id: "" });
  };

  useEffect(() => {
    resetAllArealLevel();
  }, [areaType]);

  useEffect(() => {
    if (searchParams.get("asset_id")) {
      handleParam();
    }
  }, [searchParams]);

  return loadingPage ? (
    <DefaultLoading />
  ) : (
    <>
      <div className="bg-blue-100 p-2 rounded-lg">
        <div className="font-semibold mb-2">Area Filter</div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-2">
          <SelectArea
            areaType={areaType}
            label={areaType === "MANUFACTURE" ? "Area" : "Building"}
            level={1}
            value={areaLevel1}
            setValue={(value: Area) => {
              setAreaLevel1(value);
              setAreaLevel2({ id: "" });
              setAreaLevel3({ id: "" });
            }}
            withAll={false}
          />
          <SelectArea
            areaType={areaType}
            label={areaType === "MANUFACTURE" ? "Sub Area" : "Floor"}
            level={2}
            value={areaLevel2}
            setValue={(value: Area) => {
              setAreaLevel2(value);
              setAreaLevel3({ id: "" });
            }}
            areaId={areaLevel1.area_id}
            disabled={areaLevel1.id === ""}
            withAll={false}
            parentId={areaLevel1.id}
          />
          <SelectArea
            areaType={areaType}
            label={areaType === "MANUFACTURE" ? "Line" : "Room"}
            level={3}
            value={areaLevel3}
            setValue={(value: Area) => {
              setAreaLevel3(value);
            }}
            areaId={areaLevel2.area_id}
            disabled={areaLevel2.id === ""}
            withAll={false}
            parentId={areaLevel2.id}
          />
        </div>
        <div className="mt-2 flex justify-end">
          <DefaultButton
            label="Filter"
            icon={<FilterIcon className="w-4 h-4 stroke-white" />}
            disabled={areaLevel1.id === ""}
            onClick={() => {
              setLoading(true);
              if (areaLevel3.id !== "") {
                setArea(areaLevel3);
              } else if (areaLevel2.id !== "") {
                setArea(areaLevel2);
              } else if (areaLevel1.id !== "") {
                setArea(areaLevel1);
              } else {
                setArea({ id: "" });
              }
              setLoading(false);
            }}
            className="w-max"
          />
        </div>
      </div>
      {area.id !== "" && !loading && (
        <div className="mt-2">
          <TableAsset area={area} areaType={areaType} />
        </div>
      )}
    </>
  );
}
