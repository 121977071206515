import DetailAsset from "features/asset/DetailAsset";
import DownloadQRCodeAsset from "features/asset/DownloadQRCodeAsset";
import { motion } from "framer-motion";
import { useSearchParams } from "react-router-dom";
import CardTitleActionGeneral from "shared/ui/card/CardTitleActionGeneral";
import { ScrollArea } from "shared/ui/shadcn/components/ui/scroll-area";
import { AnimationValue } from "shared/values/animationValue";
import { AssetValue } from "shared/values/assetValue";

export default function AssetDetailOffice() {
  const [searchParams] = useSearchParams();

  return (
    <motion.div
      variants={AnimationValue.list.item}
      className="
      w-full !bg-white rounded-lg border border-gray-100 shadow-lg 
      flex flex-col
      xl:h-[calc(((100vh-3.125rem-(4*theme('gap.2'))-5rem)*50/100)-theme('gap.1'))]
      col-span-2"
    >
      <CardTitleActionGeneral
        icon={AssetValue.assetImage}
        title="Asset Detail"
        action={
          searchParams.get("asset_id") ? (
            <DownloadQRCodeAsset asset_id={searchParams.get("asset_id")!} />
          ) : (
            <></>
          )
        }
      />
      <ScrollArea
        className="
        bg-blue-50 rounded-lg mx-2
          h-full
        "
      >
        {searchParams.get("asset_id") && (
          <DetailAsset
            asset_id={searchParams.get("asset_id")!}
            className="grid grid-cols-2 xl:grid-cols-3 p-2 gap-2 gap-y-10"
          />
        )}
      </ScrollArea>
      <div className="h-2"></div>
    </motion.div>
  );
}
