export interface Manufacture {
  id?: string;
  name?: string;
}

const fromJson = (json: any): Manufacture => {
  let result: Manufacture = {
    id: json["id"],
    name: json["name"],
  };

  return result;
};

const toJson = (value: Manufacture): string => {
  return JSON.stringify(value);
};

export const ManufactureModel = { fromJson, toJson };
