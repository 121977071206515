export interface AssetImage {
  id?: string;
  asset_id?: string;
  image?: string;
}

const fromJson = (json: any): AssetImage => {
  let model: AssetImage = {
    id: json["id"],
    asset_id: json["asset_id"],
    image: json["image"],
  };

  return model;
};

export const AssetImageModel = { fromJson };
