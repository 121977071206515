import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import { Form } from "shared/ui/shadcn/components/ui/form";
import DefaultButton from "shared/ui/button/DefaultButton";
import { useProfile } from "app/hooks/profileHook";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "shared/ui/shadcn/components/ui/dialog";
import { SaveIcon, UserIcon } from "lucide-react";
import { UserValidationValue } from "shared/values/validations/userValidationValue";
import TextInput from "shared/ui/input/TextInput";
import { UserApi } from "entities/api/userApi";
import ErrorText from "shared/ui/text/ErrorText";

interface Props {
  children: JSX.Element;
}

export default function FormProfileInfoUser({ children }: Props) {
  const { profile, setProfile } = useProfile();
  const [open, setOpen] = useState<boolean>(false);
  const form = useForm<z.infer<typeof UserValidationValue.editInfo>>({
    resolver: zodResolver(UserValidationValue.editInfo),
    defaultValues: {
      username: profile?.username,
      display_name: profile?.display_name,
    },
  });
  const [loading, setLoading] = useState(false);

  const onSubmit = async (
    values: z.infer<typeof UserValidationValue.editInfo>
  ) => {
    try {
      setLoading(true);
      const response = await UserApi.edit({
        id: profile?.id,
        display_name: values.display_name,
        username: values.username,
      });
      if (!response.success) {
        const dataError = response.error;
        form.setError(dataError.path, { message: dataError.msg });
        return;
      }
      setProfile({
        ...profile,
        display_name: values.display_name,
        username: values.username,
      });
      form.clearErrors();
      setOpen(false);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger
        asChild
        onClick={() => {
          setOpen(true);
        }}
      >
        {children}
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Edit Your Profile</DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <div className="mb-2">
              <TextInput
                label="Username"
                placeholder="Enter your username"
                prefixIcon={<UserIcon className="w-4 h-4 stroke-gray-500" />}
                value={form.getValues("username")}
                setValue={(value: string) => {
                  form.setValue("username", value, { shouldValidate: true });
                }}
              />
              <ErrorText text={form.formState.errors.username?.message!} />
            </div>
            <div className="mb-10">
              <TextInput
                label="Display Name"
                placeholder="Enter your display name"
                prefixIcon={<UserIcon className="w-4 h-4 stroke-gray-500" />}
                value={form.getValues("display_name")}
                setValue={(value: string) => {
                  form.setValue("display_name", value, {
                    shouldValidate: true,
                  });
                }}
              />
              <ErrorText text={form.formState.errors.display_name?.message!} />
            </div>
            <DefaultButton
              label="Save changes"
              icon={<SaveIcon className="stroke-white w-6 h-6" />}
              loading={loading}
              type="submit"
            />
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
