import PositionAsset from "features/asset/PositionAsset";
import { motion } from "framer-motion";
import { useSearchParams } from "react-router-dom";
import CardTitleGeneral from "shared/ui/card/CardTitleGeneral";
import { AnimationValue } from "shared/values/animationValue";
import { AssetValue } from "shared/values/assetValue";

export default function AssetPositionManufacture() {
  const [searchParams] = useSearchParams();
  return (
    <motion.div
      variants={AnimationValue.list.item}
      className="
      w-full !bg-white rounded-lg border border-gray-100 shadow-lg 
      flex flex-col
      h-[100vw]
      xl:h-[calc((100vh-3.125rem-(5*theme('gap.2'))-5rem)*75/100)]
      xl:col-span-2"
    >
      <CardTitleGeneral icon={AssetValue.mapImage} title="Position Asset" />
      <div className="flex-grow rounded-lg bg-blue-50 mx-2 mb-2 p-1">
        {searchParams.get("asset_id") && (
          <PositionAsset asset_id={searchParams.get("asset_id")!} />
        )}
      </div>
    </motion.div>
  );
}
