/* eslint-disable react-hooks/exhaustive-deps */
import { SupplierApi } from "entities/api/supplierApi";
import { useEffect, useState } from "react";
import { AssetValue } from "shared/values/assetValue";
import CounterDashboard from "widgets/dashboard/CounterDashboard";

export default function CounterSupplier() {
  const [counter, setCounter] = useState<number>(0);

  const getCounter = async () => {
    try {
      let response: any = null;
      response = await SupplierApi.getTotal();
      if (response.success) {
        setCounter(response.data.counter);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getCounter();
  }, []);

  return (
    <CounterDashboard
      counter={counter}
      icon={AssetValue.factoryImage}
      label="Supplier Total"
    />
  );
}
