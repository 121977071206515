import { Area } from "entities/model/areaModel";
import { TrendingDownIcon, TrendingUpIcon } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { RouteValue } from "shared/values/routeValue";

interface Props {
  data: Area;
}

export default function OEEItem({ data }: Props) {
  const navigate = useNavigate();

  return (
    <div
      className={`flex flex-col bg-blue-50 rounded-lg mx-2 mb-2 p-2 cursor-pointer`}
      onClick={() => {
        navigate(
          `${RouteValue.manufactureAreaDashboard.replace(
            ":area_id",
            data.id!
          )}?level=${data.level}`
        );
      }}
    >
      <div className="flex gap-2 items-center">
        <div>
          <label className="block font-semibold">{data.name}</label>
          {/* <label className="block text-gray-400 text-sm font-semibold">
            {subAreaName} {lineName && `- ${lineName}`}
          </label> */}
        </div>
        {data.oee! > data.oee_yesterday! ? (
          <TrendingUpIcon className="w-6 h-6 stroke-green-400" />
        ) : (
          <TrendingDownIcon className="w-6 h-6 stroke-yellow-400" />
        )}
        <label
          className={`font-semibold text-sm ${
            data.oee! > data.oee_yesterday!
              ? "text-green-400"
              : "text-yellow-400"
          }`}
        >
          {(data.oee! - data.oee_yesterday!).toFixed(2)}%
        </label>
      </div>
      <div className="flex flex-col gap-1">
        <div className="flex justify-between">
          <label className="font-semibold text-green-400 text-sm">
            Running: {(100 - data.asset_off!).toFixed(2)}%
          </label>
          <label className="font-semibold text-yellow-400 text-sm">
            Off: {data.asset_off!}%
          </label>
        </div>
        <div className="flex h-3 w-full">
          <div
            className={`${
              100 - data.asset_off! === 100 ? "rounded-full" : "rounded-l-full"
            } ${
              100 - data.asset_off! >= 90
                ? "bg-green-400"
                : 100 - data.asset_off! >= 80
                ? "bg-yellow-500"
                : "bg-red-400"
            }`}
            style={{ width: `${(100 - data.asset_off!).toFixed(2)}%` }}
          ></div>
          <div
            className={`flex-grow bg-gray-400 ${
              data.asset_off! >= 100 ? "rounded-full" : "rounded-r-full"
            }`}
          ></div>
        </div>
      </div>
    </div>
  );
}
