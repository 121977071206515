import { Paginations } from "entities/model/paginationModel";
import DefaultButton from "../button/DefaultButton";
import { ChevronLeft, ChevronRight } from "lucide-react";

interface Props {
  pagination: Paginations;
  setPagination: any;
}

export default function PaginationTable({ pagination, setPagination }: Props) {
  return (
    <div className="flex justify-between items-center my-2 px-2">
      <div className="">
        Page {pagination.page} of {pagination.total_page} page
      </div>
      <div className="flex items-center gap-2">
        <DefaultButton
          label="Prev"
          variant={"outline"}
          icon={<ChevronLeft className="w-4 h-4" />}
          disabled={pagination.page === 1}
          onClick={() => {
            setPagination({ ...pagination, page: pagination.page! - 1 });
          }}
          type="button"
        />
        <DefaultButton
          label="Next"
          variant={"outline"}
          suffixIcon={<ChevronRight className="w-4 h-4" />}
          disabled={pagination.page === pagination.total_page}
          onClick={() => {
            setPagination({ ...pagination, page: pagination.page! + 1 });
          }}
          type="button"
        />
      </div>
    </div>
  );
}
