import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../shadcn/components/ui/dialog";

interface Props {
  button: JSX.Element;
  title?: string;
  className?: string;
  children?: JSX.Element;
}

export default function DefaultDialog({
  button,
  title = "",
  className = "sm:max-w-[720px]",
  children,
}: Props) {
  return (
    <Dialog>
      <DialogTrigger asChild>
        <div>{button}</div>
      </DialogTrigger>
      <DialogContent className={`${className}`}>
        <DialogHeader>
          <DialogTitle>{title}</DialogTitle>
        </DialogHeader>
        {children}
      </DialogContent>
    </Dialog>
  );
}
