import { SettingValue } from "shared/values/settingValue";
import { ApiValue } from "shared/values/apiValue";
import { PaginationModel, Paginations } from "entities/model/paginationModel";
import { AreaType } from "entities/model/areaModel";

const headerSignIn = async () => {
  const secretKey = SettingValue.secretKeySignIn;
  return {
    Authorization: `Bearer ${secretKey}`,
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  };
};

const headerWithToken = () => {
  const token = localStorage.getItem(SettingValue.keyLocalStorageToken);
  return {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  };
};

const headerFormMultipart = () => {
  const token = localStorage.getItem(SettingValue.keyLocalStorageToken);
  return {
    Authorization: `Bearer ${token}`,
    "Access-Control-Allow-Origin": "*",
  };
};

type Header = "signIn" | "withToken" | "formMultipart";

type Props = {
  url: string;
  data?: any | null;
  header?: Header;
};

type Result = {
  success: boolean;
  data: any;
  error: any;
  dataList: any[];
  pagination: Paginations;
};

const handleHeader = async (header: Header) => {
  switch (header) {
    case "signIn":
      return await headerSignIn();
    case "withToken":
      return headerWithToken();
    case "formMultipart":
      return headerFormMultipart();
    default:
      return {};
  }
};

const getRefreshToken = async () => {
  const headers = headerWithToken();
  const response = await fetch(ApiValue.userGetToken, {
    method: "POST",
    headers,
  });
  if (response.status !== 200) return false;
  const data = await response.json();
  localStorage.setItem(SettingValue.keyLocalStorageToken, data.data.token);
  return true;
};

const handleResponse = async (response: Response, method: any) => {
  let result: Result = {
    success: false,
    data: {},
    error: {},
    dataList: [],
    pagination: PaginationModel.handleInitial(0),
  };
  switch (response.status) {
    case 200:
      result.success = true;
      break;
    case 401:
      let resultRefreshToken = false;
      for (let index = 0; index < 3; index++) {
        resultRefreshToken = await getRefreshToken();
      }
      if (!resultRefreshToken) {
        return result;
      }
      result = await method();
      break;
    case 400:
      break;
    default:
      throw new Error();
  }
  const responseData = await response.json();
  if (responseData.data) result.data = responseData.data;
  if (responseData.dataList) result.dataList = responseData.dataList;
  if (responseData.pagination)
    result.pagination = PaginationModel.fromJson(responseData.pagination);
  if (responseData.error) {
    result.success = false;
    result.error = responseData.error;
  }
  return result;
};

const post = async ({ url, data, header = "withToken" }: Props) => {
  const headers = await handleHeader(header);
  const response = await fetch(url, {
    method: "POST",
    body: JSON.stringify(data ?? {}),
    headers,
  });
  return handleResponse(response, async () => {
    return await post({ url, data, header });
  });
};

const put = async ({ url, data, header = "withToken" }: Props) => {
  const headers = await handleHeader(header);
  const response = await fetch(url, {
    method: "PUT",
    body: JSON.stringify(data ?? {}),
    headers,
  });
  return handleResponse(response, async () => {
    return await put({ url, data, header });
  });
};

const uploadFile = async ({ url, data }: Props) => {
  const header = "formMultipart";
  const headers = await handleHeader(header);
  const formData = new FormData();
  for (const name in data) {
    formData.append(name, data[name]);
  }
  const response = await fetch(url, {
    method: "POST",
    body: formData,
    headers,
  });
  return handleResponse(response, async () => {
    return await uploadFile({ url, data });
  });
};

const get = async ({ url, header = "withToken" }: Props) => {
  const headers = await handleHeader(header);
  const response = await fetch(url, {
    method: "GET",
    headers,
  });
  return handleResponse(response, async () => {
    return await get({ url, header });
  });
};

const deleteData = async ({ url, header = "withToken" }: Props) => {
  const headers = await handleHeader(header);
  const response = await fetch(url, {
    method: "DELETE",
    headers,
  });
  return handleResponse(response, async () => {
    return await deleteData({ url, header });
  });
};

export const BaseApi = {
  post,
  put,
  getRefreshToken,
  uploadFile,
  get,
  deleteData,
};

export interface ParamList {
  offset?: number;
  limit?: number;
  keyword?: string;
  level?: number;
  areaId?: string;
  areaType?: AreaType;
  id?: string;
  approved?: number;
  parentId?: string;
  purchase_date?: string;
  pic_id?: string;
  warranty?: string;
  manufacture_id?: string;
  supplier_id?: string;
  area_type?: string;
}
