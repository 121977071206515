import { TableCell, TableRow } from "../shadcn/components/ui/table";

interface Props {
  colSpan: number;
}

export default function EmptyRowTable({ colSpan }: Props) {
  return (
    <TableRow>
      <TableCell colSpan={colSpan} className="text-center">
        Empty Data
      </TableCell>
    </TableRow>
  );
}
