import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../shadcn/components/ui/select";

export interface OptionSelect {
  value: string;
  label: string;
}

interface Props {
  label?: string;
  value: string;
  setValue: any;
  disabled?: boolean;
  withAll?: boolean;
  dataList: OptionSelect[];
}

export default function SelectInput({
  label,
  value,
  setValue,
  disabled,
  withAll = false,
  dataList,
}: Props) {
  return (
    <div className="flex flex-col">
      {label && <div className="mb-1">{label}</div>}
      <Select value={value} onValueChange={setValue} disabled={disabled}>
        <SelectTrigger className="w-full bg-white !text-input">
          <SelectValue placeholder={`Select ${label}`} />
        </SelectTrigger>
        <SelectContent className="max-h-60">
          {withAll && (
            <SelectGroup>
              <SelectItem value={"ALL"} className="!text-input">
                All
              </SelectItem>
            </SelectGroup>
          )}

          {dataList.map((data: OptionSelect, index: number) => (
            <SelectGroup key={index + 1}>
              <SelectItem
                value={data.value}
                className="!text-input"
                key={index + 1}
              >
                {data.label}
              </SelectItem>
            </SelectGroup>
          ))}
        </SelectContent>
      </Select>
    </div>
  );
}
