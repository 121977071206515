import { ApiValue } from "shared/values/apiValue";
import { BaseApi, ParamList } from "./baseApi";

const edit = async (id: string, data: any) => {
  return await BaseApi.put({
    url: ApiValue.notificationEdit.replace("{notification_id}", id),
    data,
  });
};

const getListByUser = async ({ limit, offset }: ParamList) => {
  let url = `${ApiValue.userGetNotification}?limit=${limit}&offset=${offset}`;
  return await BaseApi.get({ url });
};

const getCountByUser = async () => {
  let url = `${ApiValue.userGetNotificationTotal}`;
  return await BaseApi.get({ url });
};

export const NotificationApi = { edit, getListByUser, getCountByUser };
