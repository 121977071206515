import { useAuth } from "app/hooks/authHook";
import { useProfile } from "app/hooks/profileHook";
import FormProfileAvatarUser from "features/user/FormProfileAvatarUser";
import FormProfileInfoUser from "features/user/FormProfileInfoUser";
import FormProfilePasswordUser from "features/user/FormProfilePasswordUser";
import { LockIcon, LogOutIcon, User2Icon, UserCircle2Icon } from "lucide-react";
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "shared/ui/shadcn/components/ui/avatar";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "shared/ui/shadcn/components/ui/dropdown-menu";
import { Skeleton } from "shared/ui/shadcn/components/ui/skeleton";
import { StringUtil } from "shared/utils/stringUtil";

export default function MenuProfileUser() {
  const { profile, setProfile } = useProfile();
  const { setSigned, setProfileId } = useAuth();

  const signOut = () => {
    localStorage.clear();
    setProfile({});
    setProfileId("");
    setSigned(false);
  };

  if (profile === null) {
    return (
      <div className="flex gap-2 items-center">
        <Skeleton className="border-2 border-primary w-8 h-8 aspect-square rounded-full"></Skeleton>
        <Skeleton className="font-semibold hidden xl:block w-28 h-6"></Skeleton>
      </div>
    );
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <div className="cursor-pointer flex gap-2 items-center">
          <Avatar className="border-2 border-primary w-8 h-8 aspect-square">
            <AvatarImage src={profile?.avatar} />
            <AvatarFallback>Avatar</AvatarFallback>
          </Avatar>
          <div className="font-semibold hidden xl:block">
            {profile?.display_name}
          </div>
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <DropdownMenuLabel className="flex gap-2">
          <Avatar className="cursor-pointer border-2 border-primary">
            <AvatarImage src={profile?.avatar} />
            <AvatarFallback>Avatar</AvatarFallback>
          </Avatar>
          <div className="flex flex-col">
            <label>{profile?.display_name}</label>
            <span className="text-gray-400 text-sm">
              {StringUtil.toTitleCase(profile?.role?.name!)}
            </span>
          </div>
        </DropdownMenuLabel>
        <DropdownMenuSeparator />
        <FormProfileInfoUser>
          <div className="flex items-center w-full cursor-pointer rounded-sm px-2 py-1.5 text-sm outline-none transition-colors hover:bg-accent hover:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50">
            <User2Icon className="mr-2 h-4 w-4" />
            <span className="">Profile Edit</span>
          </div>
        </FormProfileInfoUser>
        <FormProfileAvatarUser>
          <div className="flex items-center w-full cursor-pointer rounded-sm px-2 py-1.5 text-sm outline-none transition-colors hover:bg-accent hover:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50">
            <UserCircle2Icon className="mr-2 h-4 w-4 " />
            <span className="">Change Avatar</span>
          </div>
        </FormProfileAvatarUser>
        <FormProfilePasswordUser>
          <div className="flex items-center w-full cursor-pointer rounded-sm px-2 py-1.5 text-sm outline-none transition-colors hover:bg-accent hover:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50">
            <LockIcon className="mr-2 h-4 w-4 " />
            <span className="">Change Password</span>
          </div>
        </FormProfilePasswordUser>
        <DropdownMenuItem
          className="group"
          onClick={() => {
            signOut();
          }}
        >
          <LogOutIcon className="mr-2 h-4 w-4 stroke-red-500" />
          <span className="text-red-500">Sign Out</span>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
