/* eslint-disable react-hooks/exhaustive-deps */
import { useAssetFilter } from "app/hooks/assetFilterHook";
import CounterAreaAsset from "features/asset/CounterAreaAsset";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import DetailButton from "shared/ui/button/DetailButton";
import CardTitleActionSmallGeneral from "shared/ui/card/CardTitleActionSmallGeneral";
import CardTitleSmallGeneral from "shared/ui/card/CardTitleSmallGeneral";
import { AnimationValue } from "shared/values/animationValue";
import { AssetValue } from "shared/values/assetValue";
import { RouteValue } from "shared/values/routeValue";

export default function CounterAssetOffice() {
  const { assetFilter } = useAssetFilter();
  const navigate = useNavigate();

  return (
    <motion.div
      variants={AnimationValue.list.item}
      className="
      w-full !bg-white rounded-lg border border-gray-100 shadow-lg 
      flex flex-col
      h-[calc(((100vh-3.125rem-(4*theme('gap.2'))-5rem)*50/100)-theme('gap.1'))]"
    >
      <CardTitleActionSmallGeneral
        icon={AssetValue.laptopImage}
        title="Total Asset Area"
        action={
          <div
            onClick={() => {
              navigate(
                `${RouteValue.manufacturePerformanceDetailDashboard.replace(
                  ":type",
                  "asset-total"
                )}?area_type=OFFICE`
              );
            }}
          >
            <DetailButton />
          </div>
        }
      />
      <div className="flex-grow rounded-lg bg-blue-50 mx-2 mb-2 p-1 justify-center items-center flex">
        <CounterAreaAsset
          areaType="OFFICE"
          className="xl:h-[calc((((100vh-3.125rem-(4*theme('gap.2'))-5rem)*50/100)-theme('gap.1'))-theme('height.6')-theme('margin.2')*4)]"
          positionLegend={"bottom"}
          areaId={assetFilter.area_level_id}
        />
      </div>
    </motion.div>
  );
}
