interface Props {
  data: any;
  isBlur?: boolean;
  onClick?: any;
}

export default function ItemEnergy({ data, isBlur = true, onClick }: Props) {
  return (
    <div
      className={`h-full ${
        data.count > 0 ? "bg-blue-50" : "bg-gray-100 text-gray-400"
      } flex gap-2 items-center p-2 rounded-lg w-full relative`}
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
    >
      {isBlur && (
        <div className="backdrop-blur-sm bg-white/30 absolute top-0 left-0 w-full h-full z-50 rounded-lg"></div>
      )}
      <div
        className={`h-full ${
          data.count > 0 ? "bg-blue-50" : "bg-gray-100 text-gray-400"
        } flex gap-2 items-center p-2 rounded-lg w-full`}
      >
        <div
          className={`
              h-auto w-1/5 xl:w-1/2 aspect-square 
              !bg-no-repeat !bg-center !bg-contain
              ${
                data.count > 0 ? "border-primary" : "grayscale border-gray-400"
              }`}
          style={{ backgroundImage: `url("${data.icon}")` }}
        ></div>
        <div className="flex flex-col w-1/2">
          <div className="font-semibold text-gray-400 text-sm min-[1440px]:text-lg">
            {data.label}
          </div>
          <div className="font-semibold text-xs">Today</div>
          <div className="font-semibold min-[1440px]:text-lg">{`${data.count} ${data.unit}`}</div>
        </div>
      </div>
    </div>
  );
}
