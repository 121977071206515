import { zodResolver } from "@hookform/resolvers/zod";
import { UserApi } from "entities/api/userApi";
import { Role } from "entities/model/roleModel";
import { User } from "entities/model/userModel";
import SelectRole from "features/role/SelectRole";
import { SaveIcon, UserIcon } from "lucide-react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import DefaultButton from "shared/ui/button/DefaultButton";
import TextInput from "shared/ui/input/TextInput";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "shared/ui/shadcn/components/ui/dialog";
import { Form } from "shared/ui/shadcn/components/ui/form";
import { useToast } from "shared/ui/shadcn/components/ui/use-toast";
import ErrorText from "shared/ui/text/ErrorText";
import { UserValidationValue } from "shared/values/validations/userValidationValue";
import { z } from "zod";

interface Props {
  children: JSX.Element;
  onFormSubmit?: any;
  data?: User | null;
}

export default function FormUser({
  children,
  onFormSubmit,
  data = null,
}: Props) {
  const [open, setOpen] = useState<boolean>(false);
  const form = useForm<z.infer<typeof UserValidationValue.add>>({
    resolver: zodResolver(UserValidationValue.add),
    defaultValues: {
      display_name: data ? data.display_name : "",
      username: data ? data.username : "",
      role_id: data ? data.role_id : "",
    },
  });
  const [loading, setLoading] = useState<boolean>(false);
  const { toast } = useToast();

  const onSubmit = async (values: z.infer<typeof UserValidationValue.add>) => {
    try {
      setLoading(true);
      const dataInput = {
        display_name: values.display_name,
        username: values.username,
        role_id: values.role_id,
      };
      const response = data
        ? await UserApi.edit({ id: data.id!, ...dataInput })
        : await UserApi.save(dataInput);
      if (!response.success) {
        const dataError = response.error;
        form.setError(dataError.path, { message: dataError.msg });
        return;
      }
      if (onFormSubmit) {
        onFormSubmit({ ...data, ...dataInput });
      }
      form.clearErrors();
      if (data === null) {
        form.setValue("username", "");
        form.setValue("display_name", "");
        form.setValue("role_id", "");
      }
      toast({
        title: `Success ${data === null ? "add" : "edit"} user`,
        description: data === null ? "Success created!" : "Success edited!",
        className: "bg-emerald-500 !text-white",
      });
      setOpen(false);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger
        asChild
        onClick={() => {
          setOpen(true);
        }}
      >
        {children}
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>{data ? "Edit User" : "Add User"}</DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <div className="mb-2">
              <TextInput
                label="Username"
                placeholder="Enter username"
                value={form.getValues("username")}
                prefixIcon={<UserIcon className="w-4 h-4" />}
                setValue={(value: string) => {
                  form.setValue("username", value, { shouldValidate: true });
                }}
              />
              <ErrorText text={form.formState.errors.username?.message!} />
            </div>
            <div className="mb-2">
              <TextInput
                label="Display Name"
                placeholder="Enter display name"
                value={form.getValues("display_name")}
                prefixIcon={<UserIcon className="w-4 h-4" />}
                setValue={(value: string) => {
                  form.setValue("display_name", value, {
                    shouldValidate: true,
                  });
                }}
              />
              <ErrorText text={form.formState.errors.display_name?.message!} />
            </div>
            <div className="mb-2">
              <SelectRole
                label="Role"
                value={{ id: form.getValues("role_id") }}
                setValue={(value: Role) => {
                  form.setValue("role_id", value.id!, { shouldValidate: true });
                }}
                withAll={false}
              />
              <ErrorText text={form.formState.errors.role_id?.message!} />
            </div>
            <DefaultButton
              label="Save changes"
              icon={<SaveIcon className="stroke-white w-6 h-6" />}
              loading={loading}
              type="submit"
              className="mt-8"
            />
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
