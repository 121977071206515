/* eslint-disable react-hooks/exhaustive-deps */
import { AssetApi } from "entities/api/assetApi";
import { AreaModel } from "entities/model/areaModel";
import { AssetModel } from "entities/model/assetModel";
import { useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import DefaultLoading from "shared/ui/loading/DefaultLoading";
import { RouteValue } from "shared/values/routeValue";
import AssetDetailManufacture from "widgets/manufacture/AssetDetailManufacture";
import AssetImageManufacture from "widgets/manufacture/AssetImageManufacture";
import AssetPositionManufacture from "widgets/manufacture/AssetPositionManufacture";
import EnergyManufacture from "widgets/manufacture/EnergyManufacture";

export default function AssetManufacturingPage() {
  return (
    <div
      className="
    xl:col-span-3 
    grid grid-cols-1 xl:grid-cols-2 gap-2
    xl:h-[calc(100vh-3.125rem-(4*theme('gap.2'))-5rem)]"
    >
      <div className="xl:h-[calc(100vh-3.125rem-(4*theme('gap.2'))-5rem)] flex flex-col gap-2">
        <AssetDetailManufacture />
        <AssetImageManufacture />
      </div>
      <div className="xl:h-[calc(100vh-3.125rem-(4*theme('gap.2'))-5rem)] flex flex-col gap-2">
        <AssetPositionManufacture />
        <EnergyManufacture />
        <div className="h-4 xl:hidden"></div>
      </div>
    </div>
  );
}
