import { SettingValue } from "shared/values/settingValue";

const getAvatarDefault = (name: string) =>
  SettingValue.avatarDefault.replace("{name}", name);

const toTitleCase = (value: string) => {
  if (value) {
    return value
      .toLowerCase()
      .replace("_", " ")
      .split(" ")
      .map((l) => l[0].toUpperCase() + l.substring(1))
      .join(" ");
  }
  return "";
};

export const StringUtil = { getAvatarDefault, toTitleCase };
