import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../shadcn/components/ui/accordion";

interface Props {
  children?: JSX.Element | string;
  title: string;
}

export default function AccordionCard({ children, title }: Props) {
  return (
    <div className="mb-2 mx-2 rounded-lg bg-blue-50 p-1">
      <Accordion type="single" collapsible className="w-full">
        <AccordionItem value="item-1" className="!border-b-0">
          <AccordionTrigger className="px-2">{title}</AccordionTrigger>
          <AccordionContent className="px-2">{children}</AccordionContent>
        </AccordionItem>
      </Accordion>
    </div>
  );
}
