export interface RoleMenu {
  id?: string;
  menu?: string;
  role_id?: string;
}

const fromJson = (json: any): RoleMenu => {
  let result: RoleMenu = {
    id: json["id"],
    menu: json["menu"],
    role_id: json["role_id"],
  };
  return result;
};

const toJson = (value: RoleMenu): string => {
  return JSON.stringify(value);
};

export const RoleMenuModel = { fromJson, toJson };
