/* eslint-disable react-hooks/exhaustive-deps */
import { format } from "date-fns";
import OEEList from "features/oee/OEEList";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import AccordionCard from "shared/ui/card/AccordionCard";
import CardTitleGeneral from "shared/ui/card/CardTitleGeneral";
import { DateInput } from "shared/ui/input/DateInput";
import { ScrollArea } from "shared/ui/shadcn/components/ui/scroll-area";
import { AnimationValue } from "shared/values/animationValue";
import { AssetValue } from "shared/values/assetValue";

export default function OEEManufacture() {
  const [date, setDate] = useState<Date>(new Date());
  const [searchParams, setSearchParams] = useSearchParams();

  const handleParams = () => {
    if (searchParams.get("date")) {
      setDate(new Date(searchParams.get("date")!));
    }
  };

  useEffect(() => {
    handleParams();
  }, []);

  return (
    <motion.div
      variants={AnimationValue.list.item}
      className="
        w-full !bg-white rounded-lg border border-gray-100 shadow-lg 
        flex flex-col gap-2
        h-[calc(100vh-3.125rem-(4*theme('gap.2'))-5rem)]"
    >
      <CardTitleGeneral icon={AssetValue.performanceImage} title="OEE" />
      <AccordionCard title="Filter">
        <DateInput
          label="Date"
          date={date}
          setDate={(value: Date) => {
            setDate(value);
            setSearchParams({ date: format(value, "Y-MM-dd") });
          }}
        />
      </AccordionCard>
      <ScrollArea>
        <OEEList />
      </ScrollArea>
      <div className="h-2"></div>
    </motion.div>
  );
}
