import BookValueManufacture from "widgets/manufacture/BookValueManufacture";
import EnergyManufacture from "widgets/manufacture/EnergyManufacture";
import LayoutManufacture from "widgets/manufacture/LayoutManufacture";
import PICManufacture from "widgets/manufacture/PICManufacture";

export default function AreaManufacturingPage() {
  return (
    <div className="xl:col-span-3 flex flex-col gap-2 xl:h-[calc(100vh-3.125rem-(4*theme('gap.2'))-5rem)]">
      <div className="grid grid-cols-1 xl:grid-cols-3 xl:gap-2 xl:h-[calc((100vh-3.125rem-(5*theme('gap.2'))-5rem)*75/100)]">
        <BookValueManufacture />
        <div className="h-4 xl:hidden"></div>
        <LayoutManufacture />
      </div>
      <div className="grid grid-cols-1 xl:grid-cols-2 gap-2 xl:h-[calc((100vh-3.125rem-(5*theme('gap.2'))-5rem)*25/100)]">
        <PICManufacture />
        <EnergyManufacture />
        <div className="h-4 xl:hidden"></div>
      </div>
    </div>
  );
}
