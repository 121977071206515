/* eslint-disable react-hooks/exhaustive-deps */
import { RoleApi } from "entities/api/roleApi";
import { Role, RoleModel } from "entities/model/roleModel";
import { useEffect, useState } from "react";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "shared/ui/shadcn/components/ui/select";
import { Skeleton } from "shared/ui/shadcn/components/ui/skeleton";

interface Props {
  label: string;
  value: Role;
  setValue: any;
  disabled?: boolean;
  withAll?: boolean;
}

export default function SelectRole({
  label,
  value,
  setValue,
  disabled,
  withAll = true,
}: Props) {
  const [dataList, setDataList] = useState<Role[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const fetchArea = async () => {
    try {
      setLoading(true);
      const response = await RoleApi.getList({
        limit: 0,
        offset: 0,
      });
      if (response.success) {
        const responseList: Role[] = [];
        for (const key in response.dataList) {
          const item = response.dataList[key];
          responseList.push(RoleModel.fromJson(item));
        }
        setDataList(responseList);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchArea();
  }, []);

  return (
    <div className="flex flex-col">
      <div className="mb-1">{label}</div>
      {loading ? (
        <Skeleton className="w-full h-10" />
      ) : (
        <Select
          value={value.id}
          onValueChange={(e: string) => {
            if (e === "ALL") {
              setValue({ id: "" });
            } else {
              const item = dataList.filter((data: Role) => data.id === e);
              setValue(item[0]);
            }
          }}
          disabled={disabled}
        >
          <SelectTrigger className="w-full bg-white !text-input">
            <SelectValue placeholder={`Select ${label}`} />
          </SelectTrigger>
          <SelectContent className="max-h-60">
            {withAll && (
              <SelectGroup>
                <SelectItem value={"ALL"} className="!text-input">
                  All
                </SelectItem>
              </SelectGroup>
            )}

            {dataList.map((data: Role, index: number) => (
              <SelectGroup key={index + 1}>
                <SelectItem
                  value={data.id!}
                  className="!text-input"
                  key={index + 1}
                >
                  {data.name!}
                </SelectItem>
              </SelectGroup>
            ))}
          </SelectContent>
        </Select>
      )}
    </div>
  );
}
