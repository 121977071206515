import { AssetApi } from "entities/api/assetApi";
import { Asset } from "entities/model/assetModel";
import { Trash2Icon } from "lucide-react";
import { useState } from "react";
import DefaultButton from "shared/ui/button/DefaultButton";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "shared/ui/shadcn/components/ui/dialog";
import { useToast } from "shared/ui/shadcn/components/ui/use-toast";

interface Props {
  children: JSX.Element;
  callback?: any | null;
  asset: Asset;
  id: string;
}

export default function DeleteAssetImage({
  children,
  callback = null,
  asset,
  id,
}: Props) {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState<boolean>(false);
  const { toast } = useToast();

  const deleteData = async () => {
    try {
      setLoading(true);
      const response = await AssetApi.removeImage(asset.id!, id);
      if (response.success) {
        setOpen(false);
      }
      toast({
        title: `Success delete image`,
        description: "Success deleted!",
        className: "bg-green-500 !text-white",
      });
      if (callback) {
        callback();
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger
        asChild
        onClick={() => {
          setOpen(true);
        }}
      >
        {children}
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Are you sure delete this image?</DialogTitle>
        </DialogHeader>
        <div>Are you sure delete image ?</div>
        <div className="flex gap-2 justify-end">
          <div>
            <DefaultButton
              label="Cancel"
              type="button"
              variant={"outline"}
              onClick={() => {
                setOpen(false);
              }}
            />
          </div>
          <div>
            <DefaultButton
              label="Delete"
              icon={<Trash2Icon className="stroke-white w-6 h-6" />}
              loading={loading}
              type="button"
              variant={"destructive"}
              onClick={async () => {
                await deleteData();
              }}
            />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
