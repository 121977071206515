interface Props {
  icon: string;
  title: string;
  action: any;
}

export default function CardTitleActionGeneral({ icon, title, action }: Props) {
  return (
    <div className="flex gap-2 items-center justify-between m-2">
      <div className="flex flex-grow gap-2 items-center justify-start">
        <img src={icon} alt={title} className={`w-auto h-8`} />
        <label className={`font-semibold`}>{title}</label>
      </div>
      {action}
    </div>
  );
}
