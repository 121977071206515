import { z } from "zod";

const add = z.object({
  name: z.string().min(1, { message: "Name is required!" }),
  asset_no: z.string().min(1, { message: "No asset is required!" }),
  machine_no: z.string(),
  warranty: z.string().min(1, { message: "Warranty is required!" }),
  price: z.number().min(1, { message: "Price is required!" }),
  purchase_date: z.string().min(1, { message: "Purchase date is required!" }),
  is_parent: z.number(),
  asset_id: z.string(),
  manufacture_id: z.string().min(1, { message: "Manufacture is required!" }),
  supplier_id: z.string().min(1, { message: "Supplier is required!" }),
  pic_id: z.string().min(1, { message: "PIC is required!" }),
  area_type: z.string(),
  area_level_id: z.string(),
});

const importExcel = z.object({
  file: z.string().min(1, { message: "File is required!" }),
});

const uploadImage = z.object({
  image: z.string().min(1, { message: "Image is required!" }),
});

const addOverhaul = z.object({
  overhaul_amount: z.number().min(1, { message: "Amount is required!" }),
  overhaul_date: z.string().min(1, { message: "Date is required!" }),
});

export const AssetValidationValue = {
  add,
  importExcel,
  uploadImage,
  addOverhaul,
};
