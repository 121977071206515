/* eslint-disable react-hooks/exhaustive-deps */
import { AssetApi } from "entities/api/assetApi";
import { Asset, AssetModel } from "entities/model/assetModel";
import DetailAsset from "features/asset/DetailAsset";
import TableAsset from "features/asset/TableAsset";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CardTitleGeneral from "shared/ui/card/CardTitleGeneral";
import DefaultLoading from "shared/ui/loading/DefaultLoading";
import { AnimationValue } from "shared/values/animationValue";
import { AssetValue } from "shared/values/assetValue";

export default function ListChildAssetPage() {
  const [asset, setAsset] = useState<Asset>({});
  const [loading, setLoading] = useState<boolean>(true);
  const { asset_id } = useParams();

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await AssetApi.get(asset_id!);
      setAsset(AssetModel.fromJson(response.data));
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [asset_id]);

  return (
    <>
      {loading ? (
        <DefaultLoading />
      ) : (
        <div
          className="
          pt-[calc(3.125rem+theme('padding.2'))] md:pt-0
          px-2 md:px-0
          md:h-[calc(100vh-3.125rem-(2*theme('gap.2')))]
      "
        >
          <motion.div
            variants={AnimationValue.list.container}
            initial="hidden"
            animate="visible"
            className="w-full !bg-white rounded-lg border border-gray-100 shadow-lg p-2 h-full flex flex-col"
          >
            <CardTitleGeneral
              title="Asset Child List"
              icon={
                asset.area_type === "MANUFACTURE"
                  ? AssetValue.assetImage
                  : AssetValue.laptopImage
              }
            />
            <div className="bg-blue-100 p-2 rounded-lg mb-2">
              <DetailAsset
                asset_id={asset.id!}
                className="grid grid-cols-2 md:grid-cols-6 p-2 gap-2 gap-y-4"
              />
            </div>
            <TableAsset
              area={asset.area!}
              areaType={asset.area_type!}
              asset_id={asset.id!}
            />
          </motion.div>
        </div>
      )}
    </>
  );
}
