/* eslint-disable react-hooks/exhaustive-deps */
import { useAssetFilter } from "app/hooks/assetFilterHook";
import { AssetApi } from "entities/api/assetApi";
import { AreaType } from "entities/model/areaModel";
import { useEffect, useState } from "react";
import { AssetValue } from "shared/values/assetValue";
import CounterDashboard from "widgets/dashboard/CounterDashboard";

interface Props {
  areaType: AreaType;
}

export default function CounterAsset({ areaType }: Props) {
  const { assetFilter } = useAssetFilter();
  const [counter, setCounter] = useState<number>(0);

  const getCounter = async () => {
    try {
      let response: any = null;
      response = await AssetApi.getTotal({
        areaType,
        areaId: assetFilter.area_level_id,
      });
      if (response.success) {
        setCounter(response.data.counter);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getCounter();
  }, [assetFilter.area_level_id, assetFilter.area_type]);

  const handleIcon = () => {
    switch (areaType) {
      case "MANUFACTURE":
        return AssetValue.assetImage;
      case "OFFICE":
        return AssetValue.laptopImage;
      default:
        return AssetValue.assetImage;
    }
  };

  return (
    <CounterDashboard
      counter={counter}
      icon={handleIcon()}
      label="Asset Total"
    />
  );
}
