import { ApiValue } from "shared/values/apiValue";
import { BaseApi, ParamList } from "./baseApi";

const save = async (name: string) => {
  return await BaseApi.post({ url: ApiValue.supplierSave, data: { name } });
};

const edit = async (id: string, name: string) => {
  return await BaseApi.put({
    url: ApiValue.supplierEdit.replace("{supplier_id}", id),
    data: { name },
  });
};

const remove = async (id: string) => {
  return await BaseApi.deleteData({
    url: ApiValue.supplierDelete.replace("{supplier_id}", id),
  });
};

const getList = async ({ limit, offset, keyword = "" }: ParamList) => {
  let url = `${ApiValue.supplierGetList}?limit=${limit}&offset=${offset}`;
  if (keyword) url = `${url}&keyword=${keyword}`;
  return await BaseApi.get({ url });
};

const getTotal = async () => {
  return await BaseApi.get({
    url: ApiValue.supplierGetTotal,
  });
};

export const SupplierApi = { save, edit, remove, getList, getTotal };
