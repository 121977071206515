import { useAuth } from "app/hooks/authHook";
import FormSignInUser from "features/user/FormSignInUser";
import { Navigate } from "react-router-dom";
import { AssetValue } from "shared/values/assetValue";
import { RouteValue } from "shared/values/routeValue";

export default function SignInUserPage() {
  const { signed } = useAuth();
  if (signed) return <Navigate to={RouteValue.manufactureDashboard} />;

  return (
    <div className="h-screen w-screen flex justify-center items-center">
      <div
        className="hidden md:block flex-grow !bg-center !bg-cover !bg-no-repeat h-screen"
        style={{ background: `url("${AssetValue.bgSignIn1}")` }}
      ></div>
      <div className="w-full md:w-max bg-white p-6 md:p-24">
        {/* Right Info Content */}
        <img src={AssetValue.logotypeImage} alt="logo" className="h-6 w-auto" />
        <div className="text-6xl font-semibold">AMNOSTRA</div>
        <div className="text-gray-500 text-2xl mt-2">Welcome AMNOSTRA</div>
        <div className="text-gray-500">Manage your asset with AMNOSTRA</div>
        <div className="mt-8 mb-4 text-gray-500">
          Please enter your username and password
        </div>
        <FormSignInUser />
      </div>
    </div>
  );
}
