/* eslint-disable react-hooks/exhaustive-deps */
import { Asset } from "entities/model/assetModel";
import { motion } from "framer-motion";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "shared/ui/shadcn/components/ui/accordion";
import { ScrollArea } from "shared/ui/shadcn/components/ui/scroll-area";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "shared/ui/shadcn/components/ui/tooltip";
import { AnimationValue } from "shared/values/animationValue";
import { RouteValue } from "shared/values/routeValue";
import ListAsset from "./ListAsset";
import { useState } from "react";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "shared/ui/shadcn/components/ui/collapsible";
import { PlusIcon } from "lucide-react";

interface Props {
  data: Asset;
  withChild?: boolean;
}

export default function ItemManufactureAsset({
  data,
  withChild = true,
}: Props) {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [searchParams] = useSearchParams();
  const { area_id } = useParams();

  return (
    <Collapsible open={isOpen} onOpenChange={setIsOpen}>
      <motion.li
        className={`flex justify-between items-center gap-2 mx-2 rounded-lg p-2 my-2 cursor-pointer ${
          searchParams.get("asset_id") === data.id ? "bg-primary" : "bg-blue-50"
        }`}
        variants={AnimationValue.list.item}
        onClick={() => {
          navigate(
            `${RouteValue.manufactureAssetDashboard.replace(
              ":area_id",
              area_id!
            )}?asset_id=${data.id}`
          );
        }}
      >
        {data.image!.length > 0 && (
          <div
            className="!bg-cover !bg-no-repeat w-12 h-12 2xl:w-16 2xl:h-16 rounded-lg"
            style={{ background: `url("${data.image![0].image}")` }}
          ></div>
        )}
        <div className="flex-grow flex flex-col justify-start items-start">
          <div
            className={`font-semibold text-sm ${
              searchParams.get("asset_id") === data.id
                ? "text-white"
                : "text-gray-400"
            } `}
          >
            No: {data.asset_no}
          </div>
          <div
            className={`font-semibold ${
              searchParams.get("asset_id") === data.id && "text-white"
            }`}
          >
            {data.name}
          </div>
          <div
            className={`font-semibold text-sm ${
              searchParams.get("asset_id") === data.id
                ? "text-white"
                : "text-gray-400"
            }`}
          >
            PIC: {data.pic?.name}
          </div>
        </div>
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <div className={`w-6 h-6 rounded-full bg-green-400`}></div>
            </TooltipTrigger>
            <TooltipContent side="left" className={`bg-green-400`}>
              <p>Running</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
        {/* {data.counter?.assets! > 0 && (
          <CollapsibleTrigger asChild>
            <div className="p-1 cursor-pointer bg-green-400 rounded-full">
              <PlusIcon className="w-4 h-4" />
            </div>
          </CollapsibleTrigger>
        )} */}
      </motion.li>
      <CollapsibleContent className="px-2">
        <ScrollArea className="max-h-56 py-2">
          {/* <ListAsset areaType="MANUFACTURE" assetId={data.id} /> */}
        </ScrollArea>
      </CollapsibleContent>
    </Collapsible>
  );
}
