export interface Supplier {
  id?: string;
  name?: string;
}

const fromJson = (json: any): Supplier => {
  let result: Supplier = {
    id: json["id"],
    name: json["name"],
  };

  return result;
};

const toJson = (value: Supplier): string => {
  return JSON.stringify(value);
};

export const SupplierModel = { fromJson, toJson };
