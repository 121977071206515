/* eslint-disable react-hooks/exhaustive-deps */
import { Area, AreaType } from "entities/model/areaModel";
import SelectArea from "features/area/SelectArea";
import TableAreaPIC from "features/pic/TableAreaPIC";
import { FilterIcon } from "lucide-react";
import { useEffect, useState } from "react";
import DefaultButton from "shared/ui/button/DefaultButton";

interface Props {
  areaType: AreaType;
}

export default function TableFilterAreaPIC({ areaType }: Props) {
  const [areaLevel1, setAreaLevel1] = useState<Area>({ id: "" });
  const [areaLevel2, setAreaLevel2] = useState<Area>({ id: "" });
  const [areaLevel3, setAreaLevel3] = useState<Area>({ id: "" });
  const [area, setArea] = useState<Area>({ id: "" });

  const resetAllArealLevel = () => {
    setArea({ id: "" });
    setAreaLevel1({ id: "" });
    setAreaLevel2({ id: "" });
    setAreaLevel3({ id: "" });
  };

  useEffect(() => {
    resetAllArealLevel();
  }, [areaType]);

  return (
    <>
      <div className="bg-blue-100 p-2 rounded-lg">
        <div className="font-semibold mb-2">Area Filter</div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-2">
          <SelectArea
            areaType={areaType}
            label={areaType === "MANUFACTURE" ? "Area" : "Building"}
            level={1}
            value={areaLevel1}
            setValue={(value: Area) => {
              setAreaLevel1(value);
              setAreaLevel2({ id: "" });
              setAreaLevel3({ id: "" });
            }}
          />
          <SelectArea
            areaType={areaType}
            label={areaType === "MANUFACTURE" ? "Sub Area" : "Floor"}
            level={2}
            value={areaLevel2}
            setValue={(value: Area) => {
              setAreaLevel2(value);
              setAreaLevel3({ id: "" });
            }}
            areaId={areaLevel1.area_id}
            disabled={areaLevel1.id === ""}
            parentId={areaLevel1.id}
          />
          <SelectArea
            areaType={areaType}
            label={areaType === "MANUFACTURE" ? "Line" : "Room"}
            level={3}
            value={areaLevel3}
            setValue={setAreaLevel3}
            areaId={areaLevel2.area_id}
            disabled={areaLevel2.id === ""}
            parentId={areaLevel2.id}
          />
        </div>
        <div className="mt-2 flex justify-end">
          <DefaultButton
            label="Filter"
            icon={<FilterIcon className="w-4 h-4 stroke-white" />}
            disabled={areaLevel1.id === ""}
            onClick={() => {
              if (areaLevel3.id !== "") {
                setArea(areaLevel3);
              } else if (areaLevel2.id !== "") {
                setArea(areaLevel2);
              } else if (areaLevel1.id !== "") {
                setArea(areaLevel1);
              } else {
                setArea({ id: "" });
              }
            }}
            className="w-max"
          />
        </div>
      </div>
      {area.id !== "" && (
        <div className="mt-2">
          <TableAreaPIC area={area} />
        </div>
      )}
    </>
  );
}
