/* eslint-disable react-hooks/exhaustive-deps */
import { useAuth } from "app/hooks/authHook";
import { getProfile, useProfile } from "app/hooks/profileHook";
import { useEffect, useState } from "react";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import PageLoading from "shared/ui/loading/PageLoading";
import { RouteValue } from "shared/values/routeValue";
import HeaderDashboard from "widgets/dashboard/HeaderDashboard";

export default function DashboardLayout() {
  const { setProfile } = useProfile();
  const { signed, setSigned, setProfileId } = useAuth();
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();

  const handleProfile = async () => {
    try {
      setLoading(true);
      const user = await getProfile();
      if (user === null) {
        localStorage.clear();
        setProfile({});
        setProfileId("");
        setSigned(false);
        return navigate(RouteValue.userSignIn);
        // return <Navigate to={RouteValue.userSignIn} />;
      }
      setProfile(user);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (signed) {
      handleProfile();
    }
  }, []);

  if (!signed) return <Navigate to={RouteValue.userSignIn} />;

  return loading ? (
    <PageLoading />
  ) : (
    <div
      className="
        relative flex flex-col gap-2
        xl:h-screen xl:max-h-screen
        xl:p-2
        before:fixed before:top-0 before:left-0
        before:h-1/2 before:w-full
        before:bg-primary
        before:-z-10
        after:fixed after:top-0 after:left-0
        after:h-full after:w-full
        after:bg-blue-50
        after:-z-20
        "
    >
      <HeaderDashboard />
      <Outlet />
    </div>
  );
}
