export default function DefaultLoading() {
  return (
    <div className="flex items-center justify-center p-2">
      <l-reuleaux
        size="24"
        stroke="5"
        stroke-length="0.15"
        bg-opacity="0.1"
        speed="1.2"
        color="#005cad"
      ></l-reuleaux>
    </div>
  );
}
