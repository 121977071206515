import { zodResolver } from "@hookform/resolvers/zod";
import { FileApi } from "entities/api/fileApi";
import { PICApi } from "entities/api/picApi";
import { PIC } from "entities/model/picModel";
import { SaveIcon } from "lucide-react";
import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import DefaultButton from "shared/ui/button/DefaultButton";
import TextInput from "shared/ui/input/TextInput";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "shared/ui/shadcn/components/ui/dialog";
import { Form } from "shared/ui/shadcn/components/ui/form";
import { useToast } from "shared/ui/shadcn/components/ui/use-toast";
import ErrorText from "shared/ui/text/ErrorText";
import { StringUtil } from "shared/utils/stringUtil";
import { PICValidationValue } from "shared/values/validations/picValidationValue";
import { z } from "zod";

interface Props {
  children: JSX.Element;
  onFormSubmit?: any;
  data?: PIC | null;
}

export default function FormPIC({
  children,
  onFormSubmit,
  data = null,
}: Props) {
  const [open, setOpen] = useState<boolean>(false);
  const form = useForm<z.infer<typeof PICValidationValue.add>>({
    resolver: zodResolver(PICValidationValue.add),
    defaultValues: {
      name: data ? data.name : "",
      nik: data ? data.nik : "",
      avatar: data ? data.avatar : "",
    },
  });
  const [loading, setLoading] = useState<boolean>(false);
  const { toast } = useToast();
  const inputFileRef: any = useRef();
  const [file, setFile] = useState<File | null>(null);

  const onSubmit = async (values: z.infer<typeof PICValidationValue.add>) => {
    try {
      setLoading(true);
      let avatar = "";
      if (form.getValues("avatar") !== "") {
        const reponseUpload = await FileApi.uploadPICAvatar(file);
        if (!reponseUpload.success) {
          const dataError = reponseUpload.data.error;
          form.setError(dataError.path, { message: dataError.msg });
          return;
        }
        avatar = reponseUpload.data.image;
      }

      const response = data
        ? await PICApi.edit({
            id: data.id!,
            name: values.name,
            nik: values.nik,
            avatar: avatar,
          })
        : await PICApi.save({
            name: values.name,
            nik: values.nik,
            avatar: avatar,
          });
      if (!response.success) {
        const dataError = response.error;
        form.setError(dataError.path, { message: dataError.msg });
        return;
      }
      if (onFormSubmit) {
        onFormSubmit({
          ...data,
          name: values.name,
          avatar: avatar ?? data?.avatar,
          nik: values.nik,
        });
      }
      form.clearErrors();
      if (data === null) {
        form.reset({ name: "", nik: "", avatar: "" });
      }
      toast({
        title: `Success ${data === null ? "add" : "edit"} pic`,
        description: data === null ? "Success created!" : "Success edited!",
        className: "bg-emerald-500 !text-white",
      });
      setOpen(false);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const onFileChangeCapture = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files != null && e.target.files!.length > 0) {
      setFile(e.target.files[0]);
      form.setValue("avatar", URL.createObjectURL(e.target.files[0]), {
        shouldValidate: true,
      });
    }
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger
        asChild
        onClick={() => {
          setOpen(true);
        }}
      >
        {children}
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>{data ? "Edit PIC" : "Add PIC"}</DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <div className="flex gap-4 items-center mb-2">
              <img
                src={`${
                  form.getValues("avatar") !== ""
                    ? form.getValues("avatar")
                    : StringUtil.getAvatarDefault(form.getValues("name") ?? "")
                }`}
                alt="Avatar"
                className="rounded-full w-14 h-14 bg-gray-100 border-2 border-primary"
              />
              <div className="flex-grow flex flex-col gap-4">
                <input
                  type="file"
                  ref={inputFileRef}
                  onChangeCapture={onFileChangeCapture}
                  className="hidden"
                  accept="image/jpg,image/jpeg,image/png,image/webp"
                />
                <div
                  className="text-primary font-bold cursor-pointer"
                  onClick={() => {
                    inputFileRef.current.click();
                  }}
                >
                  Select Picture
                </div>
              </div>
            </div>
            <div className="mb-2">
              <TextInput
                label="Name"
                placeholder="Enter name pic"
                value={form.getValues("name")}
                setValue={(value: string) => {
                  form.setValue("name", value, { shouldValidate: true });
                }}
              />
              <ErrorText text={form.formState.errors.name?.message!} />
            </div>
            <div className="mb-10">
              <TextInput
                label="NIK"
                placeholder="Enter nik pic"
                value={form.getValues("nik")}
                setValue={(value: string) => {
                  form.setValue("nik", value, { shouldValidate: true });
                }}
              />
            </div>
            <DefaultButton
              label="Save changes"
              icon={<SaveIcon className="stroke-white w-6 h-6" />}
              loading={loading}
              type="submit"
            />
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
