import { zodResolver } from "@hookform/resolvers/zod";
import { PICApi } from "entities/api/picApi";
import { PIC } from "entities/model/picModel";
import { SaveIcon } from "lucide-react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import DefaultButton from "shared/ui/button/DefaultButton";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "shared/ui/shadcn/components/ui/dialog";
import { Form } from "shared/ui/shadcn/components/ui/form";
import { useToast } from "shared/ui/shadcn/components/ui/use-toast";
import ErrorText from "shared/ui/text/ErrorText";
import { PICValidationValue } from "shared/values/validations/picValidationValue";
import { z } from "zod";
import SelectPIC from "./SelectPIC";
import { Area } from "entities/model/areaModel";

interface Props {
  children: JSX.Element;
  onFormSubmit?: any;
  area: Area;
}

export default function FormAreaPIC({ children, onFormSubmit, area }: Props) {
  const [open, setOpen] = useState<boolean>(false);
  const form = useForm<z.infer<typeof PICValidationValue.addArea>>({
    resolver: zodResolver(PICValidationValue.addArea),
    defaultValues: {
      pic_id: "",
      area_level_id: area.id,
    },
  });
  const [loading, setLoading] = useState<boolean>(false);
  const { toast } = useToast();

  const onSubmit = async (
    values: z.infer<typeof PICValidationValue.addArea>
  ) => {
    try {
      setLoading(true);

      const response = await PICApi.saveArea({
        pic_id: values.pic_id,
        area_level_id: values.area_level_id,
      });
      if (!response.success) {
        const dataError = response.error;
        form.setError(dataError.path, { message: dataError.msg });
        return;
      }
      if (onFormSubmit) {
        onFormSubmit();
      }
      form.clearErrors();
      form.setValue("pic_id", "");
      toast({
        title: `Success add pic of area`,
        description: "Success created!",
        className: "bg-emerald-500 !text-white",
      });
      setOpen(false);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger
        asChild
        onClick={() => {
          setOpen(true);
        }}
      >
        {children}
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>{`Add PIC of Area ${area.name}`}</DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <div className="mb-10">
              <SelectPIC
                label="PIC"
                value={{ id: form.getValues("pic_id") }}
                setValue={(value: PIC) => {
                  form.setValue("pic_id", value.id!, { shouldValidate: true });
                }}
                withAll={false}
              />
              <ErrorText text={form.formState.errors.pic_id?.message!} />
            </div>
            <DefaultButton
              label="Save changes"
              icon={<SaveIcon className="stroke-white w-6 h-6" />}
              loading={loading}
              type="submit"
            />
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
