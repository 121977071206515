/* eslint-disable react-hooks/exhaustive-deps */
import { AreaApi } from "entities/api/areaApi";
import { AreaType } from "entities/model/areaModel";
import { useEffect, useState } from "react";
import PieChart from "shared/ui/chart/PieChart";
import DefaultLoading from "shared/ui/loading/DefaultLoading";

interface Props {
  className: string;
  areaType: AreaType;
  areaId?: string;
  positionLegend?: any;
}

export default function CounterAreaAsset({
  className,
  areaType,
  areaId = "",
  positionLegend = "right",
}: Props) {
  const [loading, setLoading] = useState(true);
  const [series, setSeries]: any = useState([]);
  const [label, setLabel]: any = useState([]);

  const fetchData = async () => {
    try {
      let area_id = "";
      const responseAsset = await AreaApi.get(areaId);
      area_id = responseAsset.data["area_id"];
      const response = await AreaApi.getAssetTotal({
        offset: 0,
        limit: 0,
        areaType,
        parentId: areaId,
        areaId: area_id,
      });
      if (response.success) {
        const seriesData = [
          ...response.dataList.map((item: any) => item.counter),
        ];
        const labelData = [...response.dataList.map((item: any) => item.name)];
        setSeries(seriesData);
        setLabel(labelData);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setSeries([]);
    setLabel([]);
    setLoading(true);
    fetchData();
  }, [areaId]);

  return (
    <div className={`${className}`}>
      {loading ? (
        <DefaultLoading />
      ) : (
        <PieChart
          label={label}
          series={series}
          positionLegend={positionLegend}
        />
      )}
    </div>
  );
}
