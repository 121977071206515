// user
const userSignIn = "";

// manufactureDashboard
const manufactureDashboard = "/manufacture";
const manufactureAreaDashboard = "/manufacture/:area_id";
const manufactureAssetDashboard = "/manufacture/:area_id/asset";
const manufacturePerformanceDetailDashboard =
  "/manufacture/performance/:type/detail";

// officeDashboard
const officeDashboard = "/office";

// asset
const assetList = "/asset";
const assetChildList = "/asset/:asset_id/child";
const assetOverhaulList = "/asset/:asset_id/overhaul";

// pic
const picMasterList = "/pic/master";
const picAreaList = "/pic/area";

// data master
const masterManufactureList = "/master/manufacture";
const masterSupplierList = "/master/supplier";

// user
const userList = "/user";
const userAreaList = "/user/:user_id/area";
const roleList = "/role";
const roleMenuList = "/role/:role_id/menu";

// notification
const notificationList = "/notification";

export const RouteValue = {
  userSignIn,
  manufactureDashboard,
  manufactureAreaDashboard,
  manufactureAssetDashboard,
  manufacturePerformanceDetailDashboard,
  officeDashboard,
  assetList,
  assetChildList,
  assetOverhaulList,
  picMasterList,
  picAreaList,
  masterManufactureList,
  masterSupplierList,
  userList,
  userAreaList,
  roleList,
  roleMenuList,
  notificationList,
};
