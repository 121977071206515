/* eslint-disable react-hooks/exhaustive-deps */
import { AreaType } from "entities/model/areaModel";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import CardTitleGeneral from "shared/ui/card/CardTitleGeneral";
import { AnimationValue } from "shared/values/animationValue";
import { AssetValue } from "shared/values/assetValue";
import TabTypeArea from "widgets/area/TabTypeArea";
import TableFilterAreaAsset from "widgets/asset/TableFilterAreaAsset";

export default function ListAssetPage() {
  const [areaType, setAreaType] = useState<AreaType>("MANUFACTURE");
  const [searchParams] = useSearchParams();

  const handleParam = () => {
    setAreaType(
      searchParams.get("areaType") === "MANUFACTURE" ? "MANUFACTURE" : "OFFICE"
    );
  };

  useEffect(() => {
    if (searchParams.get("areaType")) {
      handleParam();
    }
  }, [searchParams]);

  return (
    <>
      <div
        className="pt-[calc(3.125rem+theme('padding.2'))] md:pt-0
        px-2 md:px-0"
      >
        <TabTypeArea select={areaType} onSelect={setAreaType} />
      </div>
      <div
        className="
        px-2 md:px-0
        md:h-[calc(100vh-3.125rem-(2*theme('gap.2')))]
      "
      >
        <motion.div
          variants={AnimationValue.list.container}
          initial="hidden"
          animate="visible"
          className="w-full !bg-white rounded-lg border border-gray-100 shadow-lg p-2 h-full flex flex-col"
        >
          <CardTitleGeneral
            title="Asset List"
            icon={
              areaType === "MANUFACTURE"
                ? AssetValue.assetImage
                : AssetValue.laptopImage
            }
          />
          <TableFilterAreaAsset areaType={areaType} />
        </motion.div>
      </div>
    </>
  );
}
