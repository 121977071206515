import { useForm } from "react-hook-form";
import { UserValidationValue } from "shared/values/validations/userValidationValue";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Form } from "shared/ui/shadcn/components/ui/form";
import TextInput from "shared/ui/input/TextInput";
import ErrorText from "shared/ui/text/ErrorText";
import { LogInIcon, UserIcon } from "lucide-react";
import PasswordInput from "shared/ui/input/PasswordInput";
import DefaultButton from "shared/ui/button/DefaultButton";
import { useState } from "react";
import { UserApi } from "entities/api/userApi";
import { SettingValue } from "shared/values/settingValue";
import { decodeToken } from "react-jwt";
import { useAuth } from "app/hooks/authHook";
import { useNavigate } from "react-router-dom";
import { RouteValue } from "shared/values/routeValue";

export default function FormSignInUser() {
  const form = useForm<z.infer<typeof UserValidationValue.signIn>>({
    resolver: zodResolver(UserValidationValue.signIn),
    defaultValues: {
      username: "",
      password: "",
    },
  });
  const [loading, setLoading] = useState<boolean>(false);
  const { setProfileId, setSigned } = useAuth();
  const navigate = useNavigate();

  const onSubmit = async (
    values: z.infer<typeof UserValidationValue.signIn>
  ) => {
    try {
      setLoading(true);
      const response = await UserApi.signIn(values.username, values.password);
      if (!response.success) {
        const dataError = response.error;
        form.setError(dataError.path, { message: dataError.msg });
        return;
      }
      localStorage.setItem(
        SettingValue.keyLocalStorageToken,
        response.data.token
      );
      const decode: any = decodeToken(
        localStorage.getItem(SettingValue.keyLocalStorageToken)!
      );
      setProfileId(decode.data);
      setSigned(true);
      navigate(RouteValue.manufactureDashboard);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <div className="mb-2">
          <TextInput
            label="Username"
            placeholder="Enter your username"
            prefixIcon={<UserIcon className="w-4 h-4 stroke-gray-500" />}
            value={form.getValues("username")}
            setValue={(value: string) => {
              form.setValue("username", value, { shouldValidate: true });
            }}
          />
          <ErrorText text={form.formState.errors.username?.message!} />
        </div>
        <div className="mb-10">
          <PasswordInput
            label="Password"
            placeholder="Enter your password"
            prefixIcon={true}
            suffixIcon={true}
            value={form.getValues("password")}
            setValue={(value: string) => {
              form.setValue("password", value, { shouldValidate: true });
            }}
          />
          <ErrorText text={form.formState.errors.password?.message!} />
        </div>
        <DefaultButton
          label="Sign In"
          icon={<LogInIcon className="stroke-white w-6 h-6" />}
          loading={loading}
          type="submit"
        />
      </form>
    </Form>
  );
}
