import { QrCodeIcon } from "lucide-react";
import { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "shared/ui/shadcn/components/ui/dialog";
import { QrScanner } from "@yudiel/react-qr-scanner";

export default function QRCodeScanAsset() {
  const [open, setOpen] = useState<boolean>(false);

  const onScan = async (data: any) => {};

  const onError = (err: any) => {
    console.log(err);
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <QrCodeIcon className="w-6 h-6 aspect-square stroke-gray-500 cursor-pointer" />
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Scan QR Code</DialogTitle>
          <DialogDescription>Please scan qr code asset</DialogDescription>
        </DialogHeader>
        <QrScanner onDecode={onScan} onError={onError} />
      </DialogContent>
    </Dialog>
  );
}
