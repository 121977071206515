/* eslint-disable react-hooks/exhaustive-deps */
import { zodResolver } from "@hookform/resolvers/zod";
import { AreaApi } from "entities/api/areaApi";
import { FileApi } from "entities/api/fileApi";
import { Area, AreaModel, AreaType } from "entities/model/areaModel";
import { FileBarChart2Icon, SaveIcon, UploadCloudIcon } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import DefaultButton from "shared/ui/button/DefaultButton";
import DefaultLoading from "shared/ui/loading/DefaultLoading";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "shared/ui/shadcn/components/ui/dialog";
import { Form } from "shared/ui/shadcn/components/ui/form";
import { useToast } from "shared/ui/shadcn/components/ui/use-toast";
import ErrorText from "shared/ui/text/ErrorText";
import { AssetValidationValue } from "shared/values/validations/assetValidationValue";
import { z } from "zod";

interface Props {
  children: JSX.Element;
  area: Area;
  areaType: AreaType;
  asset_id?: string;
  onFormSubmit: any;
}

export default function FormImportAsset({
  children,
  area,
  areaType,
  asset_id = "",
  onFormSubmit,
}: Props) {
  const [open, setOpen] = useState<boolean>(false);
  const form = useForm<z.infer<typeof AssetValidationValue.importExcel>>({
    resolver: zodResolver(AssetValidationValue.importExcel),
    defaultValues: {
      file: "",
    },
  });
  const [loading, setLoading] = useState(false);
  const [loadingArea, setLoadingArea] = useState(true);
  const inputFileRef: any = useRef();
  const [file, setFile] = useState<File | null>(null);
  const [areaLevel1, setAreaLevel1] = useState<Area>({});
  const [areaLevel2, setAreaLevel2] = useState<Area>({});
  const [areaLevel3, setAreaLevel3] = useState<Area>({});
  const { toast } = useToast();

  const fetchDataArea = async () => {
    try {
      setLoadingArea(true);
      const response = await AreaApi.getHirarki(area.id!);
      setAreaLevel1(AreaModel.fromJson(response.data.areaLevel1));
      setAreaLevel2(AreaModel.fromJson(response.data.areaLevel2));
      setAreaLevel3(AreaModel.fromJson(response.data.areaLevel3));
    } catch (error) {
    } finally {
      setLoadingArea(false);
    }
  };

  const onSubmit = async (
    values: z.infer<typeof AssetValidationValue.importExcel>
  ) => {
    try {
      setLoading(true);
      const reponseUpload = await FileApi.importExcelAsset(
        file,
        area.id!,
        areaType,
        asset_id ? 0 : 1,
        asset_id
      );
      if (!reponseUpload.success) {
        const dataError = reponseUpload.error;
        form.setError(dataError.path, { message: dataError.msg });
        return;
      }
      form.clearErrors();
      setFile(null);
      toast({
        title: `Success import asset`,
        description: "Success import asset",
        className: "bg-emerald-500 !text-white",
      });
      onFormSubmit();
      setOpen(false);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const onFileChangeCapture = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files != null && e.target.files!.length > 0) {
      setFile(e.target.files[0]);
      form.setValue("file", URL.createObjectURL(e.target.files[0]), {
        shouldValidate: true,
      });
    }
  };

  useEffect(() => {
    fetchDataArea();
  }, [area.id]);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger
        asChild
        onClick={() => {
          setOpen(true);
        }}
      >
        {children}
      </DialogTrigger>
      <DialogContent className="sm:max-w-[525px]">
        <DialogHeader>
          <DialogTitle>Import Excel</DialogTitle>
        </DialogHeader>
        {loadingArea ? (
          <DefaultLoading />
        ) : (
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
              <div className="bg-amber-500 p-2 rounded-lg grid grid-cols-3 mb-4">
                <div>
                  <div className="font-semibold">
                    {areaType === "MANUFACTURE" ? "Area" : "Building"}
                  </div>
                  <div>{areaLevel1.name}</div>
                </div>
                {areaLevel2 && (
                  <div>
                    <div className="font-semibold">
                      {areaType === "MANUFACTURE" ? "Sub Area" : "Floor"}
                    </div>
                    <div>{areaLevel2.name}</div>
                  </div>
                )}
                {areaLevel3 && (
                  <div>
                    <div className="font-semibold">
                      {areaType === "MANUFACTURE" ? "Line" : "Room"}
                    </div>
                    <div>{areaLevel3.name}</div>
                  </div>
                )}
              </div>
              <input
                type="file"
                ref={inputFileRef}
                onChangeCapture={onFileChangeCapture}
                className="hidden"
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              />
              <div
                className="bg-blue-100 rounded-lg w-full h-auto aspect-video p-2 flex items-center justify-center cursor-pointer"
                onClick={() => {
                  inputFileRef.current.click();
                }}
              >
                <div className="flex flex-col gap-2 items-center justify-center">
                  {file ? (
                    <>
                      <FileBarChart2Icon className="w-8 h-8" />
                      <div className="font-semibold">{file.name}</div>
                    </>
                  ) : (
                    <>
                      <UploadCloudIcon className="w-8 h-8" />
                      <div className="font-semibold">Choose file</div>
                    </>
                  )}
                </div>
              </div>
              <ErrorText text={form.formState.errors.file?.message!} />
              {file !== null && (
                <DefaultButton
                  label="Import Excel"
                  icon={<SaveIcon className="stroke-white w-6 h-6" />}
                  loading={loading}
                  type="submit"
                  className="mt-8"
                />
              )}
            </form>
          </Form>
        )}
      </DialogContent>
    </Dialog>
  );
}
