import DetailAsset from "features/asset/DetailAsset";
import { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "shared/ui/shadcn/components/ui/dialog";

interface Props {
  asset_id: string;
  children: JSX.Element;
}

export default function DialogDetailAsset({ asset_id, children }: Props) {
  const [open, setOpen] = useState<boolean>(false);
  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger
        asChild
        onClick={() => {
          setOpen(true);
        }}
      >
        {children}
      </DialogTrigger>
      <DialogContent className="w-dialog-full">
        <DialogHeader>
          <DialogTitle>Detail Asset</DialogTitle>
        </DialogHeader>
        <div className="bg-blue-100 p-2">
          <DetailAsset asset_id={asset_id} />
        </div>
      </DialogContent>
    </Dialog>
  );
}
