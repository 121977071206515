import FormUploadImageAsset from "features/asset/FormUploadImageAsset";
import ImageAsset from "features/asset/ImageAsset";
import { motion } from "framer-motion";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import UploadButton from "shared/ui/button/UploadButton";
import CardTitleActionGeneral from "shared/ui/card/CardTitleActionGeneral";
import DefaultDialog from "shared/ui/dialog/DefaultDialog";
import { AnimationValue } from "shared/values/animationValue";
import { AssetValue } from "shared/values/assetValue";

export default function AssetImageManufacture() {
  const [searchParams] = useSearchParams();
  const [refresh, setRefresh] = useState<boolean>(false);

  return (
    <motion.div
      variants={AnimationValue.list.item}
      className="
        w-full !bg-white rounded-lg border border-gray-100 shadow-lg 
        flex flex-col
        h-[calc((100vh-3.125rem-(5*theme('gap.2'))-5rem)*50/100)]
        col-span-2"
    >
      <CardTitleActionGeneral
        icon={AssetValue.assetImage}
        title="Asset Image"
        action={
          searchParams.get("asset_id") ? (
            <DefaultDialog button={<UploadButton />} title={`Update Image`}>
              <FormUploadImageAsset
                asset_id={searchParams.get("asset_id")!}
                onFormSubmit={() => {
                  setRefresh(!refresh);
                }}
              />
            </DefaultDialog>
          ) : (
            <></>
          )
        }
      />
      <div
        className="bg-blue-50 rounded-lg mx-2 flex items-center justify-center p-2 
        h-[calc(((100vh-3.125rem-(5*theme('gap.2'))-5rem)*50/100)-theme('height.8')-theme('margin.4'))]
        "
      >
        <ImageAsset
          className="
          h-full w-full 
          overflow-hidden overflow-x-scroll scroll-smooth scrollbar-custom
          flex
          gap-2"
          asset_id={searchParams.get("asset_id")!}
          refresh={refresh}
        />
      </div>
      <div className="h-2"></div>
    </motion.div>
  );
}
