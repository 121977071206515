import { Area, AreaModel } from "./areaModel";
import { PIC, PICModel } from "./picModel";

export interface PICArea {
  id?: string;
  pic_id?: string;
  area_level_id?: string;
  area?: Area;
  pic?: PIC;
}

const fromJson = (json: any): PICArea => {
  let result: PICArea = {
    id: json["id"],
    pic_id: json["pic_id"],
    area_level_id: json["area_level_id"],
  };
  if (json["area_level"]) result.area = AreaModel.fromJson(json["area_level"]);
  if (json["pic"]) result.pic = PICModel.fromJson(json["pic"]);

  return result;
};

const toJson = (value: PICArea): string => {
  return JSON.stringify(value);
};

export const PICAreaModel = { fromJson, toJson };
