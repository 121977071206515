import { AssetFilter } from "entities/model/assetFilterModel";
import { create } from "zustand";

export const initialAssetFilter: AssetFilter = {
  area_level1: "",
  area_level2: "",
  area_level3: "",
  area_level_id: "",
  keyword: "",
  manufacture_id: "",
  pic_id: "",
  purchase_date: "",
  supplier_id: "",
  warranty: "",
  area_type: "MANUFACTURE",
};

type State = {
  assetFilter: AssetFilter;
};

type Actions = {
  setAssetFilter: (value: AssetFilter) => void;
};

export const useAssetFilter = create<State & Actions>((set) => ({
  assetFilter: initialAssetFilter,
  setAssetFilter: (value: AssetFilter) =>
    set((state) => ({ assetFilter: value })),
}));
