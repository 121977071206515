import FilterAsset from "features/asset/FilterAsset";
import ListAsset from "features/asset/ListAsset";
import { motion } from "framer-motion";
import CardTitleGeneral from "shared/ui/card/CardTitleGeneral";
import { ScrollArea } from "shared/ui/shadcn/components/ui/scroll-area";
import { AnimationValue } from "shared/values/animationValue";
import { AssetValue } from "shared/values/assetValue";

export default function AssetListOffice() {
  return (
    <motion.div
      variants={AnimationValue.list.item}
      className="
      w-full !bg-white rounded-lg border border-gray-100 shadow-lg 
      flex flex-col gap-2
      h-[calc(100vh-3.125rem-(4*theme('gap.2'))-5rem)]"
    >
      <CardTitleGeneral icon={AssetValue.laptopImage} title="Asset List" />
      <FilterAsset areaType="OFFICE" />
      <ScrollArea>
        <ListAsset areaType="OFFICE" />
      </ScrollArea>
      <div className="h-2"></div>
    </motion.div>
  );
}
