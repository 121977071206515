import { z } from "zod";

const signIn = z.object({
  username: z.string().min(1, { message: "Username is required!" }),
  password: z.string().min(1, { message: "Password is required!" }),
});
const editInfo = z.object({
  username: z.string().min(1, { message: "Username is required!" }),
  display_name: z.string().min(1, { message: "Display name is required!" }),
});
const editAvatar = z.object({
  avatar: z.string().min(1, { message: "Avatar is required!" }),
});
const editPassword = z
  .object({
    old_password: z
      .string()
      .min(1, { message: "Current password is required!" }),
    new_password: z.string().min(1, { message: "New password is required!" }),
    confirm_password: z
      .string()
      .min(1, { message: "Confirm password is required!" }),
  })
  .refine((data) => data.new_password === data.confirm_password, {
    message: "Passwords don't match",
    path: ["confirm_password"],
  });
const add = z.object({
  username: z.string().min(1, { message: "Username is required!" }),
  display_name: z.string().min(1, { message: "Display name is required!" }),
  role_id: z.string().min(1, { message: "Role is required!" }),
});
const addArea = z.object({
  area_level_id: z.string().min(1, { message: "Area is required!" }),
});

export const UserValidationValue = {
  signIn,
  editInfo,
  editAvatar,
  editPassword,
  add,
  addArea,
};
