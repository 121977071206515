import { NotificationApi } from "entities/api/notificationApi";
import { BellIcon } from "lucide-react";
import { useEffect, useState } from "react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "shared/ui/shadcn/components/ui/dropdown-menu";
import { ScrollArea } from "shared/ui/shadcn/components/ui/scroll-area";
import ListUserNotification from "./ListUserNotification";
import { useNavigate } from "react-router-dom";
import { RouteValue } from "shared/values/routeValue";

export default function UserNotification() {
  const [total, setTotal] = useState<number>(0);
  const [open, setOpen] = useState<boolean>(false);
  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      const response = await NotificationApi.getCountByUser();
      if (response.success) {
        setTotal(response.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <DropdownMenu open={open} onOpenChange={setOpen}>
      <DropdownMenuTrigger asChild>
        <div className="relative cursor-pointer">
          <BellIcon className="w-6 h-6 aspect-square stroke-gray-500" />
          {total > 0 && (
            <div className="bg-rose-500 w-3 h-auto aspect-square rounded-full absolute top-0 right-0"></div>
          )}
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="!w-56 md:!w-96">
        <DropdownMenuLabel>Notification</DropdownMenuLabel>
        <DropdownMenuSeparator />
        <ScrollArea className="max-h-56 flex flex-col">
          <div className="flex flex-col gap-4 px-1">
            <ListUserNotification isRefresh={true} />
          </div>
        </ScrollArea>
        <DropdownMenuSeparator />
        <div
          className="px-2 py-1.5 text-sm text-center cursor-pointer text-blue-500"
          onClick={() => {
            navigate(RouteValue.notificationList);
          }}
        >
          Show All Notification
        </div>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
