import { ApiValue } from "shared/values/apiValue";
import { BaseApi } from "./baseApi";
import { AreaType } from "entities/model/areaModel";

const uploadUserAvatar = async (userId: string, image: any) => {
  const url = ApiValue.fileUploadUserAvatar.replace("{user_id}", userId);
  return await BaseApi.uploadFile({
    url,
    data: { image },
  });
};

const uploadPICAvatar = async (image: any) => {
  const url = ApiValue.fileUploadPICAvatar;
  return await BaseApi.uploadFile({
    url,
    data: { image },
  });
};

const importExcelAsset = async (
  file: any,
  area_level_id: string,
  areaType: AreaType,
  is_parent: number,
  parent_id?: string
) => {
  const url = ApiValue.fileImportAsset;
  let data = {};
  data = { file, area_level_id, areaType, is_parent };
  if (parent_id) data = { ...data, parent_id };
  return await BaseApi.uploadFile({
    url,
    data,
  });
};

const uploadAssetImage = async (asset_id: string, image: any) => {
  const url = ApiValue.fileUploadAssetImage.replace("{asset_id}", asset_id);
  return await BaseApi.uploadFile({
    url,
    data: { image },
  });
};

export const FileApi = {
  uploadUserAvatar,
  uploadPICAvatar,
  importExcelAsset,
  uploadAssetImage,
};
