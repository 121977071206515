/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "shared/ui/shadcn/components/ui/table";
import EmptyRowTable from "shared/ui/table/EmptyRowTable";
import { SettingValue } from "shared/values/settingValue";
import { PaginationModel, Paginations } from "entities/model/paginationModel";
import PaginationTable from "shared/ui/table/PaginationTable";
import LoadingRowTable from "shared/ui/table/LoadingRowTable";
import {
  Notification,
  NotificationModel,
} from "entities/model/notificationModel";
import { NotificationApi } from "entities/api/notificationApi";
import { Button } from "shared/ui/shadcn/components/ui/button";
import { EyeIcon } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { RouteValue } from "shared/values/routeValue";

export default function TableUserNotification() {
  const limit = SettingValue.limitNotification;
  const [pagination, setPagination] = useState<Paginations>(
    PaginationModel.handleInitial(limit)
  );
  const [dataList, setDataList] = useState<Notification[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const navigation = useNavigate();

  const fetchDataList = async () => {
    try {
      setLoading(true);
      let offset = 0;
      offset = pagination.page === 1 ? 0 : pagination.page! * limit - limit;
      const response = await NotificationApi.getListByUser({
        offset,
        limit,
      });
      let responseDataList: Notification[] = [];
      if (response.success) {
        for (let index = 0; index < response.dataList.length; index++) {
          const item = response.dataList[index];
          responseDataList.push(NotificationModel.fromJson(item));
        }
        setPagination(response.pagination);
      }
      setDataList(responseDataList);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleOpen = async (id: string, index: number) => {
    try {
      const response = await NotificationApi.edit(id, { opened: 1 });
      if (response.success) {
        dataList[index].opened = true;
        const newData = [...dataList];
        setDataList(newData);
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchDataList();
  }, [pagination.page]);

  return (
    <>
      <div className="rounded-md border">
        <Table>
          <TableHeader className="bg-primary">
            <TableRow>
              <TableHead className="w-[5%] text-white text-center">
                No
              </TableHead>
              <TableHead className="text-white">Sender</TableHead>
              <TableHead className="text-white">Notification</TableHead>
              <TableHead className="w-[10%] text-white text-center">
                Action
              </TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {loading ? (
              <LoadingRowTable colSpan={4} />
            ) : dataList.length < 1 ? (
              <EmptyRowTable colSpan={4} />
            ) : (
              dataList.map((item: Notification, index: number) => (
                <TableRow key={index}>
                  <TableCell className="text-center">
                    {(pagination.page === 1
                      ? 1
                      : pagination.page! * limit - limit + 1) + index}
                  </TableCell>
                  <TableCell>
                    <div className="flex gap-4">
                      <img
                        src={item.sender?.avatar}
                        alt="AVATAR"
                        className="w-12 h-12"
                      />
                      <div>
                        <div className="font-semibold">
                          {item.sender?.display_name}
                        </div>
                        <div>{item.sender?.username}</div>
                      </div>
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className="font-semibold">{item.title}</div>
                    <div className="text-sm">{item.body}</div>
                  </TableCell>
                  <TableCell className="text-center">
                    <Button
                      className="bg-cyan-500"
                      onClick={() => {
                        if (!item.opened) handleOpen(item.id!, index);
                        let url = item.notif_data?.asset?.is_parent
                          ? RouteValue.assetList
                          : RouteValue.assetChildList.replace(
                              ":asset_id",
                              item.notif_data?.asset?.id!
                            );
                        url = `${url}?asset_id=${item.notif_data?.asset?.id}&areaType=${item.notif_data?.asset?.area_type}`;
                        navigation(url);
                      }}
                    >
                      <EyeIcon className="w-4 h-4 stroke-white" />
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </div>
      <PaginationTable pagination={pagination} setPagination={setPagination} />
    </>
  );
}
