import { AssetApi } from "entities/api/assetApi";
import { Asset } from "entities/model/assetModel";
import { CheckIcon, XIcon } from "lucide-react";
import { useState } from "react";
import DefaultButton from "shared/ui/button/DefaultButton";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "shared/ui/shadcn/components/ui/dialog";
import { useToast } from "shared/ui/shadcn/components/ui/use-toast";

interface Props {
  children: JSX.Element;
  onDelete?: any;
  data: Asset;
  approved: boolean;
}

export default function ApproveAsset({
  children,
  onDelete,
  data,
  approved,
}: Props) {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState<boolean>(false);
  const { toast } = useToast();

  const deleteData = async () => {
    try {
      setLoading(true);
      const response = await AssetApi.edit(data.id!, {
        approved: approved ? 1 : 0,
      });

      if (!response.success) return;

      toast({
        title: `Success ${approved ? "approved" : "disapproved"} asset`,
        description: `Success ${approved ? "Approved" : "Disapproved"}!`,
        className: "bg-emerald-500 !text-white",
      });
      if (onDelete) {
        onDelete();
      }
      setOpen(false);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger
        asChild
        onClick={() => {
          setOpen(true);
        }}
      >
        {children}
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            Are you sure {approved ? "Approve" : "Disapprove"} this asset?
          </DialogTitle>
        </DialogHeader>
        <div>
          Are you sure {approved ? "Approve" : "Disapprove"} data asset{" "}
          {data.name} ?
        </div>
        <div className="flex gap-2 justify-end">
          <div>
            <DefaultButton
              label="Cancel"
              type="button"
              variant={"outline"}
              onClick={() => {
                setOpen(false);
              }}
            />
          </div>
          <div>
            <DefaultButton
              label={approved ? "Approve" : "Disapprove"}
              icon={
                approved ? (
                  <CheckIcon className="stroke-white w-6 h-6" />
                ) : (
                  <XIcon className="stroke-white w-6 h-6" />
                )
              }
              loading={loading}
              type="button"
              variant={approved ? "default" : "destructive"}
              onClick={async () => {
                await deleteData();
              }}
            />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
