/* eslint-disable react-hooks/exhaustive-deps */
import {
  EditIcon,
  LayoutIcon,
  PlusCircleIcon,
  SearchIcon,
  Trash2Icon,
} from "lucide-react";
import { useEffect, useState } from "react";
import DefaultButton from "shared/ui/button/DefaultButton";
import TextInput from "shared/ui/input/TextInput";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "shared/ui/shadcn/components/ui/table";
import EmptyRowTable from "shared/ui/table/EmptyRowTable";
import { SettingValue } from "shared/values/settingValue";
import { PaginationModel, Paginations } from "entities/model/paginationModel";
import PaginationTable from "shared/ui/table/PaginationTable";
import { Button } from "shared/ui/shadcn/components/ui/button";
import LoadingRowTable from "shared/ui/table/LoadingRowTable";
import { Role, RoleModel } from "entities/model/roleModel";
import { RoleApi } from "entities/api/roleApi";
import FormRole from "./FormRole";
import DeleteRole from "./DeleteRole";
import { useToast } from "shared/ui/shadcn/components/ui/use-toast";
import { Switch } from "shared/ui/shadcn/components/ui/switch";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "shared/ui/shadcn/components/ui/tooltip";
import { useNavigate } from "react-router-dom";
import { RouteValue } from "shared/values/routeValue";

export default function TableRole() {
  const limit = SettingValue.limitRole;
  const [keyword, setKeyword] = useState<string>("");
  const [pagination, setPagination] = useState<Paginations>(
    PaginationModel.handleInitial(limit)
  );
  const [dataList, setDataList] = useState<Role[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const { toast } = useToast();
  const navigate = useNavigate();

  const fetchDataList = async () => {
    try {
      setLoading(true);
      let offset = 0;
      if (keyword) {
        offset = 0;
      } else {
        offset = pagination.page === 1 ? 0 : pagination.page! * limit - limit;
      }
      const response = await RoleApi.getList({
        offset,
        limit,
        keyword,
      });
      let responseDataList: Role[] = [];
      if (response.success) {
        for (let index = 0; index < response.dataList.length; index++) {
          const item = response.dataList[index];
          responseDataList.push(RoleModel.fromJson(item));
        }
        setPagination(response.pagination);
      }
      setDataList(responseDataList);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const onEdit = (index: number, value: Role) => {
    setLoading(true);
    dataList[index].name = value.name;
    setDataList([...dataList]);
    setLoading(false);
  };

  const fetchEdit = async (id: string, data: any) => {
    try {
      const response = await RoleApi.edit(id, data);
      toast({
        title: `${response.success ? "Success" : "Failed"} edit role`,
        description: `${response.success ? "Success" : "Failed"} edited!`,
        className: `${
          response.success ? "bg-emerald-500" : "bg-rose-500"
        } !text-white`,
      });
    } catch (error) {}
  };

  useEffect(() => {
    fetchDataList();
  }, [keyword, pagination.page]);

  return (
    <>
      <div className="flex flex-col-reverse md:flex-row justify-between mb-2 p-2 bg-blue-100 rounded-lg gap-2">
        <div className="w-full md:w-1/3">
          <TextInput
            placeholder="Search by name"
            prefixIcon={<SearchIcon className="w-4 h-4 stroke-gray-500" />}
            value={keyword}
            setValue={setKeyword}
          />
        </div>
        <div>
          <FormRole
            onFormSubmit={() => {
              fetchDataList();
            }}
          >
            <DefaultButton
              label="Add Data"
              icon={<PlusCircleIcon className="stroke-white w-6 h-6" />}
            />
          </FormRole>
        </div>
      </div>
      <div className="rounded-md border">
        <Table>
          <TableHeader className="bg-primary">
            <TableRow>
              <TableHead className="w-[5%] text-white text-center">
                No
              </TableHead>
              <TableHead className="text-white">Name</TableHead>
              <TableHead className="text-white">Can Add</TableHead>
              <TableHead className="text-white">Can Edit</TableHead>
              <TableHead className="text-white">Can Delete</TableHead>
              <TableHead className="text-white">Can Approved</TableHead>
              <TableHead className="w-[10%] text-white text-center">
                Action
              </TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {loading ? (
              <LoadingRowTable colSpan={7} />
            ) : dataList.length < 1 ? (
              <EmptyRowTable colSpan={7} />
            ) : (
              dataList.map((item: Role, index: number) => (
                <TableRow key={index}>
                  <TableCell className="text-center">
                    {(pagination.page === 1
                      ? 1
                      : pagination.page! * limit - limit + 1) + index}
                  </TableCell>
                  <TableCell>{item.name}</TableCell>
                  <TableCell>
                    <div className="flex items-center">
                      <Switch
                        id="can_add"
                        checked={item.can_add}
                        onCheckedChange={(checked: boolean) => {
                          dataList[index].can_add = checked;
                          setDataList([...dataList]);
                          fetchEdit(item.id!, { can_add: checked ? 1 : 0 });
                        }}
                      />
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className="flex items-center">
                      <Switch
                        id="can_edit"
                        checked={item.can_edit}
                        onCheckedChange={(checked: boolean) => {
                          dataList[index].can_edit = checked;
                          setDataList([...dataList]);
                          fetchEdit(item.id!, { can_edit: checked ? 1 : 0 });
                        }}
                      />
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className="flex items-center">
                      <Switch
                        id="can_delete"
                        checked={item.can_delete}
                        onCheckedChange={(checked: boolean) => {
                          dataList[index].can_delete = checked;
                          setDataList([...dataList]);
                          fetchEdit(item.id!, { can_delete: checked ? 1 : 0 });
                        }}
                      />
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className="flex items-center">
                      <Switch
                        id="can_approve"
                        checked={item.can_approve}
                        onCheckedChange={(checked: boolean) => {
                          dataList[index].can_approve = checked;
                          setDataList([...dataList]);
                          fetchEdit(item.id!, { can_approve: checked ? 1 : 0 });
                        }}
                      />
                    </div>
                  </TableCell>
                  <TableCell className="text-center">
                    <div className="flex gap-2 justify-center items-center">
                      {!item.access_all_area && (
                        <TooltipProvider>
                          <Tooltip>
                            <TooltipTrigger>
                              <Button
                                className="bg-emerald-500"
                                onClick={() => {
                                  navigate(
                                    RouteValue.roleMenuList.replace(
                                      ":role_id",
                                      item.id!
                                    )
                                  );
                                }}
                              >
                                <LayoutIcon className="w-4 h-4 stroke-white" />
                              </Button>
                            </TooltipTrigger>
                            <TooltipContent>See Access Menu</TooltipContent>
                          </Tooltip>
                        </TooltipProvider>
                      )}

                      <FormRole
                        data={item}
                        onFormSubmit={(value: Role) => {
                          onEdit(index, value);
                        }}
                      >
                        <Button className="bg-blue-500">
                          <EditIcon className="w-4 h-4 stroke-white" />
                        </Button>
                      </FormRole>
                      <DeleteRole
                        data={item}
                        onDelete={() => {
                          fetchDataList();
                        }}
                      >
                        <Button className="bg-rose-500">
                          <Trash2Icon className="w-4 h-4 stroke-white" />
                        </Button>
                      </DeleteRole>
                    </div>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </div>
      <PaginationTable pagination={pagination} setPagination={setPagination} />
    </>
  );
}
