import { zodResolver } from "@hookform/resolvers/zod";
import { format } from "date-fns";
import { AssetApi } from "entities/api/assetApi";
import { AreaType } from "entities/model/areaModel";
import { Asset } from "entities/model/assetModel";
import { Manufacture } from "entities/model/manufactureModel";
import { PIC } from "entities/model/picModel";
import { Supplier } from "entities/model/supplierModel";
import SelectManufacture from "features/manufacture/SelectManufacture";
import SelectPIC from "features/pic/SelectPIC";
import SelectSupplier from "features/supplier/SelectSupplier";
import { SaveIcon } from "lucide-react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import DefaultButton from "shared/ui/button/DefaultButton";
import { DateInput } from "shared/ui/input/DateInput";
import NumberInput from "shared/ui/input/NumberInput";
import TextInput from "shared/ui/input/TextInput";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "shared/ui/shadcn/components/ui/dialog";
import { Form } from "shared/ui/shadcn/components/ui/form";
import { useToast } from "shared/ui/shadcn/components/ui/use-toast";
import ErrorText from "shared/ui/text/ErrorText";
import { AssetValidationValue } from "shared/values/validations/assetValidationValue";
import { z } from "zod";

interface Props {
  children: JSX.Element;
  onFormSubmit?: any;
  data?: Asset | null;
  isParent?: boolean;
  asset_id?: string;
  areaType?: AreaType;
  area_level_id?: string;
}

export default function FormAsset({
  children,
  onFormSubmit,
  data = null,
  isParent = true,
  asset_id = "",
  areaType = "MANUFACTURE",
  area_level_id = "",
}: Props) {
  const [open, setOpen] = useState<boolean>(false);
  const form = useForm<z.infer<typeof AssetValidationValue.add>>({
    resolver: zodResolver(AssetValidationValue.add),
    defaultValues: {
      name: data ? data.name : "",
      asset_no: data ? data.asset_no : "",
      machine_no: data ? data.machine_no : "",
      warranty: data ? data.warranty : "",
      price: data ? data.price : 0,
      purchase_date: data
        ? format(
            data.purchase_date === null ? new Date() : data.purchase_date!,
            "Y-MM-dd"
          )
        : format(new Date(), "Y-MM-dd"),
      is_parent: data ? (data.is_parent ? 1 : 0) : isParent ? 1 : 0,
      asset_id: asset_id,
      manufacture_id: data ? data.manufacture_id : "",
      supplier_id: data ? data.supplier_id : "",
      pic_id: data ? data.pic_id : "",
      area_type: data ? data.area_type : areaType,
      area_level_id: data ? data.area_level_id : area_level_id,
    },
  });
  const [loading, setLoading] = useState<boolean>(false);
  const { toast } = useToast();
  const [pic, setPIC] = useState<PIC>({ id: data ? data.pic_id : "" });
  const [manufacture, setManufacture] = useState<Manufacture>({
    id: data ? data.manufacture_id : "",
  });
  const [supplier, setSupplier] = useState<Supplier>({
    id: data ? data.supplier_id : "",
  });

  const onSubmit = async (values: z.infer<typeof AssetValidationValue.add>) => {
    try {
      setLoading(true);
      const response = data
        ? await AssetApi.edit(data.id!, { ...values })
        : await AssetApi.save({ ...values });
      if (!response.success) {
        const dataError = response.error;
        form.setError(dataError.path, { message: dataError.msg });
        return;
      }
      if (onFormSubmit) {
        onFormSubmit();
      }
      form.clearErrors();
      toast({
        title: `Success ${data === null ? "add" : "edit"} asset`,
        description: data === null ? "Success created!" : "Success edited!",
        className: "bg-emerald-500 !text-white",
      });
      setOpen(false);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger
        asChild
        onClick={() => {
          setOpen(true);
        }}
      >
        {children}
      </DialogTrigger>
      <DialogContent className="w-dialog-full">
        <DialogHeader>
          <DialogTitle>{data ? "Edit Asset" : "Add Asset"}</DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-2">
              <div>
                <TextInput
                  label="Name"
                  placeholder="Enter name asset"
                  value={form.getValues("name")}
                  setValue={(value: string) => {
                    form.setValue("name", value, { shouldValidate: true });
                  }}
                />
                <ErrorText text={form.formState.errors.name?.message!} />
              </div>
              <div>
                <TextInput
                  label="No Asset"
                  placeholder="Enter no asset"
                  value={form.getValues("asset_no")}
                  setValue={(value: string) => {
                    form.setValue("asset_no", value, { shouldValidate: true });
                  }}
                />
                <ErrorText text={form.formState.errors.asset_no?.message!} />
              </div>
              {areaType === "MANUFACTURE" && (
                <div>
                  <TextInput
                    label="No Machine"
                    placeholder="Enter no machine"
                    value={form.getValues("machine_no")}
                    setValue={(value: string) => {
                      form.setValue("machine_no", value, {
                        shouldValidate: true,
                      });
                    }}
                  />
                  <ErrorText
                    text={form.formState.errors.machine_no?.message!}
                  />
                </div>
              )}
              <div>
                <SelectPIC
                  label="PIC"
                  value={pic}
                  setValue={(value: PIC) => {
                    setPIC(value);
                    form.setValue("pic_id", value.id!);
                  }}
                  withAll={false}
                />
                <ErrorText text={form.formState.errors.pic_id?.message!} />
              </div>
              <div>
                <TextInput
                  label="Warranty"
                  placeholder="Enter warranty"
                  value={form.getValues("warranty")}
                  setValue={(value: string) => {
                    form.setValue("warranty", value, {
                      shouldValidate: true,
                    });
                  }}
                />
                <ErrorText text={form.formState.errors.warranty?.message!} />
              </div>
              <div>
                <SelectManufacture
                  label="Manufacture"
                  value={manufacture}
                  setValue={(value: Manufacture) => {
                    setManufacture(value);
                    form.setValue("manufacture_id", value.id!);
                  }}
                  withAll={false}
                />
                <ErrorText
                  text={form.formState.errors.manufacture_id?.message!}
                />
              </div>
              <div>
                <SelectSupplier
                  label="Supplier"
                  value={supplier}
                  setValue={(value: Supplier) => {
                    setSupplier(value);
                    form.setValue("supplier_id", value.id!);
                  }}
                  withAll={false}
                />
                <ErrorText text={form.formState.errors.supplier_id?.message!} />
              </div>
              <div>
                <NumberInput
                  label="Price"
                  placeholder="Enter price"
                  value={form.getValues("price")}
                  setValue={(value: number) => {
                    form.setValue("price", parseInt(`${value}`), {
                      shouldValidate: true,
                    });
                  }}
                />
                <ErrorText text={form.formState.errors.price?.message!} />
              </div>
              <div>
                <DateInput
                  label="Purchase Date"
                  date={new Date(form.getValues("purchase_date"))}
                  setDate={(value: Date) => {
                    form.setValue("purchase_date", format(value, "Y-MM-dd"), {
                      shouldValidate: true,
                    });
                  }}
                />
                <ErrorText
                  text={form.formState.errors.purchase_date?.message!}
                />
              </div>
            </div>
            <div className="flex justify-end">
              <DefaultButton
                label="Save changes"
                icon={<SaveIcon className="stroke-white w-6 h-6" />}
                loading={loading}
                type="submit"
                className="w-max"
              />
            </div>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
