import { Input } from "../shadcn/components/ui/input";

interface Props {
  label?: String;
  placeholder?: string;
  prefixIcon?: JSX.Element;
  suffixIcon?: JSX.Element;
  value?: number;
  setValue?: any;
}

export default function NumberInput({
  label,
  placeholder,
  prefixIcon,
  suffixIcon,
  value,
  setValue,
}: Props) {
  const widgetInput = (padding?: string) => (
    <Input
      className={`${padding ?? ""} w-full bg-white`}
      placeholder={placeholder}
      value={value}
      type="number"
      onChange={(v) => {
        setValue(v.target.value);
      }}
    />
  );

  const widgetFormControl = () => {
    if (prefixIcon || suffixIcon)
      return (
        <div className="relative w-full">
          {prefixIcon && (
            <div className="absolute left-2 h-full flex items-center">
              {prefixIcon}
            </div>
          )}
          {suffixIcon && (
            <div className="absolute right-2 h-full flex items-center">
              {suffixIcon}
            </div>
          )}
          {widgetInput(`${prefixIcon && "pl-8"} ${suffixIcon && "pr-8"}`)}
        </div>
      );

    return <div className="w-full">{widgetInput()}</div>;
  };

  return (
    <div>
      {label && <div className="mb-1">{label}</div>}
      {widgetFormControl()}
    </div>
  );
}
