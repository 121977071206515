interface Props {
  icon: string;
  title: string;
  desc: string;
  classNameIcon?: string;
}

export default function ItemDetailAsset({
  icon,
  title,
  desc,
  classNameIcon = "w-12 h-12 !bg-center !bg-contain !bg-no-repeat",
}: Props) {
  return (
    <div className="flex gap-2 items-center">
      <div
        className={`${classNameIcon}`}
        style={{
          background: `url("${icon}")`,
        }}
      ></div>
      <div>
        <div className="">{title}</div>
        <div className="text-gray-400">{desc}</div>
      </div>
    </div>
  );
}
