import { AreaType } from "entities/model/areaModel";
import ChartHistoryAr from "features/ar/ChartHistoryAr";
import CounterAreaAsset from "features/asset/CounterAreaAsset";
import ChartHistoryMtbf from "features/mtbf/ChartHistoryMtbf";
import ChartHistoryMttr from "features/mttr/ChartHistoryMttr";
import { motion } from "framer-motion";
import { useParams, useSearchParams } from "react-router-dom";
import CardTitleGeneral from "shared/ui/card/CardTitleGeneral";
import { AnimationValue } from "shared/values/animationValue";
import { AssetValue } from "shared/values/assetValue";
import FilterPerformanceManufacture from "widgets/manufacture/FilterPerformanceManufacture";

export default function PerformanceDetailManufacturingPage() {
  const { type } = useParams();
  const [searchParams] = useSearchParams();

  const handleChart = () => {
    switch (type) {
      case "AR":
        return <ChartHistoryAr />;
      case "MTTR":
        return <ChartHistoryMttr />;
      case "MTBF":
        return <ChartHistoryMtbf />;
      case "asset-total":
        return (
          <CounterAreaAsset
            areaType={
              (searchParams.get("area_type") as AreaType) ?? "MANUFACTURE"
            }
            className="h-full"
          />
        );
      case "energy":
        return <ChartHistoryMtbf />;
      default:
        return <></>;
    }
  };

  return (
    <motion.div
      variants={AnimationValue.list.container}
      initial="hidden"
      animate="visible"
      className="w-full !bg-white rounded-lg border border-gray-100 shadow-lg p-2 flex flex-col gap-2 xl:h-[calc(100vh-3.125rem-(4*theme('gap.2'))-5rem)]"
    >
      <CardTitleGeneral
        title={`${type ?? ""} Detail`}
        icon={AssetValue.performanceImage}
      />
      <FilterPerformanceManufacture
        areaType={(searchParams.get("area_type") as AreaType) ?? "MANUFACTURE"}
      />
      <div className="flex-grow rounded-lg bg-blue-100 mb-2 p1">
        {handleChart()}
      </div>
    </motion.div>
  );
}
