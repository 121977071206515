import TableRoleMenu from "features/role_menu/TableRoleMenu";
import { motion } from "framer-motion";
import { useParams } from "react-router-dom";
import CardTitleGeneral from "shared/ui/card/CardTitleGeneral";
import { AnimationValue } from "shared/values/animationValue";
import { AssetValue } from "shared/values/assetValue";

export default function ListRoleMenuPage() {
  const { role_id } = useParams();
  return (
    <div
      className="
      pt-[calc(3.125rem+theme('padding.2'))] md:pt-0
      px-2 md:px-0
      md:h-[calc(100vh-3.125rem-(2*theme('gap.2')))]
      "
    >
      <motion.div
        variants={AnimationValue.list.container}
        initial="hidden"
        animate="visible"
        className="w-full !bg-white rounded-lg border border-gray-100 shadow-lg p-2 h-full flex flex-col"
      >
        <CardTitleGeneral title="Role List" icon={AssetValue.employeeImage} />
        <TableRoleMenu role_id={role_id!} />
      </motion.div>
    </div>
  );
}
