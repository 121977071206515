import { zodResolver } from "@hookform/resolvers/zod";
import { RoleApi } from "entities/api/roleApi";
import { SaveIcon } from "lucide-react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import DefaultButton from "shared/ui/button/DefaultButton";
import SelectInput from "shared/ui/input/SelectInput";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "shared/ui/shadcn/components/ui/dialog";
import { Form } from "shared/ui/shadcn/components/ui/form";
import { useToast } from "shared/ui/shadcn/components/ui/use-toast";
import { Menu, NavigationUtil } from "shared/utils/navigationUtil";
import { RoleValidationValue } from "shared/values/validations/roleValidationValue";
import { z } from "zod";

interface Props {
  children: JSX.Element;
  onFormSubmit?: any;
  role_id: string;
}

export default function FormRoleMenu({
  children,
  onFormSubmit,
  role_id,
}: Props) {
  const [open, setOpen] = useState<boolean>(false);
  const form = useForm<z.infer<typeof RoleValidationValue.addMenu>>({
    resolver: zodResolver(RoleValidationValue.addMenu),
  });
  const [loading, setLoading] = useState<boolean>(false);
  const { toast } = useToast();

  const onSubmit = async (
    values: z.infer<typeof RoleValidationValue.addMenu>
  ) => {
    try {
      setLoading(true);
      const response = await RoleApi.saveMenu(role_id, {
        menu: values.menu,
        role_id: role_id,
      });
      if (!response.success) {
        const dataError = response.error;
        form.setError(dataError.path, { message: dataError.msg });
        return;
      }
      if (onFormSubmit) {
        onFormSubmit();
      }
      setOpen(false);
      toast({
        title: `Success add role menu`,
        description: "Success created!",
        className: "bg-emerald-500 !text-white",
      });
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger
        asChild
        onClick={() => {
          setOpen(true);
        }}
      >
        {children}
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Add Role Menu</DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <SelectInput
              label="Menu"
              value={form.getValues("menu")}
              setValue={(value: string) => {
                form.setValue("menu", value, {
                  shouldValidate: true,
                  shouldDirty: true,
                  shouldTouch: true,
                });
              }}
              dataList={[
                ...NavigationUtil.menuList.map((value: Menu) => {
                  return {
                    label: value.label,
                    value: value.name,
                  };
                }),
              ]}
            />
            <DefaultButton
              label="Save changes"
              icon={<SaveIcon className="stroke-white w-6 h-6" />}
              loading={loading}
              type="submit"
              className="mt-10"
            />
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
