import {
  animate,
  motion,
  useInView,
  useMotionValue,
  useTransform,
} from "framer-motion";
import { useEffect, useRef } from "react";
import { AnimationValue } from "shared/values/animationValue";

interface Props {
  icon: string;
  label: string;
  counter: number;
  unit?: string;
  isDecimal?: boolean;
  onClick?: any;
}

export default function CounterDashboard({
  icon,
  label,
  counter,
  unit = "",
  isDecimal = false,
  onClick,
}: Props) {
  const count = useMotionValue(0);
  const decimal = useTransform(count, (latest) => {
    return latest.toFixed(2);
  });
  const rounded = useTransform(count, (latest) => {
    return Math.round(latest);
  });
  const ref = useRef(null);
  const inView = useInView(ref);

  useEffect(() => {
    if (inView) {
      animate(count, counter, { duration: 2.5 });
    }
  }, [count, inView, counter]);

  return (
    <motion.li
      variants={AnimationValue.list.item}
      className={`!bg-white rounded-lg border border-gray-100 shadow-lg p-2 h-[5rem] flex gap-2 items-center ${
        onClick && "cursor-pointer"
      }`}
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
    >
      <div className="w-auto h-[calc(5rem-(2*theme('padding.2')))] aspect-square flex justify-center items-center">
        <img src={icon} alt={label} className="w-full h-auto" />
      </div>
      <div className="flex bg-blue-50 rounded-lg p-2 px-6 flex-grow h-full justify-between items-center">
        <label className="text-gray-400 font-semibold">{label}</label>
        <label className="font-semibold text-3xl flex-grow text-end">
          <motion.span
            ref={ref}
            className="font-semibold text-3xl flex-grow text-end"
          >
            {isDecimal ? decimal : rounded}
          </motion.span>
          <span className="font-semibold text-3xl flex-grow text-end">
            {unit}
          </span>
        </label>
      </div>
    </motion.li>
  );
}
