/* eslint-disable react-hooks/exhaustive-deps */
import { format } from "date-fns";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTrigger,
} from "shared/ui/shadcn/components/ui/dialog";
import { AnimationValue } from "shared/values/animationValue";
import { SettingValue } from "shared/values/settingValue";
import ItemEnergy from "./ItemEnergy";
import { EnergyApi } from "entities/api/energyApi";
import { useNavigate } from "react-router-dom";
import { RouteValue } from "shared/values/routeValue";

interface Props {
  classNameContainer?: string;
  classNameItem?: string;
}

export default function ListEnergy({
  classNameContainer,
  classNameItem,
}: Props) {
  const [dataList, setDataList] = useState(SettingValue.energyConsumeList);
  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      const response = await EnergyApi.getAvg();
      if (response.success) {
        let energy = [...dataList];
        energy[0].count = response.data.energy.water;
        energy[1].count = response.data.energy.gas;
        energy[2].count =
          response.data.energy.electric === 0
            ? 0.5
            : response.data.energy.electric;
        setDataList(energy);
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <motion.ul
      variants={AnimationValue.list.container}
      initial="hidden"
      animate="visible"
      className={classNameContainer}
    >
      {dataList.map((data: any, index: number) => (
        <motion.li variants={AnimationValue.list.item} key={index}>
          <div className={classNameItem}>
            <ItemEnergy
              data={data}
              isBlur={data.isBlur}
              onClick={() => {
                if (data.type === "electric") {
                  navigate(
                    RouteValue.manufacturePerformanceDetailDashboard.replace(
                      ":type",
                      "energy"
                    )
                  );
                }
              }}
            />
          </div>
        </motion.li>
      ))}
    </motion.ul>
  );
}
