export interface Paginations {
  page?: number;
  total_page?: number;
  per_page?: number;
  total_data?: number;
}

const fromJson = (json: any): Paginations => {
  let result: Paginations = {
    page: json["page"],
    total_page: json["total_page"],
    per_page: json["per_page"],
    total_data: json["total_data"],
  };

  return result;
};

const toJson = (value: Paginations): string => {
  return JSON.stringify(value);
};

const handleInitial = (perPage: number): Paginations => {
  return {
    page: 1,
    per_page: perPage,
    total_data: 0,
    total_page: 1,
  };
};

export const PaginationModel = { fromJson, toJson, handleInitial };
