import ListEnergy from "features/energy/ListEnergy";
import { motion } from "framer-motion";
import CardTitleSmallGeneral from "shared/ui/card/CardTitleSmallGeneral";
import { AnimationValue } from "shared/values/animationValue";
import { AssetValue } from "shared/values/assetValue";

export default function EnergyManufacture() {
  return (
    <motion.div
      variants={AnimationValue.list.item}
      className="
        w-full !bg-white rounded-lg border border-gray-100 shadow-lg 
        flex flex-col
        xl:h-[calc((100vh-3.125rem-(6*theme('gap.2'))-5rem)*25/100)]"
    >
      <CardTitleSmallGeneral
        icon={AssetValue.energyImage}
        title="Energy Consume"
      />
      <ListEnergy
        classNameContainer="flex-grow rounded-lg mx-2 mb-2 grid grid-cols-1 xl:grid-cols-3 gap-2"
        classNameItem="xl:h-[calc(((100vh-3.125rem-(6*theme('gap.2'))-5rem)*25/100)-theme('height.6')-theme('margin.2')*3)] cursor-pointer"
      />
    </motion.div>
  );
}
