import CounterAR from "features/ar/CounterAr";
import CounterAsset from "features/asset/CounterAsset";
import CounterMTBF from "features/mtbf/CounterMTBF";
import CounterMTTR from "features/mttr/CounterMTTR";
import { motion } from "framer-motion";
import { AnimationValue } from "shared/values/animationValue";

export default function CounterManufacture() {
  return (
    <motion.ul
      variants={AnimationValue.list.container}
      initial="hidden"
      animate="visible"
      className="grid grid-cols-1 xl:grid-cols-4 gap-2 xl:h-[5rem]"
    >
      <CounterAsset areaType="MANUFACTURE" />
      <CounterMTBF />
      <CounterMTTR />
      <CounterAR />
    </motion.ul>
  );
}
