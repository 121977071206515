import { zodResolver } from "@hookform/resolvers/zod";
import { ManufactureApi } from "entities/api/manufactureApi";
import { Manufacture } from "entities/model/manufactureModel";
import { SaveIcon } from "lucide-react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import DefaultButton from "shared/ui/button/DefaultButton";
import TextInput from "shared/ui/input/TextInput";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "shared/ui/shadcn/components/ui/dialog";
import { Form } from "shared/ui/shadcn/components/ui/form";
import { useToast } from "shared/ui/shadcn/components/ui/use-toast";
import ErrorText from "shared/ui/text/ErrorText";
import { ManufactureValidationValue } from "shared/values/validations/manufactureValidationValue";
import { z } from "zod";

interface Props {
  children: JSX.Element;
  onFormSubmit?: any;
  data?: Manufacture | null;
}

export default function FormManufacture({
  children,
  onFormSubmit,
  data = null,
}: Props) {
  const [open, setOpen] = useState<boolean>(false);
  const form = useForm<z.infer<typeof ManufactureValidationValue.add>>({
    resolver: zodResolver(ManufactureValidationValue.add),
    defaultValues: {
      name: data ? data.name : "",
    },
  });
  const [loading, setLoading] = useState<boolean>(false);
  const { toast } = useToast();

  const onSubmit = async (
    values: z.infer<typeof ManufactureValidationValue.add>
  ) => {
    try {
      setLoading(true);
      const response = data
        ? await ManufactureApi.edit(data.id!, values.name)
        : await ManufactureApi.save(values.name);
      if (!response.success) {
        const dataError = response.error;
        form.setError(dataError.path, { message: dataError.msg });
        return;
      }
      if (onFormSubmit) {
        onFormSubmit({ ...data, name: values.name });
      }
      form.clearErrors();
      if (data === null) {
        form.reset({ name: "" });
      }
      toast({
        title: `Success ${data === null ? "add" : "edit"} manufacture`,
        description: data === null ? "Success created!" : "Success edited!",
        className: "bg-emerald-500 !text-white",
      });
      setOpen(false);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger
        asChild
        onClick={() => {
          setOpen(true);
        }}
      >
        {children}
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>
            {data ? "Edit Manufacture" : "Add Manufacture"}
          </DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <div className="mb-10">
              <TextInput
                label="Name"
                placeholder="Enter name manufacture"
                value={form.getValues("name")}
                setValue={(value: string) => {
                  form.setValue("name", value, { shouldValidate: true });
                }}
              />
              <ErrorText text={form.formState.errors.name?.message!} />
            </div>
            <DefaultButton
              label="Save changes"
              icon={<SaveIcon className="stroke-white w-6 h-6" />}
              loading={loading}
              type="submit"
            />
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
