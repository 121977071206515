import { NotificationApi } from "entities/api/notificationApi";
import {
  NotificationModel,
  Notification,
} from "entities/model/notificationModel";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Skeleton } from "shared/ui/shadcn/components/ui/skeleton";
import { RouteValue } from "shared/values/routeValue";

interface Props {
  isRefresh: boolean;
}

export default function ListUserNotification({ isRefresh }: Props) {
  const limit = 5;
  const [dataList, setDataList] = useState<Notification[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const navigation = useNavigate();

  const fetchDataList = async () => {
    try {
      setLoading(true);
      let offset = 0;
      const response = await NotificationApi.getListByUser({
        offset,
        limit,
      });
      let responseDataList: Notification[] = [];
      if (response.success) {
        for (let index = 0; index < response.dataList.length; index++) {
          const item = response.dataList[index];
          responseDataList.push(NotificationModel.fromJson(item));
        }
      }
      setDataList(responseDataList);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleOpen = async (id: string, index: number) => {
    try {
      const response = await NotificationApi.edit(id, { opened: 1 });
      if (response.success) {
        dataList[index].opened = true;
        const newData = [...dataList];
        setDataList(newData);
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchDataList();
  }, [isRefresh]);

  return loading ? (
    <>
      {[...Array(limit)].map((value: any, index: number) => (
        <Skeleton key={index} className="w-full h-20" />
      ))}
    </>
  ) : (
    <>
      {dataList.map((data: Notification, index: number) => (
        <div
          key={index}
          className={`${
            data.opened ? "bg-gray-200" : "bg-blue-100"
          } p-2 rounded-lg cursor-pointer`}
          onClick={() => {
            if (!data.opened) handleOpen(data.id!, index);
            let url = data.notif_data?.asset?.is_parent
              ? RouteValue.assetList
              : RouteValue.assetChildList.replace(
                  ":asset_id",
                  data.notif_data?.asset?.id!
                );
            url = `${url}?asset_id=${data.notif_data?.asset?.id}&areaType=${data.notif_data?.asset?.area_type}`;
            navigation(url);
          }}
        >
          <div className="font-semibold mb-2">{data.title}</div>
          <div className="flex gap-2">
            <img
              src={data.sender?.avatar}
              alt="Avatar"
              className="w-10 h-10 rounded-full"
            />
            <div>
              <div>{data.body}</div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
}
