export type AreaType = "MANUFACTURE" | "OFFICE";

export interface Area {
  id?: string;
  area_code?: string;
  name?: string;
  level?: number;
  area_id?: string;
  oee?: number;
  oee_yesterday?: number;
  asset_off?: number;
  layout?: string;
}

const fromJson = (json: any): Area => {
  let result: Area = {
    id: json["id"],
    area_code: json["area_code"],
    name: json["name"],
    level: json["level"],
    area_id: json["area_id"],
    oee: json["oee"],
    oee_yesterday: json["oee_yesterday"],
    asset_off: json["asset_off"],
    layout: json["layout"],
  };
  return result;
};

const toJson = (value: Area): string => {
  return JSON.stringify(value);
};

export const AreaModel = { fromJson, toJson };
