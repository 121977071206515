import { ApiValue } from "shared/values/apiValue";
import { BaseApi, ParamList } from "./baseApi";
import { User } from "entities/model/userModel";

const signIn = async (username: string, password: string) => {
  return await BaseApi.post({
    url: ApiValue.userSignIn,
    data: { username, password },
    header: "signIn",
  });
};

const getProfile = async () =>
  await BaseApi.get({ url: ApiValue.userGetProfile });

const edit = async ({
  id,
  avatar,
  display_name,
  role_id,
  username,
  new_password,
  old_password,
}: User) => {
  let url = ApiValue.userEdit.replace("{user_id}", id!);
  let data = {};
  if (avatar) data = { ...data, avatar };
  if (display_name) data = { ...data, display_name };
  if (role_id) data = { ...data, role_id };
  if (username) data = { ...data, username };
  if (new_password) data = { ...data, new_password };
  if (old_password) data = { ...data, old_password };

  return await BaseApi.put({ url, data });
};

const save = async (data: any) => {
  return await BaseApi.post({ url: ApiValue.userSave, data });
};

const remove = async (id: string) => {
  return await BaseApi.deleteData({
    url: ApiValue.userDelete.replace("{user_id}", id),
  });
};

const getList = async ({ limit, offset, keyword = "" }: ParamList) => {
  let url = `${ApiValue.userGetList}?limit=${limit}&offset=${offset}`;
  if (keyword) url = `${url}&keyword=${keyword}`;
  return await BaseApi.get({ url });
};

const resetPassword = async (id: string) => {
  return await BaseApi.put({
    url: ApiValue.userResetPassword.replace("{user_id}", id),
  });
};

const get = async (id: string) => {
  return await BaseApi.get({
    url: ApiValue.userGet.replace("{user_id}", id),
  });
};

const getAreaList = async ({
  id,
  limit,
  offset,
  keyword = "",
  areaId = "",
}: ParamList) => {
  let url =
    `${ApiValue.userAreaGetList}?limit=${limit}&offset=${offset}`.replace(
      "{user_id}",
      id!
    );
  if (keyword) url = `${url}&keyword=${keyword}`;
  if (areaId) url = `${url}&area_id=${areaId}`;
  return await BaseApi.get({ url });
};

const saveArea = async (user_id: string, data: any) => {
  return await BaseApi.post({
    url: ApiValue.userAreaSave.replace("{user_id}", user_id),
    data,
  });
};

const removeArea = async (user_id: string, area_id: string) => {
  return await BaseApi.deleteData({
    url: ApiValue.userAreaDelete
      .replace("{user_id}", user_id)
      .replace("{area_id}", area_id),
  });
};

export const UserApi = {
  signIn,
  getProfile,
  edit,
  save,
  remove,
  getList,
  resetPassword,
  get,
  getAreaList,
  saveArea,
  removeArea,
};
