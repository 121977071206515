/* eslint-disable react-hooks/exhaustive-deps */
import { Asset, AssetModel } from "entities/model/assetModel";
import ItemDetailAsset from "./ItemDetailAsset";
import { AssetValue } from "shared/values/assetValue";
import { useEffect, useState } from "react";
import { format } from "date-fns";
import { AssetApi } from "entities/api/assetApi";
import { Skeleton } from "shared/ui/shadcn/components/ui/skeleton";

interface Props {
  className?: string;
  asset_id: string;
}

export default function DetailAsset({
  asset_id,
  className = "grid grid-cols-3 p-2 gap-2 gap-x-2",
}: Props) {
  const [data, setData] = useState<Asset>({});
  const [loading, setLoading] = useState<boolean>(true);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await AssetApi.get(asset_id);
      setData(AssetModel.fromJson(response.data));
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [asset_id]);

  return loading ? (
    <div className={`${className}`}>
      {[...Array(10)].map((item: any, index: number) => (
        <Skeleton className="w-full h-auto aspect-video" key={index} />
      ))}
    </div>
  ) : (
    <div className={`${className}`}>
      <ItemDetailAsset
        title="Name"
        desc={data?.name!}
        icon={AssetValue.assetImage}
      />
      <ItemDetailAsset
        title="No Machine"
        desc={data?.machine_no!}
        icon={AssetValue.assetImage}
      />
      <ItemDetailAsset
        title="No Asset"
        desc={data?.asset_no!}
        icon={AssetValue.assetImage}
      />
      <ItemDetailAsset
        title={data?.areaLevel1?.name!}
        desc={data?.areaLevel2?.name!}
        icon={AssetValue.layoutImage}
      />
      <ItemDetailAsset
        title={data.area_type === "MANUFACTURE" ? "Line" : "Room"}
        desc={data?.areaLevel3?.name!}
        icon={AssetValue.layoutImage}
      />
      <ItemDetailAsset
        title="PIC"
        desc={data?.pic?.name!}
        icon={AssetValue.employeeImage}
      />
      <ItemDetailAsset
        title="Warranty"
        desc={data?.warranty!}
        icon={AssetValue.assetImage}
      />
      <ItemDetailAsset
        title="Manufacture"
        desc={data?.manufacture?.name!}
        icon={AssetValue.factoryImage}
      />
      <ItemDetailAsset
        title="Supplier"
        desc={data?.supplier?.name!}
        icon={AssetValue.factoryImage}
      />
      <ItemDetailAsset
        title="Purchase Date"
        desc={format(data?.purchase_date!, "PPPP")}
        icon={AssetValue.calendarImage}
      />
      <ItemDetailAsset
        title="Price"
        desc={`Rp ${data?.price?.toLocaleString()}`}
        icon={AssetValue.bookImage}
      />
      {/* <ItemDetailAsset
        title="Book Value"
        desc={`Rp ${BookValueHelper.countBookValue(
          data?.price ?? 0,
          data?.purchase_date ?? null
        ).toLocaleString()}`}
        icon={AssetValue.bookImage}
      /> */}
    </div>
  );
}
