/* eslint-disable react-hooks/exhaustive-deps */
import { PlusCircleIcon, SearchIcon, Trash2Icon } from "lucide-react";
import { useEffect, useState } from "react";
import TextInput from "shared/ui/input/TextInput";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "shared/ui/shadcn/components/ui/table";
import EmptyRowTable from "shared/ui/table/EmptyRowTable";
import { SettingValue } from "shared/values/settingValue";
import { PaginationModel, Paginations } from "entities/model/paginationModel";
import PaginationTable from "shared/ui/table/PaginationTable";
import { Button } from "shared/ui/shadcn/components/ui/button";
import LoadingRowTable from "shared/ui/table/LoadingRowTable";
import { Area, AreaModel, AreaType } from "entities/model/areaModel";
import { UserApi } from "entities/api/userApi";
import { User } from "entities/model/userModel";
import DeleteAreaUser from "./DeleteAreaUser";
import FormAreaUser from "./FormAreaUser";
import DefaultButton from "shared/ui/button/DefaultButton";

interface Props {
  user: User;
  area: Area;
  areaType: AreaType;
}

export default function TableAreaUser({ user, area, areaType }: Props) {
  const limit = SettingValue.limitSupplier;
  const [keyword, setKeyword] = useState<string>("");
  const [pagination, setPagination] = useState<Paginations>(
    PaginationModel.handleInitial(limit)
  );
  const [dataList, setDataList] = useState<Area[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const fetchDataList = async () => {
    try {
      setLoading(true);
      let offset = 0;
      if (keyword) {
        offset = 0;
      } else {
        offset = pagination.page === 1 ? 0 : pagination.page! * limit - limit;
      }
      const response = await UserApi.getAreaList({
        offset,
        limit,
        keyword,
        id: user.id,
        areaId: area.area_id,
      });
      let responseDataList: Area[] = [];
      if (response.success) {
        for (let index = 0; index < response.dataList.length; index++) {
          const item = response.dataList[index];
          responseDataList.push(AreaModel.fromJson(item.area_level));
        }
        setPagination(response.pagination);
      }
      setDataList(responseDataList);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDataList();
  }, [keyword, pagination.page, areaType, area.id]);

  return (
    <>
      <div className="flex flex-col-reverse md:flex-row justify-between mb-2 p-2 bg-blue-100 rounded-lg gap-2">
        <div className="w-full md:w-1/3">
          <TextInput
            placeholder="Search by name"
            prefixIcon={<SearchIcon className="w-4 h-4 stroke-gray-500" />}
            value={keyword}
            setValue={setKeyword}
          />
        </div>
        <div>
          <FormAreaUser
            onFormSubmit={() => {
              fetchDataList();
            }}
            user={user}
            area={area}
            areaType={areaType}
          >
            <DefaultButton
              label="Add Data"
              icon={<PlusCircleIcon className="stroke-white w-6 h-6" />}
            />
          </FormAreaUser>
        </div>
      </div>
      <div className="rounded-md border">
        <Table>
          <TableHeader className="bg-primary">
            <TableRow>
              <TableHead className="w-[5%] text-white text-center">
                No
              </TableHead>
              <TableHead className="text-white">Name</TableHead>
              <TableHead className="w-[10%] text-white text-center">
                Action
              </TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {loading ? (
              <LoadingRowTable colSpan={3} />
            ) : dataList.length < 1 ? (
              <EmptyRowTable colSpan={3} />
            ) : (
              dataList.map((item: Area, index: number) => (
                <TableRow key={index}>
                  <TableCell className="text-center">
                    {(pagination.page === 1
                      ? 1
                      : pagination.page! * limit - limit + 1) + index}
                  </TableCell>
                  <TableCell>{item.name}</TableCell>
                  <TableCell className="text-center">
                    <div className="flex gap-2 justify-center items-center">
                      <DeleteAreaUser
                        data={item}
                        onDelete={() => {
                          fetchDataList();
                        }}
                        user_id={user.id!}
                      >
                        <Button className="bg-rose-500">
                          <Trash2Icon className="w-4 h-4 stroke-white" />
                        </Button>
                      </DeleteAreaUser>
                    </div>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </div>
      <PaginationTable pagination={pagination} setPagination={setPagination} />
    </>
  );
}
