/* eslint-disable react-hooks/exhaustive-deps */
import { Area, AreaType } from "entities/model/areaModel";
import { User } from "entities/model/userModel";
import SelectArea from "features/area/SelectArea";
import { FilterIcon } from "lucide-react";
import { useEffect, useState } from "react";
import DefaultButton from "shared/ui/button/DefaultButton";

interface Props {
  user: User;
  areaType: AreaType;
  setArea: any;
}

export default function FilterTableAreaUser({
  user,
  areaType,
  setArea,
}: Props) {
  const [areaLevel1, setAreaLevel1] = useState<Area>({ id: "" });
  const [areaLevel2, setAreaLevel2] = useState<Area>({ id: "" });

  const handleAreaLevel = () => {
    if (areaLevel1.id === "") {
      setAreaLevel2({ id: "" });
    }
  };

  const resetAllArealLevel = () => {
    setArea({ id: "" });
    setAreaLevel1({ id: "" });
    setAreaLevel2({ id: "" });
  };

  useEffect(() => {
    handleAreaLevel();
  }, [areaLevel1.id, areaLevel2.id]);

  useEffect(() => {
    resetAllArealLevel();
  }, [areaType]);

  return (
    <div className="bg-blue-100 rounded-lg p-2">
      <div className="font-semibold mb-2 text-lg">Info User</div>
      <div className="flex flex-col md:flex-row gap-4 md:items-center justify-center">
        <div className="flex gap-4 items-center">
          <img
            src={`${user.avatar}`}
            alt="Avatar"
            className="rounded-full w-14 h-14 bg-gray-100 border-2 border-primary"
          />
          <div className="flex flex-col">
            <div className="font-semibold">{user.display_name}</div>
            <div className="text-gray-400 text-sm font-semibold">
              {user.username}
            </div>
          </div>
          <div className="bg-emerald-500 px-4 py-2 rounded-lg text-white">
            Area Level: {user.role?.access_area}
          </div>
        </div>
        <div className="flex-grow grid grid-cols-1 md:grid-cols-3 gap-2 items-end">
          <SelectArea
            areaType={areaType}
            label={areaType === "MANUFACTURE" ? "Area" : "Building"}
            level={1}
            value={areaLevel1}
            setValue={setAreaLevel1}
            withAll={false}
          />
          {user.role?.access_area === 3 && (
            <SelectArea
              areaType={areaType}
              label={areaType === "MANUFACTURE" ? "Sub Area" : "Floor"}
              level={2}
              value={areaLevel2}
              setValue={setAreaLevel2}
              areaId={areaLevel1.area_id}
              disabled={areaLevel1.id === ""}
              withAll={false}
              parentId={areaLevel1.id}
            />
          )}
          <DefaultButton
            label="Filter"
            icon={<FilterIcon className="w-4 h-4 stroke-white" />}
            disabled={areaLevel1.id === ""}
            onClick={() => {
              if (areaLevel2.id !== "") {
                setArea(areaLevel2);
              } else if (areaLevel1.id !== "") {
                setArea(areaLevel1);
              } else {
                setArea({ id: "" });
              }
            }}
          />
        </div>
      </div>
    </div>
  );
}
