import { z } from "zod";

const add = z.object({
  name: z.string().min(1, { message: "Name is required!" }),
  access_all_area: z.number(),
  access_area: z.number(),
});
const addMenu = z.object({
  menu: z.string().min(1, { message: "Menu is required!" }),
});

export const RoleValidationValue = {
  add,
  addMenu,
};
