import { zodResolver } from "@hookform/resolvers/zod";
import { UserApi } from "entities/api/userApi";
import { Area, AreaType } from "entities/model/areaModel";
import { User } from "entities/model/userModel";
import SelectArea from "features/area/SelectArea";
import { SaveIcon } from "lucide-react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import DefaultButton from "shared/ui/button/DefaultButton";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "shared/ui/shadcn/components/ui/dialog";
import { Form } from "shared/ui/shadcn/components/ui/form";
import { useToast } from "shared/ui/shadcn/components/ui/use-toast";
import ErrorText from "shared/ui/text/ErrorText";
import { UserValidationValue } from "shared/values/validations/userValidationValue";
import { z } from "zod";

interface Props {
  children: JSX.Element;
  onFormSubmit?: any;
  user: User;
  area: Area;
  areaType: AreaType;
}

export default function FormAreaUser({
  children,
  onFormSubmit,
  user,
  area,
  areaType,
}: Props) {
  const [open, setOpen] = useState<boolean>(false);
  const form = useForm<z.infer<typeof UserValidationValue.addArea>>({
    resolver: zodResolver(UserValidationValue.addArea),
    defaultValues: {
      area_level_id: "",
    },
  });
  const [loading, setLoading] = useState<boolean>(false);
  const { toast } = useToast();

  const onSubmit = async (
    values: z.infer<typeof UserValidationValue.addArea>
  ) => {
    try {
      setLoading(true);
      const response = await UserApi.saveArea(user.id!, {
        area_level_id: values.area_level_id,
      });
      if (!response.success) {
        const dataError = response.error;
        form.setError(dataError.path, { message: dataError.msg });
        return;
      }
      if (onFormSubmit) {
        onFormSubmit();
      }
      form.clearErrors();
      form.setValue("area_level_id", "");
      toast({
        title: `Success add area user`,
        description: "Success created!",
        className: "bg-emerald-500 !text-white",
      });
      setOpen(false);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleLabelArea = (): string => {
    switch (area.level) {
      case 1:
        return areaType === "MANUFACTURE" ? "Sub Area" : "Floor";
      case 2:
        return areaType === "MANUFACTURE" ? "Line" : "Room";

      default:
        return "";
    }
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger
        asChild
        onClick={() => {
          setOpen(true);
        }}
      >
        {children}
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Add Area User</DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <div className="mb-2">
              <SelectArea
                areaType={areaType}
                label={handleLabelArea()}
                level={area.level! + 1}
                value={{ id: form.getValues("area_level_id") }}
                setValue={(value: Area) => {
                  form.setValue("area_level_id", value.id!, {
                    shouldValidate: true,
                  });
                }}
                areaId={area.area_id}
                withAll={false}
                parentId={area.id}
              />
              <ErrorText text={form.formState.errors.area_level_id?.message!} />
            </div>
            <DefaultButton
              label="Save changes"
              icon={<SaveIcon className="stroke-white w-6 h-6" />}
              loading={loading}
              type="submit"
              className="mt-8"
            />
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
