import { motion } from "framer-motion";
import { AnimationValue } from "shared/values/animationValue";
import AssetDetailOffice from "widgets/asset/AssetDetailOffice";
import AssetImageOffice from "widgets/asset/AssetImageOffice";
import AssetPositionOffice from "widgets/asset/AssetPositionOffice";
import AssetListOffice from "widgets/office/AssetListOffice";
import CounterAssetOffice from "widgets/office/CounterAssetOffice";

export default function AssetOfficePage() {
  return (
    <div className="flex flex-col gap-2 xl:h-[calc(100vh-3.125rem-(2*theme('gap.2')))] pt-[calc(3.125rem+theme('padding.4'))] xl:pt-0 px-2 xl:px-0">
      <motion.div
        variants={AnimationValue.list.container}
        initial="hidden"
        animate="visible"
        className="grid grid-cols-1 xl:grid-cols-4 xl:gap-2 xl:h-[calc(100vh-3.125rem-(4*theme('gap.2'))-5rem)]"
      >
        <AssetListOffice />
        <div className="h-4 xl:hidden"></div>
        <div className="xl:col-span-3 flex flex-col gap-2">
          <div className="xl:h-[calc(((100vh-3.125rem-(4*theme('gap.2'))-5rem)*50/100)-theme('gap.1'))] grid grid-cols-1 xl:grid-cols-3 gap-2">
            <CounterAssetOffice />
            <AssetPositionOffice />
          </div>
          <div className="xl:h-[calc(((100vh-3.125rem-(4*theme('gap.2'))-5rem)*50/100)-theme('gap.1'))] grid grid-cols-1 xl:grid-cols-3 gap-2">
            <AssetDetailOffice />
            <AssetImageOffice />
          </div>
        </div>
        <div className="h-4 xl:hidden"></div>
      </motion.div>
    </div>
  );
}
