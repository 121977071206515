import { AssetValue } from "shared/values/assetValue";
import { RouteValue } from "shared/values/routeValue";

interface ParamUrl {
  user_id?: string;
  role_id?: string;
}

const checkActiveNav = (
  name: string,
  pathname: string,
  { user_id, role_id }: ParamUrl
): boolean => {
  switch (pathname) {
    case "/manufacture":
      return name === "manufacture";
    case "/office":
      return name === "office";
    case "/asset":
      return name === "asset";
    case "/pic/master":
      return name === "pic_master";
    case "/pic/area":
      return name === "pic_area";
    case "/master/manufacture":
      return name === "master_manufacture";
    case "/master/supplier":
      return name === "master_supplier";
    case "/user":
    case `/user/${user_id}/area`:
      return name === "user";
    case "/role":
    case `/role/${role_id}/menu`:
      return name === "role";
    default:
      return false;
  }
};

// Nav Child Value
const navDataMasterList: SidebarNav[] = [
  {
    icon: AssetValue.factoryImage,
    label: "Manufacture",
    name: "master_manufacture",
    path: RouteValue.masterManufactureList,
  },
  {
    icon: AssetValue.factoryImage,
    label: "Supplier",
    name: "master_supplier",
    path: RouteValue.masterSupplierList,
  },
];
const navPICList: SidebarNav[] = [
  {
    icon: AssetValue.employeeImage,
    label: "Master PIC",
    name: "pic_master",
    path: RouteValue.picMasterList,
  },
  {
    icon: AssetValue.employeeImage,
    label: "PIC of Area",
    name: "pic_area",
    path: RouteValue.picAreaList,
  },
];
const navUserList: SidebarNav[] = [
  {
    icon: AssetValue.employeeImage,
    label: "User",
    name: "user",
    path: RouteValue.userList,
  },
  {
    icon: AssetValue.employeeImage,
    label: "Role",
    name: "role",
    path: RouteValue.roleList,
  },
];

// Menu
const menuList: Menu[] = [
  {
    label: "Manufacturing",
    name: "manufacturing",
  },
  {
    label: "Office",
    name: "office",
  },
  {
    label: "Asset",
    name: "asset",
  },
  {
    label: "User",
    name: "user",
  },
  {
    label: "Role",
    name: "role",
  },
  {
    label: "PIC",
    name: "pic",
  },
  {
    label: "PIC of Area",
    name: "pic_area",
  },
  {
    label: "Data Master Manufacture",
    name: "m_manufacture",
  },
  {
    label: "Data Master Supplier",
    name: "m_supplier",
  },
];

export const NavigationUtil = {
  checkActiveNav,
  navDataMasterList,
  navPICList,
  navUserList,
  menuList,
};

export interface Menu {
  name: string;
  label: string;
}

export interface SidebarNav {
  name: string;
  icon: string;
  label: string;
  path?: string;
}
