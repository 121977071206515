import * as React from "react";
import { format } from "date-fns";
import { Calendar as CalendarIcon } from "lucide-react";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../shadcn/components/ui/popover";
import { Button } from "../shadcn/components/ui/button";
import { Calendar } from "../shadcn/components/ui/calendar";
import { cn } from "../shadcn/lib/utils";

interface Props {
  label: string;
  date?: Date | null;
  setDate: any;
}

export function DateInput({ label, date, setDate }: Props) {
  return (
    <div className="w-full flex-col">
      <div className="mb-1">{label}</div>
      <Popover>
        <PopoverTrigger asChild>
          <Button
            variant={"outline"}
            className={cn(
              "w-full justify-start text-left font-normal bg-white",
              !date && "text-muted-foreground"
            )}
          >
            <CalendarIcon className="mr-2 h-4 w-4" />
            {date ? format(date, "PPP") : <span>Pick a date</span>}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0">
          <Calendar
            fromYear={1}
            toYear={3000}
            captionLayout="dropdown-buttons"
            mode="single"
            selected={date ?? new Date()}
            onSelect={(e) => {
              if (e) {
                setDate(e);
              }
            }}
            initialFocus
          />
        </PopoverContent>
      </Popover>
    </div>
  );
}
