import { PIC } from "entities/model/picModel";

interface Props {
  data: PIC;
}

export default function ItemPIC({ data }: Props) {
  return (
    <div
      className={`h-full bg-blue-50 flex gap-2 items-center p-2 max-[1439px]:py-4 rounded-lg w-full`}
    >
      <div
        className={`
        h-full w-auto aspect-square 
        rounded-full border-2 border-primary 
        !bg-no-repeat !bg-center !bg-cover`}
        style={{ backgroundImage: `url("${data.avatar}")` }}
      ></div>
      <div className="flex flex-col">
        <div className={`text-sm font-semibold`}>{data.name}</div>
        <div className={`text-xs`}>{data.nik}</div>
      </div>
    </div>
  );
}
