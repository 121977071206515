// const domain = "http://127.0.0.1:4000";
const domain = "https://amnostra-api.uss.co.id";
// const domain = "https://cmms-api.akebono-astra.co.id:4000";
const baseUrl = `${domain}/api`;
// const baseUrl = "https://cmms-api.akebono-astra.co.id:4000/api";
// const baseUrl = "http://192.168.100.23:4000/api";
// const baseUrl = "https://q7crbv9x-4000.asse.devtunnels.ms/api";

const baseUrlPublic = `${domain}/public`;
const publicTemplateExcelAsset = `${baseUrlPublic}/asset/template_asset.xlsx`;

// User
const userSignIn = `${baseUrl}/user/sign-in`;
const userGetToken = `${baseUrl}/user/token`;
const userGetProfile = `${baseUrl}/user/profile`;
const userGet = `${baseUrl}/user/{user_id}`;
const userEdit = `${baseUrl}/user/{user_id}`;
const userGetList = `${baseUrl}/user`;
const userSave = `${baseUrl}/user`;
const userDelete = `${baseUrl}/user/{user_id}`;
const userResetPassword = `${baseUrl}/user/{user_id}/password`;
const userAreaGetList = `${baseUrl}/user/{user_id}/area`;
const userAreaSave = `${baseUrl}/user/{user_id}/area`;
const userAreaDelete = `${baseUrl}/user/{user_id}/area/{area_id}`;
const userGetNotification = `${baseUrl}/user/notification`;
const userGetNotificationTotal = `${baseUrl}/user/notification/total`;

// File
const fileUploadUserAvatar = `${baseUrl}/file/user/{user_id}/avatar`;
const fileUploadPICAvatar = `${baseUrl}/file/pic/avatar`;
const fileImportAsset = `${baseUrl}/file/asset/excel`;
const fileUploadAssetImage = `${baseUrl}/file/asset/{asset_id}/image`;

// Manufacture
const manufactureGetList = `${baseUrl}/manufacture`;
const manufactureSave = `${baseUrl}/manufacture`;
const manufactureEdit = `${baseUrl}/manufacture/{manufacture_id}`;
const manufactureDelete = `${baseUrl}/manufacture/{manufacture_id}`;
const manufactureGetTotal = `${baseUrl}/manufacture/total`;

// Supplier
const supplierGetList = `${baseUrl}/supplier`;
const supplierSave = `${baseUrl}/supplier`;
const supplierEdit = `${baseUrl}/supplier/{supplier_id}`;
const supplierDelete = `${baseUrl}/supplier/{supplier_id}`;
const supplierGetTotal = `${baseUrl}/supplier/total`;

// PIC
const picGetList = `${baseUrl}/pic`;
const picSave = `${baseUrl}/pic`;
const picEdit = `${baseUrl}/pic/{pic_id}`;
const picDelete = `${baseUrl}/pic/{pic_id}`;
const picAreaGetList = `${baseUrl}/pic/area`;
const picAreaSave = `${baseUrl}/pic/area`;
const picAreaDelete = `${baseUrl}/pic/area/{pic_area_id}`;

// Area
const areaGetList = `${baseUrl}/area`;
const areaGetHirarki = `${baseUrl}/area/{area_id}/hirarki`;
const areaGetAssetTotal = `${baseUrl}/area/total/asset`;
const areaGet = `${baseUrl}/area/{area_id}`;

// Role
const roleGetList = `${baseUrl}/role`;
const roleSave = `${baseUrl}/role`;
const roleEdit = `${baseUrl}/role/{role_id}`;
const roleDelete = `${baseUrl}/role/{role_id}`;
const roleMenuGetList = `${baseUrl}/role/{role_id}/menu`;
const roleMenuSave = `${baseUrl}/role/{role_id}/menu`;
const roleMenuDelete = `${baseUrl}/role/{role_id}/menu/{role_menu_id}`;

// Asset
const assetGetList = `${baseUrl}/asset`;
const assetSave = `${baseUrl}/asset`;
const assetGetDashboardList = `${baseUrl}/asset/list`;
const assetEdit = `${baseUrl}/asset/{asset_id}`;
const assetGet = `${baseUrl}/asset/{asset_id}`;
const assetDelete = `${baseUrl}/asset/{asset_id}`;
const assetGetChildList = `${baseUrl}/asset/{asset_id}/child`;
const assetGetExcelList = `${baseUrl}/asset/excel`;
const assetGetExcelChildList = `${baseUrl}/asset/{asset_id}/child/excel`;
const assetGetTotal = `${baseUrl}/asset/total`;
const assetGetTotalArea = `${baseUrl}/asset/total/area`;
const assetGetWarrantyList = `${baseUrl}/asset/warranty`;
const assetImageSave = `${baseUrl}/asset/{asset_id}/image`;
const assetImageDelete = `${baseUrl}/asset/{asset_id}/image/{asset_image_id}`;
const assetOverhaulGetList = `${baseUrl}/asset/{asset_id}/overhaul`;
const assetOverhaulSave = `${baseUrl}/asset/{asset_id}/overhaul`;
const assetOverhaulEdit = `${baseUrl}/asset/{asset_id}/overhaul/{asset_overhaul_id}`;
const assetOverhaulDelete = `${baseUrl}/asset/{asset_id}/overhaul/{asset_overhaul_id}`;

// Notification
const notificationEdit = `${baseUrl}/notification/{notification_id}`;

// Performance
const performanceGetAvg = `${baseUrl}/performance/{type}/avg`;
const performanceGetOEE = `${baseUrl}/performance/oee`;
const performanceGetHistory = `${baseUrl}/performance/{type}/history`;

// Energy
const energyGetAvg = `${baseUrl}/energy/avg`;

export const ApiValue = {
  publicTemplateExcelAsset,
  userSignIn,
  userGet,
  userGetToken,
  userGetProfile,
  userEdit,
  userGetList,
  userSave,
  userDelete,
  userResetPassword,
  userAreaGetList,
  userAreaSave,
  userAreaDelete,
  userGetNotification,
  userGetNotificationTotal,
  fileUploadUserAvatar,
  fileUploadPICAvatar,
  fileImportAsset,
  fileUploadAssetImage,
  manufactureGetList,
  manufactureSave,
  manufactureEdit,
  manufactureDelete,
  manufactureGetTotal,
  supplierGetList,
  supplierSave,
  supplierEdit,
  supplierDelete,
  supplierGetTotal,
  picGetList,
  picSave,
  picEdit,
  picDelete,
  picAreaGetList,
  picAreaSave,
  picAreaDelete,
  areaGetList,
  areaGetHirarki,
  areaGetAssetTotal,
  areaGet,
  roleGetList,
  roleSave,
  roleEdit,
  roleDelete,
  roleMenuGetList,
  roleMenuSave,
  roleMenuDelete,
  assetGetList,
  assetSave,
  assetGetDashboardList,
  assetEdit,
  assetGet,
  assetDelete,
  assetGetChildList,
  assetGetExcelList,
  assetGetExcelChildList,
  assetGetTotal,
  assetGetTotalArea,
  assetGetWarrantyList,
  assetImageSave,
  assetImageDelete,
  assetOverhaulGetList,
  assetOverhaulSave,
  assetOverhaulEdit,
  assetOverhaulDelete,
  notificationEdit,
  performanceGetAvg,
  performanceGetOEE,
  performanceGetHistory,
  energyGetAvg,
};
