import { format } from "date-fns";
import { Asset } from "entities/model/assetModel";
import { motion } from "framer-motion";
import { EyeIcon } from "lucide-react";
import { useNavigate, useParams } from "react-router-dom";
import { BookValueHelper } from "shared/helpers/bookValueHelper";
import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuTrigger,
} from "shared/ui/shadcn/components/ui/context-menu";
import { AnimationValue } from "shared/values/animationValue";
import { AssetValue } from "shared/values/assetValue";
import { RouteValue } from "shared/values/routeValue";

interface Props {
  data: Asset;
}

export default function ItemBookValueAsset({ data }: Props) {
  const navigate = useNavigate();
  const { area_id } = useParams();

  const itemWidget = () => (
    <motion.li
      className={`flex justify-between items-start gap-2 mx-2 rounded-lg p-2 my-2 cursor-pointer bg-blue-50`}
      variants={AnimationValue.list.item}
      onClick={() => {
        navigate(
          `${RouteValue.manufactureAssetDashboard.replace(
            ":area_id",
            area_id!
          )}?asset_id=${data.id}`
        );
      }}
    >
      {data.image!.length > 0 && (
        <div
          className="!bg-cover !bg-no-repeat w-14 h-14 rounded-lg"
          style={{ background: `url("${data.image![0].image}")` }}
        ></div>
      )}
      <div className="flex-grow">
        <div className="font-semibold">{data.name}</div>
        <div className="text-sm font-semibold text-gray-400">
          {data.asset_no}
        </div>
        <div className="text-xs font-semibold text-gray-400 truncate">
          Price: Rp {data.price?.toLocaleString()}
        </div>
        {/* {data.overhaulList!.length > 0 && (
          <div className="text-xs font-semibold text-gray-400 truncate">
            Last Overhaul Date:{" "}
            {format(data.overhaulList![0].date!, "dd MMM Y")}
          </div>
        )}
        {data.overhaulList!.length > 0 && (
          <div className="text-xs font-semibold text-gray-400 truncate">
            Last Overhaul Amount: Rp{" "}
            {data.overhaulList![0].amount!.toLocaleString()}
          </div>
        )} */}
        <div className="font-semibold text-green-500">
          {/* Book Value: Rp{" "}
          {(
            BookValueHelper.countBookValue(
              data.price ?? 0,
              data.purchase_date ?? null
            ) + data.overhaulAmount!
          ).toLocaleString()} */}
          Book Value: Rp{" "}
          {BookValueHelper.countBookValue(
            data.price ?? 0,
            data.purchase_date ?? null
          ).toLocaleString()}
        </div>
      </div>
      <div className="flex flex-col gap-1 items-end">
        <div
          className="!bg-contain !bg-center !bg-no-repeat w-14 h-14"
          style={{ background: `url("${AssetValue.calendarImage}")` }}
        ></div>
        <div className="font-semibold text-right">
          {format(data.purchase_date!, "dd MMM Y")}
        </div>
      </div>
    </motion.li>
  );

  return (
    <ContextMenu>
      <ContextMenuTrigger>{itemWidget()}</ContextMenuTrigger>
      <ContextMenuContent className="">
        <ContextMenuItem
          className="cursor-pointer"
          onClick={() => {
            navigate(
              RouteValue.assetOverhaulList.replace(":asset_id", data.id!)
            );
          }}
        >
          <div className="flex gap-2 items-center">
            <EyeIcon className="w-4 h-4" />
            <div>See Overhaul Detail</div>
          </div>
        </ContextMenuItem>
      </ContextMenuContent>
    </ContextMenu>
  );
}
