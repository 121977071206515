import { ApiValue } from "shared/values/apiValue";
import { BaseApi, ParamList } from "./baseApi";

const save = async (data: any) => {
  return await BaseApi.post({ url: ApiValue.roleSave, data });
};

const edit = async (id: string, data: any) => {
  return await BaseApi.put({
    url: ApiValue.roleEdit.replace("{role_id}", id),
    data,
  });
};

const remove = async (id: string) => {
  return await BaseApi.deleteData({
    url: ApiValue.roleDelete.replace("{role_id}", id),
  });
};

const getList = async ({ limit, offset, keyword = "" }: ParamList) => {
  let url = `${ApiValue.roleGetList}?limit=${limit}&offset=${offset}`;
  if (keyword) url = `${url}&keyword=${keyword}`;
  return await BaseApi.get({ url });
};

const saveMenu = async (roleId: string, data: any) => {
  return await BaseApi.post({
    url: ApiValue.roleMenuSave.replace("{role_id}", roleId),
    data,
  });
};

const removeMenu = async (roleId: string, id: string) => {
  return await BaseApi.deleteData({
    url: ApiValue.roleMenuDelete
      .replace("{role_id}", roleId)
      .replace("{role_menu_id}", id),
  });
};

const getMenuList = async ({ id, limit, offset, keyword = "" }: ParamList) => {
  let url =
    `${ApiValue.roleMenuGetList}?limit=${limit}&offset=${offset}`.replace(
      "{role_id}",
      id!
    );
  if (keyword) url = `${url}&keyword=${keyword}`;
  return await BaseApi.get({ url });
};

export const RoleApi = {
  save,
  edit,
  remove,
  getList,
  saveMenu,
  removeMenu,
  getMenuList,
};
