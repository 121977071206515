import { intervalToDuration } from "date-fns";
import { SettingValue } from "shared/values/settingValue";

const countBookValue = (price: number, purchaseDate: Date | null): number => {
  const nowDate = new Date();
  const interval = intervalToDuration({ start: purchaseDate!, end: nowDate });
  const intervalYear = interval.years ?? 0;
  let result = price;
  if (intervalYear !== 0)
    result = result - (result / SettingValue.bookValueYearGap) * intervalYear;
  return parseInt(result.toFixed(0));
};

export const BookValueHelper = { countBookValue };
