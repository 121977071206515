import { UserApi } from "entities/api/userApi";
import { User, UserModel } from "entities/model/userModel";
import { create } from "zustand";

type State = {
  profile: User | null;
};

type Actions = {
  setProfile: (value: User) => void;
};

export const useProfile = create<State & Actions>((set) => ({
  profile: null,
  setProfile: (value: User) => set((state) => ({ profile: value })),
}));

export const getProfile = async () => {
  const response = await UserApi.getProfile();
  console.log(response.success);
  if (!response.success) return null;
  return UserModel.fromJson(response.data);
};
