import { ApiValue } from "shared/values/apiValue";
import { BaseApi, ParamList } from "./baseApi";

const save = async (asset_id: string, data: any) => {
  return await BaseApi.post({
    url: ApiValue.assetOverhaulSave.replace("{asset_id}", asset_id),
    data,
  });
};

const edit = async (asset_id: string, id: string, data: any) => {
  return await BaseApi.put({
    url: ApiValue.assetOverhaulEdit
      .replace("{asset_id}", asset_id)
      .replace("{asset_overhaul_id}", id),
    data,
  });
};

const remove = async (asset_id: string, id: string) => {
  return await BaseApi.deleteData({
    url: ApiValue.assetOverhaulDelete
      .replace("{asset_overhaul_id}", id)
      .replace("{asset_id}", asset_id),
  });
};

const getList = async ({ limit, offset, id }: ParamList) => {
  let url =
    `${ApiValue.assetOverhaulGetList}?limit=${limit}&offset=${offset}`.replace(
      "{asset_id}",
      id!
    );
  return await BaseApi.get({ url });
};

export const AssetOverhaulApi = { save, edit, remove, getList };
