import { Area, AreaModel, AreaType } from "./areaModel";
import { AssetImage, AssetImageModel } from "./assetImageModel";
import { Manufacture, ManufactureModel } from "./manufactureModel";
import { PIC, PICModel } from "./picModel";
import { Supplier, SupplierModel } from "./supplierModel";
import { User, UserModel } from "./userModel";

export interface Asset {
  id?: string;
  name?: string;
  asset_no?: string;
  machine_no?: string;
  warranty?: string;
  price?: number;
  purchase_date?: Date | null;
  is_parent?: boolean;
  manufacture_id?: string;
  supplier_id?: string;
  pic_id?: string;
  area_type?: AreaType;
  area_level_id?: string;
  manufacture?: Manufacture;
  supplier?: Supplier;
  image?: AssetImage[];
  area?: Area;
  pic?: PIC;
  showDetail?: boolean;
  areaLevel1?: Area;
  areaLevel2?: Area;
  areaLevel3?: Area;
  createdBy?: User;
  // counter?: AssetCounter;
  // overhaulList?: Overhaul[];
}

const fromJson = (json: any): Asset => {
  let result: Asset = {
    id: json["id"],
    name: json["name"],
    asset_no: json["asset_no"],
    machine_no: json["machine_no"],
    warranty: json["warranty"],
    price: json["price"],
    purchase_date:
      json["purchase_date"] != null ? new Date(json["purchase_date"]) : null,
    manufacture_id: json["manufacture_id"],
    supplier_id: json["supplier_id"],
    pic_id: json["pic_id"],
    area_type: json["area_type"],
    area_level_id: json["area_level_id"],
    is_parent: json["is_parent"],
    showDetail: false,
  };

  if (json["manufacture"])
    result.manufacture = ManufactureModel.fromJson(json["manufacture"]);
  if (json["supplier"])
    result.supplier = SupplierModel.fromJson(json["supplier"]);
  if (json["area_level"]) result.area = AreaModel.fromJson(json["area_level"]);
  if (json["pic"]) result.pic = PICModel.fromJson(json["pic"]);
  let assetImageList: AssetImage[] = [];
  if (json["asset_images"])
    for (let index = 0; index < json["asset_images"].length; index++) {
      const item = json["asset_images"][index];
      assetImageList.push(AssetImageModel.fromJson(item));
    }
  if (json["areaLevel1"])
    result.areaLevel1 = AreaModel.fromJson(json["areaLevel1"]);
  if (json["areaLevel2"])
    result.areaLevel2 = AreaModel.fromJson(json["areaLevel2"]);
  if (json["areaLevel3"])
    result.areaLevel3 = AreaModel.fromJson(json["areaLevel3"]);
  if (json["created_by"])
    result.createdBy = UserModel.fromJson(json["created_by"]);
  result.image = assetImageList;

  return result;
};

const toJson = (value: Asset): string => {
  return JSON.stringify(value);
};

export const AssetModel = { fromJson, toJson };
