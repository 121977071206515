import { useNavigate } from "react-router-dom";
import { AssetValue } from "shared/values/assetValue";
import { RouteValue } from "shared/values/routeValue";
import SidebarNavDashboard from "./SidebarNavDashboard";
import SidebarMultiNavDashboard from "./SidebarMultiNavDashboard";
import { NavigationUtil } from "shared/utils/navigationUtil";

export default function SidebarDashboard() {
  const navigate = useNavigate();

  return (
    <>
      <div
        onClick={() => {
          navigate(RouteValue.manufactureDashboard);
        }}
      >
        <SidebarNavDashboard
          icon={AssetValue.factoryImage}
          label="Manufacturing"
          name="manufacture"
        />
      </div>
      <div
        onClick={() => {
          navigate(RouteValue.officeDashboard);
        }}
      >
        <SidebarNavDashboard
          icon={AssetValue.officeImage}
          label="Office"
          name="office"
        />
      </div>
      <div
        onClick={() => {
          navigate(RouteValue.assetList);
        }}
      >
        <SidebarNavDashboard
          icon={AssetValue.assetImage}
          label="Asset"
          name="asset"
        />
      </div>
      <SidebarMultiNavDashboard
        icon={
          <img
            src={AssetValue.employeeImage}
            alt={"User"}
            className="w-full h-auto"
          />
        }
        childs={NavigationUtil.navUserList}
        label="User"
      />
      <SidebarMultiNavDashboard
        icon={
          <img
            src={AssetValue.employeeImage}
            alt={"PIC"}
            className="w-full h-auto"
          />
        }
        childs={NavigationUtil.navPICList}
        label="PIC"
      />
      <SidebarMultiNavDashboard
        icon={<></>}
        childs={NavigationUtil.navDataMasterList}
        label="Data Master"
      />
    </>
  );
}
