import { zodResolver } from "@hookform/resolvers/zod";
import { useProfile } from "app/hooks/profileHook";
import { FileApi } from "entities/api/fileApi";
import { UserApi } from "entities/api/userApi";
import { SaveIcon } from "lucide-react";
import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import DefaultButton from "shared/ui/button/DefaultButton";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "shared/ui/shadcn/components/ui/dialog";
import { Form } from "shared/ui/shadcn/components/ui/form";
import { UserValidationValue } from "shared/values/validations/userValidationValue";
import { z } from "zod";

interface Props {
  children: JSX.Element;
}

export default function FormProfileAvatarUser({ children }: Props) {
  const { profile, setProfile } = useProfile();
  const [open, setOpen] = useState<boolean>(false);
  const form = useForm<z.infer<typeof UserValidationValue.editAvatar>>({
    resolver: zodResolver(UserValidationValue.editAvatar),
    defaultValues: {
      avatar: profile?.avatar,
    },
  });
  const [loading, setLoading] = useState(false);
  const inputFileRef: any = useRef();
  const [file, setFile] = useState<File | null>(null);

  const onSubmit = async (
    values: z.infer<typeof UserValidationValue.editAvatar>
  ) => {
    try {
      setLoading(true);
      if (profile?.avatar === form.getValues("avatar")) {
        setOpen(false);
        return;
      }
      const reponseUpload = await FileApi.uploadUserAvatar(profile?.id!, file);
      if (!reponseUpload.success) {
        const dataError = reponseUpload.data.error;
        form.setError(dataError.path, { message: dataError.msg });
        return;
      }
      const avatar = reponseUpload.data.image;
      const response = await UserApi.edit({ id: profile?.id, avatar });
      if (!response.success) {
        const dataError = response.error;
        form.setError(dataError.path, { message: dataError.msg });
        return;
      }
      setProfile({ ...profile, avatar });
      form.clearErrors();
      setFile(null);
      setOpen(false);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const onFileChangeCapture = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files != null && e.target.files!.length > 0) {
      setFile(e.target.files[0]);
      form.setValue("avatar", URL.createObjectURL(e.target.files[0]), {
        shouldValidate: true,
      });
    }
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger
        asChild
        onClick={() => {
          setOpen(true);
        }}
      >
        {children}
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Edit Your Avatar</DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <div className="flex gap-4 items-center">
              <img
                src={`${form.getValues("avatar")}`}
                alt="Avatar"
                className="rounded-full w-32 h-32 bg-gray-100 border-2 border-primary"
              />
              <div className="flex-grow flex flex-col gap-4">
                <input
                  type="file"
                  ref={inputFileRef}
                  onChangeCapture={onFileChangeCapture}
                  className="hidden"
                  accept="image/jpg,image/jpeg,image/png,image/webp"
                />
                <div
                  className="text-primary font-bold cursor-pointer"
                  onClick={() => {
                    inputFileRef.current.click();
                  }}
                >
                  Select Picture
                </div>
                {file !== null && (
                  <DefaultButton
                    label="Save changes"
                    icon={<SaveIcon className="stroke-white w-6 h-6" />}
                    loading={loading}
                    type="submit"
                    className="w-3/4"
                  />
                )}
              </div>
            </div>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
