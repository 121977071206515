import ReactApexChart from "react-apexcharts";

interface Props {
  data: any;
  title: string;
  isPercetage?: boolean;
  tickAmount?: number;
}

export default function LineChart({
  data,
  isPercetage = false,
  title,
  tickAmount = 4,
}: Props) {
  return (
    <ReactApexChart
      type="area"
      height={"100%"}
      width={"100%"}
      series={[
        {
          name: title,
          data: data,
        },
      ]}
      options={{
        chart: {
          type: "area",
          stacked: false,
          height: "100%",
          zoom: {
            type: "x",
            enabled: true,
            autoScaleYaxis: true,
          },
          toolbar: {
            show: false,
            autoSelected: "zoom",
          },
        },
        dataLabels: {
          enabled: false,
        },
        markers: {
          size: 0,
        },
        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 1,
            inverseColors: false,
            opacityFrom: 0.5,
            opacityTo: 0,
            stops: [0, 90, 100],
          },
        },
        yaxis: {
          labels: {
            formatter: function (val: any) {
              return `${val}${isPercetage ? "%" : ""}`;
            },
            style: {
              fontFamily: "jakarta",
            },
          },
          min: isPercetage ? 0 : undefined,
          max: isPercetage ? 100 : undefined,
          tickAmount: tickAmount,
        },
        xaxis: {
          type: "datetime",
          labels: {
            style: {
              fontFamily: "jakarta",
            },
          },
        },
        tooltip: {
          shared: false,
          y: {
            formatter: function (val: any) {
              return `${val}${isPercetage ? "%" : ""}`;
            },
          },
        },
      }}
    />
  );
}
