interface Props {
  icon: string;
  title: string;
}

export default function CardTitleSmallGeneral({ icon, title }: Props) {
  return (
    <div className="flex gap-2 items-center justify-start m-2">
      <img src={icon} alt={title} className={`w-auto h-6`} />
      <label className={`font-semibold`}>{title}</label>
    </div>
  );
}
