import CounterAsset from "features/asset/CounterAsset";
import CounterLocationAsset from "features/asset/CounterLocationAsset";
import CounterManufacture from "features/manufacture/CounterManufacture";
import CounterSupplier from "features/supplier/CounterSupplier";
import { motion } from "framer-motion";
import { AnimationValue } from "shared/values/animationValue";

export default function CounterOffice() {
  return (
    <motion.ul
      variants={AnimationValue.list.container}
      initial="hidden"
      animate="visible"
      className="grid grid-cols-1 xl:grid-cols-4 gap-2 xl:h-[5rem]"
    >
      <CounterAsset areaType="OFFICE" />
      <CounterLocationAsset areaType="OFFICE" />
      <CounterManufacture />
      <CounterSupplier />
    </motion.ul>
  );
}
