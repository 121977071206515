/* eslint-disable react-hooks/exhaustive-deps */
import { AssetApi } from "entities/api/assetApi";
import { AreaType } from "entities/model/areaModel";
import { useEffect, useState } from "react";
import { AssetValue } from "shared/values/assetValue";
import CounterDashboard from "widgets/dashboard/CounterDashboard";

interface Props {
  areaType: AreaType;
}

export default function CounterLocationAsset({ areaType }: Props) {
  const [counter, setCounter] = useState<number>(0);

  const getCounter = async () => {
    try {
      let response: any = null;
      response = await AssetApi.getTotalArea(areaType);
      if (response.success) {
        setCounter(response.data.counter);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getCounter();
  }, []);

  return (
    <CounterDashboard
      counter={counter}
      icon={AssetValue.mapImage}
      label="Location"
    />
  );
}
