/* eslint-disable react-hooks/exhaustive-deps */
import { format, sub } from "date-fns";
import { PerformanceApi } from "entities/api/performanceApi";
import { useEffect, useState } from "react";
import LineChart from "shared/ui/chart/LineChart";
import DefaultLoading from "shared/ui/loading/DefaultLoading";

interface Props {
  startDate?: string;
  endDate?: string;
  areaId?: string;
}

export default function ChartHistoryMttr({
  startDate = format(sub(new Date(), { months: 1 }), "Y-MM-dd"),
  endDate = format(new Date(), "Y-MM-dd"),
  areaId = "",
}: Props) {
  const [dataList, setDataList] = useState([]);
  const [loading, setLoading] = useState<boolean>(true);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await PerformanceApi.getHistory("mttr");
      if (response.success) {
        const arList: any = [];
        for (const key in response.dataList) {
          const item = response.dataList[key];
          arList.push([new Date(item.date), item.value]);
        }
        setDataList(arList);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [startDate, endDate, areaId]);

  return loading ? (
    <DefaultLoading />
  ) : (
    <LineChart
      data={dataList}
      title="MTTR"
      isPercetage={false}
      tickAmount={2}
    />
  );
}
