import ReactApexChart from "react-apexcharts";

interface Props {
  label: any;
  series: any;
  positionLegend?: any;
}

export default function PieChart({
  label,
  series,
  positionLegend = "right",
}: Props) {
  return (
    <ReactApexChart
      options={{
        chart: {
          width: "100%",
          height: "100%",
          type: "pie",
          background: "#eff6ff",
        },
        labels: label,
        legend: {
          position: positionLegend,
          fontSize: "12px",
          fontFamily: "jakarta",
          show: true,
          formatter: (legendName, opts) => {
            if (legendName.length > 12)
              return `${legendName.substring(
                0,
                9
              )}<br/>   ${legendName.substring(9, legendName.length)}`;
            return legendName;
          },
          itemMargin: { vertical: 7 },
          containerMargin: { top: 0, left: 0 },
        },
        plotOptions: {
          pie: {
            customScale: 1,
          },
        },
      }}
      series={series}
      type="pie"
      width="100%"
      height="100%"
    />
  );
}
