import { AssetValue } from "./assetValue";

const avatarDefault = "https://ui-avatars.com/api/?name={name}";
const secretKeySignIn = "b39c7d45-b88f-5501-9f66-63115b312051";
const saltRound = 10;
const platform: "mac" | "windows" =
  navigator.userAgent.toUpperCase().indexOf("MAC OS") > -1 ? "mac" : "windows";
const fileExcelType =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExcelExtension = ".xlsx";
const bookValueYearGap = 10;

// Local Storage
const keyLocalStorageToken = "amnostra_token";

// Limit
const limitManufacture = 10;
const limitSupplier = 10;
const limitPIC = 10;
const limitRole = 10;
const limitRoleMenu = 10;
const limitUser = 10;
const limitAsset = 10;
const limitNotification = 10;

const energyConsumeList: any = [
  {
    type: "water",
    label: "Water",
    icon: AssetValue.waterImage,
    count: 0,
    unit: "Liter",
    isBlur: false,
  },
  {
    type: "gas",
    label: "Gas",
    icon: AssetValue.gasImage,
    count: 0,
    unit: "MMBTU",
    isBlur: false,
  },
  {
    type: "electric",
    label: "Electric",
    icon: AssetValue.electricImage,
    count: 0,
    unit: "KwH",
    isBlur: false,
  },
];

export const SettingValue = {
  avatarDefault,
  keyLocalStorageToken,
  secretKeySignIn,
  saltRound,
  platform,
  limitManufacture,
  limitSupplier,
  limitPIC,
  limitRole,
  limitRoleMenu,
  limitUser,
  limitAsset,
  limitNotification,
  fileExcelType,
  fileExcelExtension,
  bookValueYearGap,
  energyConsumeList,
};
