import { AreaType } from "entities/model/areaModel";
import { motion } from "framer-motion";
import { AnimationValue } from "shared/values/animationValue";
import { AssetValue } from "shared/values/assetValue";

interface Props {
  select: AreaType;
  onSelect: any;
}

export default function TabTypeArea({ select, onSelect }: Props) {
  const widgetItem = (areaType: AreaType, icon: string) => (
    <motion.li
      variants={AnimationValue.list.item}
      className={`${
        select === areaType
          ? "!bg-orange-400 border-orange-200"
          : "!bg-white border-gray-100"
      } rounded-lg border shadow-lg p-2 h-[5rem] flex gap-2 items-center cursor-pointer`}
      onClick={() => {
        onSelect(areaType);
      }}
    >
      <div className="w-auto h-[calc(5rem-(2*theme('padding.2')))] aspect-square flex justify-center items-center">
        <img src={icon} alt={areaType} className="w-full h-auto" />
      </div>
      <div
        className={`flex ${
          select === areaType ? "bg-orange-100" : "bg-blue-50"
        } rounded-lg p-2 px-6 flex-grow h-full justify-between items-center`}
      >
        <label className="text-gray-400 font-semibold">{areaType}</label>
      </div>
    </motion.li>
  );

  return (
    <motion.ul
      variants={AnimationValue.list.container}
      initial="hidden"
      animate="visible"
      className="grid grid-cols-1 md:grid-cols-2 gap-2 md:h-[5rem]"
    >
      {widgetItem("MANUFACTURE", AssetValue.factoryImage)}
      {widgetItem("OFFICE", AssetValue.officeImage)}
    </motion.ul>
  );
}
