/* eslint-disable react-hooks/exhaustive-deps */
import { AssetApi } from "entities/api/assetApi";
import { AreaType } from "entities/model/areaModel";
import { Asset, AssetModel } from "entities/model/assetModel";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import DefaultLoading from "shared/ui/loading/DefaultLoading";
import { AnimationValue } from "shared/values/animationValue";
import { useAssetFilter } from "app/hooks/assetFilterHook";
import { PaginationModel, Paginations } from "entities/model/paginationModel";
import ItemBookValueAsset from "./ItemBookValueAsset";
import ItemManufactureAsset from "./ItemManufactureAsset";
import ItemOfficeAsset from "./ItemOfficeAsset";
import { useSearchParams } from "react-router-dom";

interface Props {
  areaType: AreaType;
  isBookValue?: boolean;
  assetId?: string;
}

export default function ListAsset({
  areaType,
  isBookValue = false,
  assetId = "",
}: Props) {
  const [dataList, setDataList] = useState<Asset[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const { assetFilter } = useAssetFilter();
  // const limit = SettingValue.limitAsset;
  const limit = 100;
  const [pagination, setPagination] = useState<Paginations>(
    PaginationModel.handleInitial(limit)
  );
  const [searchParams, setSearchParams] = useSearchParams();

  const fetchData = async () => {
    try {
      setLoading(true);
      let offset = 0;
      if (assetFilter.keyword) {
        offset = 0;
      } else {
        offset = pagination.page === 1 ? 0 : pagination.page! * limit - limit;
      }
      const response = await AssetApi.getListDashboard({
        offset,
        limit,
        keyword: assetFilter.keyword,
        areaId: assetFilter.area_level_id,
        id: "",
        purchase_date: assetFilter.purchase_date,
        pic_id: assetFilter.pic_id,
        warranty: assetFilter.warranty,
        manufacture_id: assetFilter.manufacture_id,
        supplier_id: assetFilter.supplier_id,
        area_type: areaType,
      });
      let responseDataList: Asset[] = [];
      if (response.success) {
        for (let index = 0; index < response.dataList.length; index++) {
          const item = response.dataList[index];
          responseDataList.push(AssetModel.fromJson(item));
        }
        setPagination(response.pagination);
        if (
          searchParams.get("asset_id") === null &&
          areaType !== "MANUFACTURE"
        ) {
          setSearchParams({ asset_id: response.dataList[0]["id"] });
        }
      }
      setDataList(responseDataList);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [assetFilter]);

  const handleItem = (data: Asset) => {
    if (isBookValue) return <ItemBookValueAsset data={data} />;
    switch (areaType) {
      case "MANUFACTURE":
        return <ItemManufactureAsset data={data} withChild={assetId === ""} />;
      case "OFFICE":
        return <ItemOfficeAsset data={data} />;
      default:
        return <></>;
    }
  };

  return loading ? (
    <DefaultLoading />
  ) : (
    <motion.ul
      variants={AnimationValue.list.container}
      initial="hidden"
      animate="visible"
    >
      {dataList.map((data: Asset, index: number) => (
        <motion.div
          key={index}
          initial={AnimationValue.item.initial}
          animate={AnimationValue.item.animate}
          transition={{
            type: AnimationValue.item.transition.type,
            duration: AnimationValue.item.transition.duration + index,
          }}
        >
          {handleItem(data)}
        </motion.div>
      ))}
    </motion.ul>
  );
}
