import TablePIC from "features/pic/TablePIC";
import { motion } from "framer-motion";
import CardTitleGeneral from "shared/ui/card/CardTitleGeneral";
import { AnimationValue } from "shared/values/animationValue";
import { AssetValue } from "shared/values/assetValue";

export default function ListMasterPICPage() {
  return (
    <div
      className="
      pt-[calc(3.125rem+theme('padding.2'))] md:pt-0
      px-2 md:px-0
      md:h-[calc(100vh-3.125rem-(2*theme('gap.2')))]
      "
    >
      <motion.div
        variants={AnimationValue.list.container}
        initial="hidden"
        animate="visible"
        className="w-full !bg-white rounded-lg border border-gray-100 shadow-lg p-2 h-full flex flex-col"
      >
        <CardTitleGeneral title="PIC List" icon={AssetValue.employeeImage} />
        <TablePIC />
      </motion.div>
    </div>
  );
}
