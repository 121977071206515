const basePathImages = "/assets/images";
const logotypeImage = `${basePathImages}/logo/logotype.png`;
const assetImage = `${basePathImages}/machine.png`;
const layoutImage = `${basePathImages}/layout.png`;
const displayImage = `${basePathImages}/display.png`;
const performanceImage = `${basePathImages}/performance.png`;
const employeeImage = `${basePathImages}/employee.png`;
const waterImage = `${basePathImages}/water.png`;
const gasImage = `${basePathImages}/gas.png`;
const electricImage = `${basePathImages}/electric.png`;
const factoryImage = `${basePathImages}/factory.png`;
const officeImage = `${basePathImages}/office.png`;
const energyImage = `${basePathImages}/energy.png`;
const bookImage = `${basePathImages}/book.png`;
const calendarImage = `${basePathImages}/calendar.png`;
const laptopImage = `${basePathImages}/laptop.png`;
const mapImage = `${basePathImages}/map.png`;
const layoutProduction1Image = `${basePathImages}/layout/production1.png`;
const layoutProduction2Image = `${basePathImages}/layout/production2.png`;
const layoutProduction3Image = `${basePathImages}/layout/production3.png`;
const layoutProduction4Image = `${basePathImages}/layout/production4.png`;
const layoutAssetImage = `${basePathImages}/layout/asset.png`;
const bgTemp = `${basePathImages}/bg.png`;
const bgSignIn1 = `${basePathImages}/bg/bg1.jpg`;
const bgSignIn2 = `${basePathImages}/bg/bg2.jpg`;

export const AssetValue = {
  logotypeImage,
  assetImage,
  layoutImage,
  displayImage,
  performanceImage,
  employeeImage,
  waterImage,
  gasImage,
  electricImage,
  factoryImage,
  officeImage,
  energyImage,
  bookImage,
  calendarImage,
  laptopImage,
  mapImage,
  layoutProduction1Image,
  layoutProduction2Image,
  layoutProduction3Image,
  layoutProduction4Image,
  layoutAssetImage,
  bgTemp,
  bgSignIn1,
  bgSignIn2,
};
