/* eslint-disable react-hooks/exhaustive-deps */
import { useAssetFilter } from "app/hooks/assetFilterHook";
import { PerformanceApi } from "entities/api/performanceApi";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AssetValue } from "shared/values/assetValue";
import { RouteValue } from "shared/values/routeValue";
import CounterDashboard from "widgets/dashboard/CounterDashboard";

export default function CounterMTBF() {
  const { assetFilter } = useAssetFilter();
  const [counter, setCounter] = useState<number>(0);
  const navigate = useNavigate();

  const getCounter = async () => {
    try {
      let response: any = null;
      response = await PerformanceApi.getAvg("mtbf");
      if (response.success) {
        setCounter(response.data.avg);
        setCounter(5);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getCounter();
  }, [assetFilter.area_level_id]);

  return (
    <CounterDashboard
      counter={counter}
      icon={AssetValue.performanceImage}
      label="MTBF"
      onClick={() => {
        navigate(
          RouteValue.manufacturePerformanceDetailDashboard.replace(
            ":type",
            "MTBF"
          )
        );
      }}
    />
  );
}
