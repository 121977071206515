import SignInUserPage from "pages/user/SignInUserPage";
import { Navigate, Route, Routes } from "react-router-dom";
import { RouteValue } from "shared/values/routeValue";
import DashboardLayout from "./layouts/DashboardLayout";
import ListAssetPage from "pages/asset/ListAssetPage";
import ListMasterManufacturePage from "pages/m_manufacture/ListMasterManufacturePage";
import ListMasterSupplierPage from "pages/m_supplier/ListMasterSupplierPage";
import ListMasterPICPage from "pages/pic/ListMasterPICPage";
import ListAreaPICPage from "pages/pic/ListAreaPICPage";
import ListUserPage from "pages/user/ListUserPage";
import ListRolePage from "pages/role/ListRolePage";
import ListRoleMenuPage from "pages/role/ListRoleMenuPage";
import ListAreaUserPage from "pages/user/ListAreaUserPage";
import ListChildAssetPage from "pages/asset/ListChildAssetPage";
import ListUserNotificationPage from "pages/notification/ListUserNotificationPage";
import AssetOfficePage from "pages/office/AssetOfficePage";
import DashboardOfficeLayout from "./layouts/DashboardOfficeLayout";
import DashboardManufactureLayout from "./layouts/DashboardManufactureLayout";
import PerformanceManufacturingPage from "pages/manufacturing/PerformanceManufacturingPage";
import DashboardAreaManufactureLayout from "./layouts/DashboardAreaManufactureLayout";
import AreaManufacturingPage from "pages/manufacturing/AreaManufacturingPage";
import AssetManufacturingPage from "pages/manufacturing/AssetManufacturingPage";
import ListOverhaulAssetPage from "pages/asset/ListOverhaulAssetPage";
import PerformanceDetailManufacturingPage from "pages/manufacturing/PerformanceDetailManufacturingPage";

export default function AppRoute() {
  return (
    <Routes>
      <Route element={<DashboardLayout />}>
        <Route element={<DashboardManufactureLayout />}>
          <Route
            path={RouteValue.manufactureDashboard}
            element={<PerformanceManufacturingPage />}
          />
          <Route
            path={RouteValue.manufacturePerformanceDetailDashboard}
            element={<PerformanceDetailManufacturingPage />}
          />
          <Route element={<DashboardAreaManufactureLayout />}>
            <Route
              path={RouteValue.manufactureAreaDashboard}
              element={<AreaManufacturingPage />}
            />
            <Route
              path={RouteValue.manufactureAssetDashboard}
              element={<AssetManufacturingPage />}
            />
          </Route>
        </Route>

        <Route element={<DashboardOfficeLayout />}>
          <Route
            path={RouteValue.officeDashboard}
            element={<AssetOfficePage />}
          />
        </Route>
        <Route path={RouteValue.assetList} element={<ListAssetPage />} />
        <Route
          path={RouteValue.assetChildList}
          element={<ListChildAssetPage />}
        />
        <Route
          path={RouteValue.assetOverhaulList}
          element={<ListOverhaulAssetPage />}
        />
        <Route
          path={RouteValue.picMasterList}
          element={<ListMasterPICPage />}
        />
        <Route path={RouteValue.picAreaList} element={<ListAreaPICPage />} />
        <Route
          path={RouteValue.masterManufactureList}
          element={<ListMasterManufacturePage />}
        />
        <Route
          path={RouteValue.masterSupplierList}
          element={<ListMasterSupplierPage />}
        />
        <Route path={RouteValue.userList} element={<ListUserPage />} />
        <Route path={RouteValue.userAreaList} element={<ListAreaUserPage />} />
        <Route path={RouteValue.roleList} element={<ListRolePage />} />
        <Route path={RouteValue.roleMenuList} element={<ListRoleMenuPage />} />
        <Route
          path={RouteValue.notificationList}
          element={<ListUserNotificationPage />}
        />
      </Route>
      {/* Public Route */}
      <Route path={RouteValue.userSignIn} element={<SignInUserPage />} />
      {/* Error Route */}
      <Route path="*" element={<Navigate to={RouteValue.userSignIn} />} />
    </Routes>
  );
}
