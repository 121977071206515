import { format } from "date-fns";
import { AssetApi } from "entities/api/assetApi";
import { Area } from "entities/model/areaModel";
import * as FileSaver from "file-saver";
import { FileBarChartIcon } from "lucide-react";
import { useState } from "react";
import DefaultButton from "shared/ui/button/DefaultButton";
import { SettingValue } from "shared/values/settingValue";
import XLSX from "sheetjs-style";

interface Props {
  area: Area;
  asset_id?: string;
  approved: number;
}

export default function ExportExcelAsset({ area, asset_id, approved }: Props) {
  const [loading, setLoading] = useState<boolean>(false);

  const fetchDataList = async () => {
    try {
      const response = await AssetApi.getExcelList({
        offset: 0,
        limit: 0,
        approved,
        areaId: area.id,
        id: asset_id,
      });
      if (response.success) {
        return response.dataList;
      }
      return [];
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const exportToExcel = async () => {
    try {
      setLoading(true);
      const excelData = await fetchDataList();
      const ws = XLSX.utils.json_to_sheet(excelData!);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], {
        type: SettingValue.fileExcelType,
      });
      const filename = `asset_${format(new Date(), "Y_MM_dd")}${
        SettingValue.fileExcelExtension
      }`;
      FileSaver.saveAs(data, filename);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <DefaultButton
      label="Export Data"
      icon={<FileBarChartIcon className="stroke-white w-6 h-6" />}
      className="bg-emerald-500"
      onClick={() => {
        exportToExcel();
      }}
      loading={loading}
    />
  );
}
