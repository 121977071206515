/* eslint-disable react-hooks/exhaustive-deps */
import { initialAssetFilter, useAssetFilter } from "app/hooks/assetFilterHook";
import { motion } from "framer-motion";
import { useEffect } from "react";
import { AnimationValue } from "shared/values/animationValue";
import ArManufacture from "widgets/manufacture/ArManufacture";
import CounterAssetManufacture from "widgets/manufacture/CounterAssetManufacture";
import EnergyManufacture from "widgets/manufacture/EnergyManufacture";
import MtbfManufacture from "widgets/manufacture/MtbfManufacture";
import MttrManufacture from "widgets/manufacture/MttrManufacture";
import OEEManufacture from "widgets/manufacture/OEEManufacture";
import PICManufacture from "widgets/manufacture/PICManufacture";

export default function PerformanceManufacturingPage() {
  const { setAssetFilter } = useAssetFilter();

  const handleParam = () => {
    setAssetFilter({
      ...initialAssetFilter,
      area_type: "MANUFACTURE",
    });
  };

  useEffect(() => {
    handleParam();
  }, []);

  return (
    <motion.div
      variants={AnimationValue.list.container}
      initial="hidden"
      animate="visible"
      className="grid grid-cols-1 xl:grid-cols-4 xl:gap-2 xl:h-[calc(100vh-3.125rem-(4*theme('gap.2'))-5rem)]"
    >
      <OEEManufacture />
      <div className="h-4 xl:hidden"></div>
      <div className="xl:col-span-3 flex flex-col gap-2 xl:h-[calc(100vh-3.125rem-(4*theme('gap.2'))-5rem)]">
        <div className="grid grid-cols-1 xl:grid-cols-3 xl:gap-2 xl:h-[calc((100vh-3.125rem-(6*theme('gap.2'))-5rem)*37.5/100)]">
          <CounterAssetManufacture />
          <div className="h-4 xl:hidden"></div>
          <ArManufacture />
          <div className="h-4 xl:hidden"></div>
        </div>
        <div className="grid grid-cols-1 xl:grid-cols-2 xl:gap-2 xl:h-[calc((100vh-3.125rem-(6*theme('gap.2'))-5rem)*37.5/100)]">
          <MttrManufacture />
          <div className="h-4 xl:hidden"></div>
          <MtbfManufacture />
          <div className="h-4 xl:hidden"></div>
        </div>
        <div className="grid grid-cols-1 xl:grid-cols-2 xl:gap-2 xl:h-[calc((100vh-3.125rem-(6*theme('gap.2'))-5rem)*25/100)]">
          <PICManufacture />
          <div className="h-4 xl:hidden"></div>
          <EnergyManufacture />
          <div className="h-4 xl:hidden"></div>
        </div>
      </div>
    </motion.div>
  );
}
