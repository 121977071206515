/* eslint-disable react-hooks/exhaustive-deps */
import { AreaApi } from "entities/api/areaApi";
import { Area, AreaModel } from "entities/model/areaModel";
import FormUploadLayoutArea from "features/area/FormUploadLayoutArea";
import { motion } from "framer-motion";
import { ImageIcon } from "lucide-react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import UploadButton from "shared/ui/button/UploadButton";
import CardTitleActionGeneral from "shared/ui/card/CardTitleActionGeneral";
import DefaultDialog from "shared/ui/dialog/DefaultDialog";
import DefaultLoading from "shared/ui/loading/DefaultLoading";
import { AnimationValue } from "shared/values/animationValue";
import { AssetValue } from "shared/values/assetValue";

export default function LayoutManufacture() {
  const [data, setData] = useState<Area>({});
  const [loading, setLoading] = useState<boolean>();
  const { area_id } = useParams();

  const fetchArea = async () => {
    try {
      setLoading(true);
      const response = await AreaApi.get(area_id!);
      if (response.success) {
        setData(AreaModel.fromJson(response.data));
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchArea();
  }, []);

  return (
    <motion.div
      variants={AnimationValue.list.item}
      className="
        w-full !bg-white rounded-lg border border-gray-100 shadow-lg 
        flex flex-col
        h-[100vw]
        xl:h-[calc((100vh-3.125rem-(5*theme('gap.2'))-5rem)*75/100)]
        xl:col-span-2"
    >
      <CardTitleActionGeneral
        icon={AssetValue.layoutImage}
        title={loading ? "" : data.name!}
        action={
          loading ? (
            <></>
          ) : (
            <DefaultDialog
              button={<UploadButton />}
              title={`Update Layout Area ${data.name}`}
            >
              <FormUploadLayoutArea area_id={area_id} onFormSubmit={() => {}} />
            </DefaultDialog>
          )
        }
      />
      <div className="flex-grow rounded-lg bg-blue-50 mx-2 mb-2 p-1">
        {loading ? (
          <DefaultLoading />
        ) : data.layout ? (
          <div
            className="w-full h-full !bg-center !bg-contain !bg-no-repeat"
            style={{
              background: `url("${data.layout!}")`,
            }}
          ></div>
        ) : (
          <div className="w-full h-full flex items-center justify-center">
            <div className="flex flex-col items-center justify-center">
              <ImageIcon className="w-12 h-12 stroke-gray-500" />
              <div className="font-semibold text-gray-500">No Data</div>
            </div>
          </div>
        )}
      </div>
    </motion.div>
  );
}
